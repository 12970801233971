import instance from './request';

/**
 * 封装post 上传图片请求
 * @param url
 * @param formData
 * @returns {Promise}
 */
//https://www.cnblogs.com/jiawei-Wang/p/11276339.html
export const reqUpload = (url: any, formData: any) => {
  (instance as any).headers['Content-Type'] = 'multipart/form-data';
  return instance.post(url, formData);
};

export const getDetail = (url: any, data = {}) => {
  return instance.get(url, data);
};
export const getCode = () => {
  return instance.get('/api/user/code');
};
//start
//上传base64图片
export const postUploadBase64 = (data: any) => {
  return instance.post('/api/upload/base64', data);
};

//上传base64图片到七牛云
export const postQiniuUploadBase64 = (data: any) => {
  return instance.post('/api/upload/qiniu_base64', data);
};
//添加平台模板
export const posttemplateAdd = (data: any) => {
  return instance.post('/api/platform_template/add', data);
};

//获取平台模板详情
export const postTemlateDetail = (data: any) => {
  return instance.get('/api/platform_template/detail/?id=' + data.id);
};
//编辑平台模板信息
export const postTemlateEdit = (data: any) => {
  return instance.post('/api/platform_template/edit', data);
};
//添加产品模板
export const postTemlateAdd = (data: any) => {
  return instance.post('/api/product_template/add', data);
};
//站点列表
export const postTemlateSite = () => {
  return instance.get('/api/product_template/site');
};
//获取产品模板详情
export const getProductDetail = (data: any) => {
  return instance.get('/api/product_template/detail', { params: { ...data } });
};
// 获取产品模板列表
export const getProductList = (data: any) => {
  return instance.get('/api/product_template/list', { params: { ...data } });
};
//修改产品模板
export const getProductedit = (data: any) => {
  return instance.post('/api/product_template/edit', data);
};
// 产品模板的删除
export const getDeleteProduct = (data: any) => {
  return instance.get('/api/product_template/delete', { params: { ...data } });
};
//产品库列表
export const getLibraryList = (data?: any) => {
  return instance.get('/api/product_library/list', { params: { ...data } });
};
//获取图片模板参数
export const getImagetemplateparams = (data?: any) => {
  return instance.get('/api/goods_template/imagetemplateparams', {
    params: { ...data },
  });
};
//设计成品
export const postLibraryDesign = (data: any) => {
  return instance.post('/api/product_library/design', data);
};

//获取设计记录
export const getlibraryResultlist = (data: any) => {
  return instance.get('/api/design_record/list', {
    params: { ...data },
  });
};
// 删除设计记录
export const getDeleteRecord = (data: any) => {
  return instance.get('/api/design_record/delete', {
    params: { ...data },
  });
};
//获取设计记录详情
export const getlibraryResultlistDetail = (data: any) => {
  return instance.get('/api/design_record/detail', {
    params: { ...data },
  });
};
//获取批量设计数据
export const getProductLiibraryBatch = (data: any) => {
  return instance.get('/api/product_library/batch', {
    params: { ...data },
  });
};
//批量设计成品
export const getBatchDesign = (data: any) => {
  return instance.post('/api/product_library/batch_design', data);
};
//获取图片模板列表
export const getAllTemplate = () => {
  return instance.get('/api/goods_template/imagetemplatelist');
};
//修改模板的背景图片
export const postAllTemplate = (data: any) => {
  return instance.post('/api/goods_template/imagetemplateedit', data);
};
//添加文件夹
export const postAddFolder = (data: any) => {
  return instance.post('/api/material/add_folder', data);
};
//获取文件夹列表
export const getFolderList = () => {
  return instance.get('/api/material/folder_list');
};
//修改文件夹列表
export const getEditFolder = (data: any) => {
  return instance.post('/api/material/edit_folder', data);
};
//删除文件夹
export const getDelFolder = (id: any) => {
  return instance.get('/api/material/del_folder', { params: { id } });
};
//changmei
//登录
export const postUserLogin = (data: any) => {
  return instance.post('/api/user/login', data);
};
//获取尺寸列表
export const getTemplateSize = () => {
  return instance.get('/api/product_template/size');
};
//新增尺寸
export const getTemplateAddSize = (data: any) => {
  return instance.post('/api/product_template/addsize', data);
};
//删除尺寸
export const getTemplateDeletSize = (id: any) => {
  return instance.get('/api/product_template/delsize?id=' + id);
};
// 删除规格
export const getDeleteSpecification = (id: any) => {
  return instance.get('/api/goods_template/delete_specification?id=' + id);
};
//添加模板
export const getGoodsTemplateAdd = (data: any) => {
  return instance.post('/api/goods_template/add', data);
};
// 商品模板的图片模板
export const getPictureTemplate = () => {
  return instance.get('/api/goods_template/imagetemplate');
};
// 商品模板的列表
export const getCommodityList = (data: any) => {
  return instance.get('/api/goods_template/list', { params: { ...data } });
};
// 商品模板的详情
export const getProductDetails = (data: any) => {
  return instance.get('/api/goods_template/detail', { params: { ...data } });
};
// 商品模板的编辑
export const getEditProduct = (data: any) => {
  return instance.post('/api/goods_template/edit', data);
};
// 商品模板的删除
export const getDeleteCommodity = (data: any) => {
  return instance.get('/api/goods_template/delete', { params: { ...data } });
};
// 修改密码
export const getChangePassword = (data: any) => {
  return instance.post('/api/user/resetpwd', data);
};
// 违禁词列表
export const getprohibitedWord = (
  search: any,
  filter: any,
  limit: any,
  page: any
) => {
  return instance.get(
    `/api/prohibited_word/list?search=${search}&filter=${filter}&limit=${limit}&page=${page}`
  );
};
// 编辑违禁词
export const getEditList = (data: any) => {
  return instance.post('/api/prohibited_word/edit', data);
};
// 添加违禁词
export const getIncreaseList = (data: any) => {
  return instance.post('/api/prohibited_word/add', data);
};
// 删除违禁词
export const getDelteProhibited = (id: any) => {
  return instance.get(`/api/prohibited_word/delete?id=${id}`);
};
// 违禁图片列表
export const getprohibitedImage = (
  search: any,
  filter: any,
  limit: any,
  page: any
) => {
  return instance.get(
    `/api/prohibited_image/list?search=${search}&filter=${filter}&limit=${limit}&page=${page}`
  );
};
// 添加违禁图片
export const getIllegalPictures = (data: any) => {
  return instance.post('/api/prohibited_image/add', data);
};
// 删除违禁图片
export const getDeletePicture = (id: any) => {
  return instance.get(`/api/prohibited_image/delete?id=${id}`);
};
// 编辑违禁图片
export const getEditPicture = (data: any) => {
  return instance.post('/api/prohibited_image/edit', data);
};
// 上传图片
export const getUpload = (data: any) => {
  return instance.post('/api/upload/upload', data);
};
// 用户列表
export const getGeneralList = (filter: any, limit: any, page: any) => {
  return instance.get(
    `/api/user/generalList?&filter=${filter}&limit=${limit}&page=${page}`
  );
};
// 重新提交
export const getReSubmit = (id: any) => {
  return instance.get(`/api/user/resubmit?id=${id}`);
};
// 删除用户
export const getdeleteUser = (id: any) => {
  return instance.get(`/api/user/generalDelete?id=${id}`);
};
// 添加用户
export const getAddUser = (data: any) => {
  return instance.post('/api/user/adduser', data);
};
// 批量通过或者不通过
export const getBatchList = (data: any) => {
  return instance.post('/api/user/audit', data);
};
// 审批管理的列表
export const getApprovalLsit = (
  filter: any,
  limit: any,
  page: any,
  superior_name: any
) => {
  return instance.get(
    `/api/user/auditList?&filter=${filter}&limit=${limit}&page=${page}&superior_name=${superior_name}`
  );
};
// 区域代理列表数据
export const getrEgionList = () => {
  return instance.get('/api/user/acting');
};
//  区域代理的编辑
export const getRegionEditor = (data: any) => {
  return instance.post('/api/user/agencyEdit', data);
};
// 区域代理的添加
export const getAgencyAdd = (data: any) => {
  return instance.post('/api/user/agencyAdd', data);
};
// 区域代理的删除
export const getZoneDeletion = (data: any) => {
  return instance.get('/api/user/agencyDelete', { params: { ...data } });
};
// 不通过用户申请
export const getDisagree = (data: any) => {
  return instance.post('/api/user/fail', data);
};
// 修改用户信息
export const getModifyUser = (data: any) => {
  return instance.post('/api/user/modification', data);
};
// 超管-编辑普通用户
export const getEditUser = (data: any) => {
  return instance.post('/api/user/ordinaryEdit', data);
};
//  超管查看区域代理的用户管理
export const getLookUserList = (data: any) => {
  return instance.get('/api/user/ordinaryList', { params: { ...data } });
};
// 设置用户时间
export const getuserTime = (data: any) => {
  return instance.post('/api/user/pass', data);
};
// 审核
export const getExamine = (data: any) => {
  return instance.post(`/api/user/audit`, data);
};
// 区域代理列表数组
export const getEegionLsit = (filter?: any, limit?: any, page?: any) => {
  return instance.get(
    `/api/user/agencyList?&filter=${filter}&limit=${limit}&page=${page}`
  );
};
// 平台模板列表
export const getPlatformList = (data?: any) => {
  return instance.get(`/api/platform_template/list`, {
    params: {
      ...data,
    },
  });
};
// 平台模板的删除
export const getPlatformDelete = (id: any) => {
  return instance.get(`/api/platform_template/delete?id=${id}`);
};

// 属性模板列表
export const getPropertyList = (limit?: any, page?: any) => {
  return instance.get('/api/attribute/list', { params: { limit, page } });
};
// 属性模板的添加
export const getAddProperties = (data: any) => {
  return instance.post(`/api/attribute/add`, data);
};
// 属性模板的编辑
export const getEditProperties = (data: any) => {
  return instance.post(`/api/attribute/edit`, data);
};
// 属性模板的删除
export const getAttributeDeletion = (id: any) => {
  return instance.get(`/api/attribute/delete?id=${id}`);
};
// 素材库列表
export const getSourceMaterialList = (data: any) => {
  return instance.get(`/api/material/list`, { params: { ...data } });
};
// 素材库编辑
export const getMaterialEditing = (data: any) => {
  return instance.post(`/api/material/edit`, data);
};
// 素材库的删除
export const getMaterialDelete = (id: any) => {
  return instance.get(`/api/material/delete?id=${id}`);
};
// 素材库的上传
export const getMaterialUpload = (data: any) => {
  return instance.post(`/api/material/upload`, data);
};
// 国家码/地区码
export const getRandomCode = () => {
  return instance.get(`/api/barcode/code`);
};
// 生成条形码
export const getGenerateBarcode = (data: any) => {
  return instance.post(`/api/barcode/create`, data);
};
// 条形码的列表
export const getBarcodeList = (data: any) => {
  return instance.get(`/api/barcode/list`, { params: { ...data } });
};
// 条形码详情(编辑)
export const getEditBarcode = (data: any) => {
  return instance.get(`/api/barcode/detail`, { params: { ...data } });
};
// 条形码设置失效
export const getSettingFailure = (data: any) => {
  return instance.get(`/api/barcode/failure`, { params: { ...data } });
};
// 条形码取消失效
export const getCancelInvalidation = (data: any) => {
  return instance.get(`/api/barcode/cancelfailure`, { params: { ...data } });
};
// 批量设置失效
export const getBatchFailure = (data: any) => {
  return instance.post(`/api/barcode/disabled`, data);
};
// 批量设置取消失效
export const getBatchCancel = (data: any) => {
  return instance.post(`/api/barcode/canceldisabled`, data);
};
// 成品管理
export const getCompleteList = (data: any) => {
  return instance.get(`/api/complete/list`, { params: { ...data } });
};
// 成品管理的删除
export const getFinishedDeletion = (data: any) => {
  return instance.get(`/api/complete/delete`, { params: { ...data } });
};
// 成品管理的编辑
export const getProductEditor = (data: any) => {
  return instance.post(`/api/product_library/edit`, data);
};
// 成品获取变体
export const getProductVariants = (data: any) => {
  return instance.get(`/api/complete/variant`, { params: { ...data } });
};
// 成品列表的详情
export const getFinishedDetail = (data: any) => {
  return instance.get(`/api/complete/detail`, { params: { ...data } });
};
// 汇出列表
export const getRemittanceList = (data: any) => {
  return instance.get(`/api/remit_record/list`, { params: { ...data } });
};
// 汇出记录的查看
export const getExportView = (data: any) => {
  return instance.get(`/api/remit_record/detail`, { params: { ...data } });
};
// 汇出的下载
export const getExportDownload = (data: any) => {
  return instance.get(`/api/product_library/download`, { params: { ...data } });
};
// 产品汇出
export const getExportProducts = (data: any) => {
  return instance.post(`/api/product_library/remit`, data);
};
// 汇出的产品模板数据
export const getExportData = (data: any) => {
  return instance.post(`/api/remit_record/Export`, data);
};
//二次迭代
// 订单列表
export const getAmazonOrder = (data: any) => {
  return instance.get(`/api/amazon/getOrders`, { params: { ...data } });
};
//二次迭代 end

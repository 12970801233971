














































































































































































































































































































































































































































































































import Vue from 'vue';
import {
  getRemittanceList,
  getExportView,
  getProductList,
  getExportDownload,
  getExportData,
  getExportProducts,
} from '../api';
export default Vue.extend({
  data() {
    return {
      clickId: '', //点击展开的id
      // 定价站点的数据
      options: [] as any,
      // 关键词1
      keyWord: '',
      // 关键词2
      barcodeCoding: '',
      // 状态
      stateName: '',
      // 账号名
      accountName: '',
      // 用户管理数据
      tableData: [],
      // 用户信息
      form: {
        // 产品模板
        countryCode: '',
        // 商品模板
        manufactorCode: '',
      },
      // 变体的数据
      appointData: [
        {
          img: '',
        },
      ],
      // 修改用户信息的验证
      rules: {
        countryCode: [
          { required: true, message: '请选择产品模板', trigger: 'blur' },
          //  { validator: checkUsername, trigger: "blur" }
        ],
        manufactorCode: [
          { required: true, message: '请选择商品模板', trigger: 'blur' },
        ],
        specificationsType: [
          {
            type: 'array',
            required: true,
            message: '请选择至少一种尺寸',
            trigger: 'change',
          },
        ],
        withoutFrameType: [
          {
            type: 'array',
            required: true,
            message: '请选择至少一种',
            trigger: 'change',
          },
        ],
      },
      ruleForm: {
        // 规格
        specificationsType: [''],
        // 有无框
        withoutFrameType: [],
      },
      cityOptions: ['20*850cm', '50*743cm', '80*780cm', '20*50cm'],
      checkAll: false,
      isIndeterminate: true,
      // 弹窗的显示与隐藏
      dialogFormVisible1: false,
      // 数据总数
      currentLength: 0,
      drawer: false,
      // 汇出是否有填写
      isFill: false,
      sku: '母体SKU-<color_name>-<size_name>',
      skuRule:
        '变体SKU=1:母体SKU-<color_name>-<size_name>,2:母体下按变体顺序从1开始自增,3:与母体规则一致',
      dialogFormVisible2: false,
      page: 1,
      // 查看的分页
      seePage: 1,
      // 查看的数据
      seeData: [],
      // 查看的总条数
      seeLength: 0,
      checkid: '', //查看id
      complete_id: '',
      template_id: '',
      inventory: {},
      ExportData: {} as any,
      loading: false,
    };
  },
  mounted() {
    this.getRemittanceList();
    // 请求产品模板
    this.getProductList();
  },
  methods: {
    //打开关闭
    openCloseAll(scope: any) {
      this.clickId = this.clickId == scope.row.id ? '' : scope.row.id;
    },
    // 汇出的接口
    async getRemittanceList() {
      const data = {
        filter: {
          'product.name': this.keyWord,
          'user.real_name': this.accountName,
        },
        page: this.page,
        limit: '10',
      };
      const res: any = await getRemittanceList(data);
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },

    // 重置按钮
    getReset() {
      this.accountName = '';
      this.keyWord = '';
    },
    // 搜索框
    getLookup() {
      this.page = 1;
      this.getRemittanceList();
    },
    // 产品模板选中的监听 val为id
    changeVal(val: any) {
      this.template_id = val;
      this.$forceUpdate();
      this.getExportData();
    },
    // 刷新
    getAttribute() {
      this.loading = true;
      this.getExportData();
    },
    // 产品模板选中的请求
    async getExportData() {
      const data = {
        complete_id: this.complete_id,
        template_id: this.template_id,
      };
      const res: any = await getExportData(data);
      if (res.code == 200) {
        this.ExportData = res.data;
        this.loading = false;
        this.isFill = true;
      }
    },
    // 重新汇出
    handleDelete(row: any) {
      const newArr = [] as any;
      Object.keys(this.options).forEach(key => {
        newArr.push(this.options[key].id);
      });
      const existence = newArr.indexOf(row.template_id);
      if (existence != -1) {
        this.complete_id = row.complete_id;
        this.template_id = row.template_id;
        this.form.countryCode = row.template_id;
        this.getExportData();
        this.dialogFormVisible1 = true;
      } else {
        this.form.countryCode = '';
        this.$message.error('该产品模板已被删除，可重新选择汇出');
        this.isFill = false;
        this.dialogFormVisible1 = true;
      }
    },
    // 请求产品模板
    async getProductList() {
      const data = {
        filter: {},
        page: 1,
        limit: '100',
      };
      const res: any = await getProductList(data);
      if (res.code == 200) {
        Object.keys(res.data.rows).forEach(key => {
          const item = {
            id: res.data.rows[key].id,
            label: res.data.rows[key].name,
          };
          this.options.push(item);
        });
      }
    },
    // 产品汇出
    async getExportProducts() {
      const data = {
        complete_id: this.complete_id,
        template_id: this.template_id,
      };
      const res: any = await getExportProducts(data);
      if (res.code == 200) {
        this.$message({
          message: '汇出成功',
          type: 'success',
        });
        this.dialogFormVisible1 = false;
        this.$router.go(0);
      }
    },
    // 清空
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getRemittanceList();
    },
    // 查看的分页
    getPaging(val: any) {
      this.seePage = val;
      this.getExportView();
    },
    // 批量编辑的搜索
    getEditLook() {
      this.seePage = 1;
      this.getExportView();
    },
    //产品汇出的确定按钮
    getDialogFormVisible(form: any) {
      (this.$refs[form] as any).validate((valid: any) => {
        if (valid) {
          // this.dialogFormVisible1 = false;
          this.getExportProducts();
        } else {
          return false;
        }
      });
    },
    // 指定变体汇出的确定
    getAppoint(ruleForm: any) {
      (this.$refs[ruleForm] as any).validate((valid: any) => {
        if (valid) {
          this.dialogFormVisible2 = false;
        } else {
          return false;
        }
      });
    },
    // // 每次打开对话框删除form表单
    // getOpen(formName: any) {
    //   this.$nextTick(() => {
    //     (this.$refs[formName] as any).resetFields();
    //   });
    // },
    // 查看
    getModify(val: any) {
      this.complete_id = val.complete_id;
      this.template_id = val.template_id;
      this.checkid = val.id;
      this.getExportView();
      this.drawer = true;
    },
    // 查看的请求
    async getExportView() {
      const data = {
        filter: {
          varant_name: this.barcodeCoding,
        },
        id: this.checkid,
        // template_id: this.template_id,
        page: this.seePage,
        limit: '10',
      };
      const res: any = await getExportView(data);
      if (res.code == 200) {
        // this.inventory = res.data.inventory;
        this.seeData = res.data.rows;
        this.seeLength = res.data.total;
      }
    },

    // 下载
    async getDownload(val: any) {
      // const userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
      // axios({
      //   method: 'GET',
      //   url: 'http://amazon.wsandos.com/api/product_library/download',
      //   params: { id: val.id },
      //   responseType: 'blob',
      //   headers: {
      //     token: userInfo.token,
      //   },
      // })
      //   .then(res => {
      //     const blob = new Blob([res.data], {
      //       type: 'application/vnd.ms-excel',
      //     });
      // const url = window.URL.createObjectURL(blob);
      // window.location.href = url;
      const a = document.createElement('a');
      const data = {
        id: val.id,
      };
      const res: any = await getExportDownload(data);
      if (res.code == 200) {
        // 下载名称
        // a.download = res.data.url || 'download';
        // 设置excel的路径
        a.href = 'http://amazon.wsandos.com' + res.data.url;
        a.click();
        this.getRemittanceList();
      }
    },
    // 取消按钮
    getCancel() {
      this.dialogFormVisible1 = false;
    },
    handleCheckAllChange(val: any) {
      this.ruleForm.specificationsType = val ? this.cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value: any) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.ruleForm.specificationsType.length;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.ruleForm.specificationsType.length;
    },
  },
  watch: {},
});



























































































import Vue from 'vue';
import { getlibraryResultlist, getDeleteRecord } from '@/api';
export default Vue.extend({
  data() {
    return {
      //时间筛选
      createtime: [], //时间节点筛选
      //
      resultlistList: {} as any, //获取设计记录列表
      // 创建人的数据
      options: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          id: '1',
          label: '双皮奶',
        },
        {
          id: '2',
          label: '蚵仔煎',
        },
        {
          id: '3',
          label: '龙须面',
        },
        {
          id: '4',
          label: '北京烤鸭',
        },
      ],
      // 违禁词
      contrabandWord: '',
      // 创建人
      founder: [],
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 50,
      page: 1,
    };
  },
  mounted() {
    this.getlibraryResultlist();
  },
  methods: {
    //选择筛选的时间
    // chooseTime(val: any) {
    //   this.createtime = `${val[0]},${val[1]}`;
    // },
    // 重置按钮
    getReset() {
      this.createtime = [];
    },
    // 列表
    getlibraryResultlist() {
      const params = {
        filter: { createtime: this.createtime.toString() },
        op: { createtime: 'BETWEEN' },
        limit: '10',
        page: this.page,
      };
      getlibraryResultlist(params).then(res => {
        this.resultlistList = res.data;
      });
    },
    // 选中
    handleSelectionChange(val: any) {
      console.log(5241, val);
    },
    async handleDelete(id: any) {
      const data = { id };
      const res: any = await getDeleteRecord(data);
      if (res.code == 200) {
        this.getlibraryResultlist();
        this.$message({
          message: '删除成功',
          type: 'success',
        });
      }
    },
    handleClose(row: any) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id);
          })
          .catch(() => {
            console.log('取消删除');
          });
      }
    },
    // 清空选择
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getlibraryResultlist();
    },

    // 查看按钮
    getModify(val: any) {
      this.$router.push({ name: 'technicalResults', query: { id: val.id } });
    },
  },
  watch: {
    tableData() {
      if (!this.resultlistList.rows.length && this.page > 1) {
        this.page = this.page - 1;
        this.getlibraryResultlist();
      }
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"tac"},[_c('el-col',{attrs:{"span":12}},[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"default-active":_vm.index},on:{"open":_vm.handleOpen,"close":_vm.handleClose,"select":_vm.currentIndex}},[_c('el-submenu',{class:{ additionTilte: _vm.indexAddition == '0' },attrs:{"index":"1"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-location",class:{ iconImg3: _vm.indexAddition == '0' }}),_c('span',[_vm._v("用户管理")]),_c('div',{staticClass:"triangle",class:[
              _vm.colour == 0 ? 'bounce' : '',
              _vm.indexAddition != 0 ? 'black1' : '',
              _vm.balckTriangle != 0 ? 'black2' : '' ]})]),_c('el-menu-item-group',[_c('el-menu-item',{staticClass:"pull-over",class:{
              addition: _vm.index == '1-1' || _vm.$route.path == '/management',
            },attrs:{"index":"1-1"},on:{"click":function($event){return _vm.getJump(0)}}},[_vm._v("用户管理")]),_c('el-menu-item',{staticClass:"pull-over",class:{
              addition: _vm.index == '1-2' || _vm.$route.path == '/application',
            },attrs:{"index":"1-2"},on:{"click":function($event){return _vm.getJump(1)}}},[_vm._v("申请管理")])],1)],2),_c('el-menu-item',{staticClass:"over",attrs:{"index":"2"},on:{"click":function($event){return _vm.getJump(2)}}},[_c('i',{staticClass:"el-icon-document",class:{ iconImg1: _vm.index == '2' }}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("违禁词模板管理")])]),_c('el-menu-item',{staticClass:"over",attrs:{"index":"3"},on:{"click":function($event){return _vm.getJump(3)}}},[_c('i',{staticClass:"el-icon-setting",class:{ iconImg2: _vm.index == '3' }}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("违规图片模板管理")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }



















































































































































import Vue from 'vue';
import {
  getprohibitedWord,
  getEditList,
  getIncreaseList,
  getDelteProhibited,
} from '@/api';
export default Vue.extend({
  data() {
    return {
      // 创建人的数据
      options: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          id: '1',
          label: '双皮奶',
        },
        {
          id: '2',
          label: '蚵仔煎',
        },
        {
          id: '3',
          label: '龙须面',
        },
        {
          id: '4',
          label: '北京烤鸭',
        },
      ],
      // 违禁词
      contrabandWord: '',
      // 创建人
      founder: '',
      // 用户管理数据
      tableData: [],

      // 选中的数据，用于批量删除
      selectDate: [],
      // 新增用户
      form: {
        // 违禁词名称：
        accountName: '',
      },
      // 新增用户的验证
      rules: {
        accountName: [
          { required: true, message: '请输入违禁词', trigger: 'blur' },
        ],
      },
      // 当前页树
      page: 1,
      // 数据总数
      currentLength: 0,
      // 弹窗的显示与隐藏
      dialogFormVisible: false,
      // 标题
      title: '新增违禁词',
      // 违禁词id
      id: '',
      userInfo: {} as any,
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo') as any);

    this.getprohibitedWord();
  },
  methods: {
    // 请求列表
    async getprohibitedWord() {
      const search = this.contrabandWord;
      const filter = { 'user.real_name': this.founder };
      const limit = '10';
      const page = this.page;
      const res: any = await getprohibitedWord(
        search,
        JSON.stringify(filter),
        limit,
        page
      );
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
        // console.log(this.tableData);
      }
    },
    // 重置按钮
    getReset() {
      this.contrabandWord = '';
      this.founder = '';
    },
    // 禁止选中
    checkSelectable(row: any) {
      // 判断是什么身份  1是超管，2区域代理 3用户
      if (this.userInfo.role == '2') {
        if (row.role == '1') {
          return false;
        } else {
          return true;
        }
      } else if (this.userInfo.role == '3') {
        if (row.role != '3') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 搜索框
    getLookup() {
      // console.log(this.contrabandWord, this.founder);
      this.page = 1;
      this.getprohibitedWord();
    },
    // 选中
    handleSelectionChange(val: any) {
      this.selectDate = val;
      // console.log(this.selectDate);
    },
    // 删除按钮
    async handleDelete(id: any) {
      // console.log('删除单个', row);
      const res: any = await getDelteProhibited(id);
      if (res.code == 200) {
        // console.log(res);
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getprohibitedWord();
      }
    },
    // 清空选择
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      // 分页
      this.page = val;
      this.getprohibitedWord();
    },
    // 删除的弹窗
    handleClose(row: any) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id);
          })
          .catch(() => {
            console.log('取消删除');
          });
      } else if (this.selectDate.length) {
        this.$confirm('确认批量删除？')
          .then(() => {
            this.getBatchDelete();
          })
          .catch(() => {
            console.log('取消批量删除');
          });
      }
    },
    // 批量删除
    async getBatchDelete() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      const res: any = await getDelteProhibited(id.toString());
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getprohibitedWord();
      }
    },
    //弹起新增
    getBounce() {
      this.title = '新增违禁词';
      this.form.accountName = '';
      this.dialogFormVisible = true;
    },
    //添加或者编辑违禁词按钮
    getDialogFormVisible(form: any) {
      (this.$refs[form] as any).validate((valid: any) => {
        if (valid) {
          if (this.title == '新增违禁词') {
            // 新增
            this.getIncreaseList();
          } else {
            // 编辑
            this.getEditList();
          }
        } else {
          console.log('表单为空');
          return false;
        }
      });
    },
    // 编辑接口
    async getEditList() {
      const data = {
        name: this.form.accountName,
        id: this.id,
      };
      const res: any = await getEditList(data);
      if (res.code == 200) {
        this.dialogFormVisible = false;
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getprohibitedWord();
      }
    },
    // 添加接口
    async getIncreaseList() {
      // const userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
      const data = {
        name: this.form.accountName,
        user_id: this.userInfo.user_id,
        role: this.userInfo.role,
      };
      const res: any = await getIncreaseList(data);
      if (res.code == 200) {
        this.dialogFormVisible = false;
        // 刷新
        this.$router.go(0);
      }
    },
    // 每次打开对话框删除form表单
    // getOpen(formName: any) {
    //   this.$nextTick(() => {
    //     (this.$refs[formName] as any).resetFields();
    //   });
    // },
    // 取消按钮
    getCancel() {
      this.dialogFormVisible = false;
    },
    // 编辑按钮
    getModify(val: any) {
      this.title = '编辑违禁词';
      this.id = val.id;
      this.form.accountName = val.name;
      this.dialogFormVisible = true;
    },
  },
  watch: {
    // 监听删除第二页之后请求上一页的操作
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getprohibitedWord();
      }
    },
  },
});

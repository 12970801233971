


















































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';

//canvas 绘图
import { fabric } from 'fabric';
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { Loading } from 'element-ui';
import AWS from 'aws-sdk';
import {
  getSourceMaterialList,
  getProductLiibraryBatch,
  getBatchDesign,
  postUploadBase64,
  getFolderList,
} from '@/api';
export default Vue.extend({
  data() {
    return {
      selectIndex: -1, // 一级选中
      openFileIndex: -2, //打开选中的文件
      isFile: false, //是否打开弹窗提示
      fileList: [] as any, //文件列表
      parentId: 0, //选中元素的id

      imgArrayNum: 0, //记录成功后的数量
      userInfo: '' as any, //储存用户数据
      compound_no: '' as any, //当前保存产生的唯一id
      radioSelect: '2', //选中的
      fbxId: '' as any, //当前模板的id
      productDetail: {} as any, //当前产品的详情
      // 查看图片的显示与隐藏
      dialogImg: false,
      selectFbxIndex: 0, //选中模板序号
      selectFrameIndex: 'unFrame', //选中的设计规格
      // 素材库的显示与隐藏
      dialogMaterial: false,
      materialName: '', //名称
      createtime: '', //时间节点筛选
      createtime1: '',
      value: '', //格式
      wide: '', //宽
      high: '', //高
      // 时间排序
      creationTime: '',
      // 大小排序
      size: '',
      // 素材总数
      total: 0,
      // 判断当前是否选中
      current: true,
      page: 1,
      // 素材库数据
      MaterialList: [] as any,
      // 选中的素材
      materialImgList: [] as any,
      indexList: [] as any,

      //fabric和canvas

      targetImg: '' as any, //canvas中的目标函数
      singlecreatetime: '', //单个设计的时间选择
      currentPage4: 1, //素材第多少页
      filterParams: {
        //单模板选择素材
        createtime: '',
        type: '',
        name: '', //搜索的名字
        order: 'desc',
      } as any, //素材图片筛选
      upLoading: false, //图片上传
      selectBgImgIndex: -1, //当前选中的图片
      selectIsFrame: 'unFrame', //当前点击是有框还是无框
      selectBgImgCont: { Image: '', name: '' } as any, //当前文件的名字
      photoList: {} as any, //素材库列表
      activeIndex2: '1', //导航下拉
      canvas: {} as any, //fabric使用的canvas
      canvas1: '' as any,
      context: null as any,
      canvasUrl: '', //绘制好的图片

      dialogVisible: false, //弹窗遮罩
      fabricImage: null as any, //添加图片事件
      operatingIndex: 1, //1为设计弹窗 2为列表弹窗
      framType: 1, //1为横向框架 ，2为竖直框架 3 为全屏

      config: {
        canvasState: [] as any,
        currentStateIndex: -1, //可撤回多少步骤
        redoStatus: false, //正在撤销状态
        undoStatus: false, // 正在重做状态
        undoFinishedStatus: 1,
        redoFinishedStatus: 1,
        undoButton: this.$refs.undo, //得不到  在 mounted 得到
        redoButton: this.$refs.redo,
        undeoClick: false, //是否可点击
        redoClick: true, //是否可操作取消撤回 true为无可撤销撤回操作
      },

      //fbx绘制模板图片
      slectPopIndex: 0, //弹窗选中的序号
      loadingInstance: null as any, //加载中弹窗遮罩
      imgPaste: false, //判断图片是否有贴入
      newListArray: [
        // {
        //   image: {
        //     unFrame: ['https://qie-online-sale-qiniu.wsandos.com/cuteGirl.jpg'],
        //     frame: ['https://qie-online-sale-qiniu.wsandos.com/cuteGirl.jpg'],
        //   },
        //   image_name: 'Dog With Beautiful Girl',
        // },
      ] as any, //绘制后的图片列表
      //canvas绘图参数
      getImageData: true, //可以生产图片
      // container: null as any, //画布
      camera: null as any, //相机视角
      scene: null as any, //创建this.scene
      renderer: null as any, //结构渲染器
      controls: null as any, //自由视角控制
      contTranslate: [-50, 25, 0], //向 x y z 轴平移的距离
      // geometry: null as any,//几何对象
      newImg: { unFrame: [], frame: [] } as any, //单个设计图片数组

      framImg: 'https://qie-online-sale-qiniu.wsandos.com/cuteGirl.jpg', //框架图片
      haveFarmImg: false, //是否展示框架图片
      lingtParam: {
        leftDown: {
          //向左向下阴影
          targetPosition: [0, 0, -100],
          position: [900, 180, -280], //前后 上下 左右
          far: 1500,
          near: 0.8,
          height: 2040, //上下
          width: 280, //左右
          left: -300,
          right: 1600,
          top: 1600,
          bottom: -300,
        },
        rightDown: {
          //向右向下阴影
          targetPosition: [0, 0, -100], //目标位置
          position: [900, 180, 20], //前后 上下 左右 坐标翻转定位翻转角度
          far: 1500,
          near: 200,
          height: 2010, //上下
          width: 380, //左右
          left: -200,
          right: 1500,
          top: 1600,
          bottom: -300,
        },
      } as any, //平行灯光设置
      c2List: {} as any,
    };
  },
  async beforeMount() {
    this.fbxId = (this.$route as any).query.id;
    const data = {
      id: this.fbxId,
    };
    const res: any = await getProductLiibraryBatch(data);
    if (res.code == 200) {
      this.productDetail = res.data;
      this.c2List = res.data.image_template.params;
      this.newImg = res.data.image_template.cover;
      this.framType = res.data.image_template.params.framType;
    }
  },
  mounted() {
    this.getFolderList();
    //执行循环canvas
    this.initRender();

    this.postMaterialList(); //获取单个设计的素材列表
    this.userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
  },
  methods: {
    //获取文件夹列表
    async getFolderList() {
      const res: any = await getFolderList();
      if (res.code == 200) {
        this.fileList = res.data;
      }
    },
    //打开二级列表
    openFileList(index: any) {
      this.selectIndex = this.selectIndex == index ? -1 : index;
    },
    //选中文件
    selctFile(index: any, id: any, num?: any) {
      this.openFileIndex = index;
      this.selectIndex = num ? index : -1;
      this.isFile = false;

      this.parentId = id;
      this.searchInput();
    },
    // 请求素材库裂表
    async getSourceMaterialList() {
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '获取素材中...',
      });
      let sort = '';
      let order = '';
      if (this.size == '' && this.creationTime == '') {
        sort = '';
      } else if (this.size == '倒序' || this.size == '正序') {
        sort = 'size';
        order = this.size == '倒序' ? 'desc' : ' asc';
      } else if (this.creationTime == '倒序' || this.creationTime == '正序') {
        sort = 'createtime';
        order = this.creationTime == '倒序' ? 'desc' : ' asc';
      }
      const data = {
        parent_id: this.parentId,
        filter: {
          title: this.materialName,
          type: this.value,
          createtime: this.createtime1,
        },
        limit: 18,
        page: this.page,
        op: { title: 'LIKE', type: '=', createtime: 'BETWEEN' },
        sort,
        order,
      };
      const res: any = await getSourceMaterialList(data);
      if (res.code == 200) {
        // 这里判断了请求是时候下一页或者上一页是否有勾选的图片
        this.indexList = [];
        // this.MaterialList = res.data;
        if (this.materialImgList.length) {
          for (let i = 0; i < res.data.rows.length; i++) {
            for (let t = 0; t < this.materialImgList.length; t++) {
              const index = res.data.rows.findIndex((item: any) => {
                return item.image == this.materialImgList[t].url;
              });
              this.indexList.push(index);
            }
            res.data.rows[i]['current'] = false;
          }
          this.indexList.map((item: any) => {
            if (item != -1) {
              res.data.rows[item]['current'] = true;
            }
          });
        } else {
          for (let i = 0; i < res.data.rows.length; i++) {
            res.data.rows[i]['current'] = false;
          }
        }
        this.MaterialList = res.data.rows;
        this.total = res.data.total;

        this.loadingInstance.close();
      }
    },
    getBatchBtn() {
      this.getSourceMaterialList();
      this.dialogMaterial = true;
    },
    // 查看大图
    getLookImg(index: any, isFrame: any) {
      this.dialogImg = true;
      this.selectFbxIndex = index;
      this.selectIsFrame = isFrame;
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getSourceMaterialList();
    },
    // 搜索
    getSearch() {
      this.page = 1;
      this.getSourceMaterialList();
    },
    // 确定 使用素材
    getMaterialBtn() {
      if (this.materialImgList.length) {
        //批量处理图片
        this.haveFarmImg = true;
        this.generateListImg(0);
        this.dialogMaterial = false;
      } else {
        this.$message('请选择素材');
      }
    },
    //选择筛选的时间
    chooseTime(val: any) {
      this.createtime1 = `${val[0] / 1000},${val[1] / 1000}`;
    },
    // 选中当前的素材
    getSelect(index: any) {
      if (
        this.materialImgList.length < 50 ||
        this.MaterialList[index].current
      ) {
        const newList = {
          url: this.MaterialList[index].image,
          name: this.MaterialList[index].title,
        };
        const iscontain = JSON.stringify(this.materialImgList).indexOf(
          JSON.stringify(newList)
        );
        if (this.MaterialList[index].current == false && iscontain == -1) {
          this.MaterialList[index].current = true;
          const nowChooseImg: any = {
            url: this.MaterialList[index].image,
            name: this.MaterialList[index].title,
          };
          this.materialImgList.push(nowChooseImg);
        } else if (iscontain != -1) {
          const lookupId = this.materialImgList.findIndex((item: any) => {
            return item.url == this.MaterialList[index].image;
          });
          this.materialImgList.splice(lookupId, 1);
          this.MaterialList[index].current = false;
        }
      } else {
        this.$message('至多选择10个图片');
      }
    },

    //canvas绘图

    /**
     * 创建渲染器对象
     */
    initRender() {
      this.renderer = new THREE.WebGLRenderer({
        //不支持线的宽度 CanvasRenderer才支持线的宽度
        antialias: true,
        alpha: true,
        // precision: "highp"
      });
      this.renderer.shadowMap.enabled = true; //开启阴影，加上阴影渲染
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; //定义阴影贴图类型
    },
    /**
     * 创建场景对象this.scene
     */
    initScene() {
      this.scene = new THREE.Scene();
    },

    /**
     * 相机设置
     */
    initCamera() {
      this.camera = new THREE.OrthographicCamera(0, 1000, 1000, 0, 1, 3000); //x 图片x 图片y y (left right top botom near far)
      this.camera.position.set(400, 0, 0); //设置相机位置 x y z
      this.camera.lookAt(this.scene.position); //设置相机方向(指向的场景对象) 默认为坐标原点（x:0，y:0，z：0）
    },
    /**
     * 光源设置
     */
    initLight(direction: any) {
      //环境光
      /*Lights*/
      const boardParam: any = this.lingtParam[direction];
      const [positionx, positiony, positionz] = boardParam.position;
      const [targetX, targetY, targetZ] = boardParam.targetPosition;
      const ambient = new THREE.AmbientLight(0x999999);
      // ambient.castShadow = true;
      this.scene.add(ambient);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.4); //平行光
      directionalLight.position.set(positionx, positiony, positionz);

      directionalLight.castShadow = true;
      directionalLight.receiveShadow = true;
      this.scene.add(directionalLight);
      directionalLight.shadow.camera.far = boardParam.far; //产生阴影的最远距离
      directionalLight.shadow.camera.left = boardParam.left; //产生阴影距离位置的最左边位置
      directionalLight.shadow.camera.right = boardParam.right; //最右边
      directionalLight.shadow.camera.top = boardParam.top; //最上边
      directionalLight.shadow.camera.bottom = boardParam.bottom; //最下面
      //这两个值决定使用多少像素生成阴影 默认512
      directionalLight.shadow.mapSize.height = boardParam.height;
      directionalLight.shadow.mapSize.width = boardParam.width;

      directionalLight.shadow.camera.near = boardParam.near; //远近
      const targetObject = new THREE.Object3D(); //灯光目标点
      targetObject.position.set(targetX, targetY, targetZ);
      this.scene.add(targetObject);
      directionalLight.target = targetObject;
    },
    //绘制图片封装
    allCanvas(direction: any, boardParam: any, canvasUrl: any) {
      //创建场景对象this.scene
      this.scene = new THREE.Scene();
      //相机设置
      this.camera = new THREE.OrthographicCamera(0, 500, 500, 0, 1, 3000); //x 图片x 图片y y (left right top botom near far)
      this.camera.position.set(400, 0, 0); //设置相机位置 x y z
      // this.camera.lookAt(this.scene.position); //设置相机方向(指向的场景对象) 默认为坐标原点（x:0，y:0，z：0）

      //环境光
      /*Lights*/
      const lingtParam: any = this.lingtParam[direction];
      const [positionx, positiony, positionz] = lingtParam.position;
      const [targetX, targetY, targetZ] = lingtParam.targetPosition;
      const ambient = new THREE.AmbientLight(0x444444);
      // ambient.castShadow = true;
      this.scene.add(ambient);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8); //平行光
      directionalLight.position.set(positionx, positiony, positionz);

      directionalLight.castShadow = true;
      directionalLight.receiveShadow = true;
      this.scene.add(directionalLight);
      directionalLight.shadow.camera.far = lingtParam.far; //产生阴影的最远距离
      directionalLight.shadow.camera.left = lingtParam.left; //产生阴影距离位置的最左边位置
      directionalLight.shadow.camera.right = lingtParam.right; //最右边
      directionalLight.shadow.camera.top = lingtParam.top; //最上边
      directionalLight.shadow.camera.bottom = lingtParam.bottom; //最下面
      //这两个值决定使用多少像素生成阴影 默认512
      directionalLight.shadow.mapSize.height = lingtParam.height;
      directionalLight.shadow.mapSize.width = lingtParam.width;

      directionalLight.shadow.camera.near = lingtParam.near; //远近
      const targetObject = new THREE.Object3D(); //灯光目标点
      targetObject.position.set(targetX, targetY, targetZ);
      this.scene.add(targetObject);
      directionalLight.target = targetObject;

      //光照配置结束
      // TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
      const textureLoader = new THREE.TextureLoader();
      const texture4 = textureLoader.load(boardParam.bgImg);
      const texture = textureLoader.load(canvasUrl);
      const material4 = new THREE.MeshLambertMaterial({
        map: texture4,
      });
      const material = new THREE.MeshLambertMaterial({
        visible: true, // ：是否可见，默认为 true
        map: texture,
      });
      this.renderer.setSize(1600, 1600); //像素设置
      const fbxLoader = new FBXLoader();
      fbxLoader.load(boardParam.fbx, object => {
        object.scale.set(0.5, 0.5, 0.5); //xyz缩放
        object.castShadow = boardParam.haveShadow; //开启灯光投射阴影
        object.receiveShadow = boardParam.haveShadow; //开启接受阴影
        object.traverse((child: any) => {
          if (child.name == '灯光_2') {
            //背景墙
            child.visible = false; //开启灯光投射阴影/
          }
          if (child.name == '平面') {
            child.castShadow = boardParam.haveShadow; //开启灯光投射阴影
            child.receiveShadow = boardParam.haveShadow; //开启接受阴影
            child.material = material4;
            if (child.children[0].name == '立方体') {
              //背景墙
              child.children[0].castShadow = boardParam.haveShadow; //开启灯光投射阴影
              child.children[0].receiveShadow = boardParam.haveShadow; //开启接受阴影

              if (this.haveFarmImg) {
                child.children[0].material = material;
              }
            }
          }
        });
        this.scene.add(object);

        // this.renderer.clear(); //清除缓存区

        // this.renderer.clearDepth(); //清除深度缓存区
        // this.renderer.render(this.scene, this.camera);
        // this.scene.remove(this.scene.children);
        // resolve(this.renderer.domElement.toDataURL('image/png', 1.0));
      });
    },

    async generateImg() {
      //单设计生成图片
      // this.container = document.getElementById('container');
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '拼命处理中',
      });

      const imgArray: any = {};
      for (let j = 0; j < 2; j++) {
        const framName = j == 0 ? 'unFrame' : 'frame';
        const nowFbxList: any = this.c2List[framName];
        let imgArrayList: any = [];
        for (let i = 0; i < nowFbxList.length; i++) {
          this.scene = null;
          this.camera = null;
          // THREE.Cache.clear();
          this.initScene();
          this.initCamera();
          this.initLight(nowFbxList[i].direction);
          const nowImg = await this.initModel(nowFbxList[i], this.canvasUrl);
          // this.allCanvas(nowFbxList[i].direction,nowFbxList[i], this.canvasUrl)

          imgArrayList = [...imgArrayList, nowImg];
          if (i == nowFbxList.length - 1) {
            imgArray[framName] = imgArrayList;
            if (j == 1) {
              this.newImg = imgArray;
              this.imgPaste = true;
              this.loadingInstance.close();
            }
          }
        }
      }
    },
    async generateListImg(num: number) {
      //批量处理图片
      //生成图片
      // this.container = document.getElementById('container');
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '拼命处理中',
      });
      const imgArray: any = {};
      for (let j = 0; j < 2; j++) {
        const framName = j == 0 ? 'unFrame' : 'frame';
        const nowFbxList: any = this.c2List[framName];
        let imgArrayList: any = [];
        for (let i = 0; i < nowFbxList.length; i++) {
          this.scene = null;
          this.camera = null;
          // THREE.Cache.clear();
          this.initScene();
          this.initCamera();
          this.initLight(nowFbxList[i].direction);
          const nowImg = await this.initModel(
            nowFbxList[i],
            this.materialImgList[num].url
          );
          console.log(i + '' + j, i, j);

          imgArrayList = [...imgArrayList, nowImg];
          if (i == nowFbxList.length - 1) {
            imgArray[framName] = imgArrayList;
            if (j == 1) {
              //
              const nowListArray = {
                image: imgArray,
                image_name: this.materialImgList[num].name,
              };
              this.newListArray.push(nowListArray);
              if (num < this.materialImgList.length - 1) {
                ++num;
                this.generateListImg(num);
              } else {
                this.materialImgList = [];
                this.loadingInstance.close();
              }
            }
          }
        }
      }
    },
    //加载纹理图片
    textureImg(img: any) {
      return new Promise(resolve => {
        // TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(img, texture => {
          const material4 = new THREE.MeshLambertMaterial({
            map: texture,
          });
          resolve(material4);
        });
      });
    },
    //创建立体对象
    async initModel(boardParam: any, canvasUrl: any) {
      //背景图片纹理
      const material4 = await this.textureImg(boardParam.bgImg);
      //框架图片纹理
      const material = await this.textureImg(canvasUrl);
      return new Promise(resolve => {
        this.renderer.setSize(1600, 1600); //像素设置
        const fbxLoader = new FBXLoader();
        fbxLoader.load(boardParam.fbx, object => {
          object.castShadow = boardParam.haveShadow; //开启灯光投射阴影
          object.receiveShadow = boardParam.haveShadow; //开启接受阴影
          object.traverse((child: any) => {
            if (child.name == '灯光_2') {
              //背景墙
              child.visible = false; //开启灯光投射阴影/
            }
            if (child.name == '平面') {
              child.castShadow = boardParam.haveShadow; //开启灯光投射阴影
              child.receiveShadow = boardParam.haveShadow; //开启接受阴影
              child.material = material4;
              if (child.children[0].name == '立方体') {
                //背景墙
                child.children[0].castShadow = boardParam.haveShadow; //开启灯光投射阴影
                child.children[0].receiveShadow = boardParam.haveShadow; //开启接受阴影

                if (this.haveFarmImg) {
                  child.children[0].material = material;
                }
              }
            }
          });
          this.scene.add(object);
          this.renderer.render(this.scene, this.camera);
          resolve(this.renderer.domElement.toDataURL('image/png', 1.0));

          // this.renderer.clear(); //清除缓存区
          // this.renderer.clearDepth(); //清除深度缓存区
          // this.scene.remove(this.scene.children);
        });
      });
    },

    //fabric 编辑图片

    //单素材库设计
    postMaterialList() {
      const filte = {
        title: this.filterParams.name,
        type: this.filterParams.type,
        createtime: this.singlecreatetime,
      };
      const data = {
        limit: 16,
        parent_id: this.parentId,
        page: this.currentPage4,
        order: this.filterParams.order,
        filter: JSON.stringify(filte),
        op: '{"title":"LIKE","type":"=","createtime":"BETWEEN"}',
        sort: 'createtime',
      };
      getSourceMaterialList(data).then(res => {
        this.photoList = res.data;
      });
    },
    //保存单个设计
    singleSave() {
      if (!this.imgPaste) {
        this.$message({
          message: '请先合成图片',
          type: 'warning',
        });
        return;
      }
      const nowListArray = {
        image: this.newImg,
        image_name: this.selectBgImgCont.title,
      };
      this.newListArray[this.selectFbxIndex] = nowListArray;
      this.dialogVisible = false;
    },

    /*2:转bob*/
    /**
     * 将以base64的图片url数据转换为Blob
     * @param base64    用url方式表示的base64图片数据
     * @return blob     返回blob对象
     */
    convertBase64UrlToBlob(base64: any) {
      const type = base64.split(',')[0].match(/:(.*?);/)[1]; //提取base64头的type如 'image/png'
      const bytes = window.atob(base64.split(',')[1]); //去掉url的头，并转换为byte (atob:编码 btoa:解码)

      //处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length); //通用的、固定长度(bytes.length)的原始二进制数据缓冲区对象
      const ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: type });
    },
    //上传base64图片到aw s3 亚马逊上
    postUploadBase64(file: any) {
      return new Promise((resolve, reject) => {
        const nowTime = new Date().getTime();
        let Num = '';
        for (let i = 0; i < 5; i++) {
          Num += Math.floor(Math.random() * 10);
        }
        const timestamp = nowTime + Num;
        const key = 'AKIA5LDVNQFTN4FVXCHR';
        const secret = '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';
        const s3 = new AWS.S3({
          accessKeyId: key,
          secretAccessKey: secret,
          region: 'cn-north-1',
        });
        const params = {
          Bucket: 'yijiang',
          Key: this.userInfo.id + timestamp,
          Body: file,
          ACL: 'public-read',
          // 'Access-Control-Allow-Credentials': '*',
          ContentEncoding: 'base64',
          ContentType: 'image/jpeg',
        };

        s3.putObject(params, (err: any) => {
          if (err) {
            reject(err);
          } else {
            this.imgArrayNum += 1;
            if (this.imgArrayNum == this.newListArray.length * 10) {
              console.log(this.imgArrayNum, 'this.imgArrayNum');
              this.getBatchDesign();
            }
          }
        });

        const newUrl =
          'https://yijiang.s3.cn-north-1.amazonaws.com.cn/' + params.Key;
        resolve(newUrl);
      });
    },
    //上传base4的图片接口
    postUploadBase64A(file: any) {
      return new Promise((resolve, reject) => {
        postUploadBase64({ file, compound_no: this.compound_no })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    //确认合成
    async compositieFrame() {
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '图片提交中...',
      });
      this.imgArrayNum = 0;

      const timestamp = new Date().getTime();
      let Num = '';
      for (let i = 0; i < 5; i++) {
        Num += Math.floor(Math.random() * 10);
      }
      this.compound_no = timestamp + Num;

      for (let j = 0; j < this.newListArray.length; j++) {
        const imgArray: any = {};

        for (let z = 0; z < 2; z++) {
          const framName = z == 0 ? 'unFrame' : 'frame';
          const nowFbxList: any = this.newListArray[j].image[framName];

          let imgArrayList: any = [];
          for (let i = 0; i < nowFbxList.length; i++) {
            // const pic = nowFbxList[i].split('base64,')[1];
            const pic = await this.convertBase64UrlToBlob(nowFbxList[i]);

            const url: any = await this.postUploadBase64(pic);
            // imgArrayList = [...imgArrayList, url.url];
            imgArrayList = [...imgArrayList, url];
            if (i == nowFbxList.length - 1) {
              //上传完成后执行
              imgArray[framName] = imgArrayList;
              if (z == 1) {
                this.newListArray[j].image = imgArray;

                // if (j == this.newListArray.length - 1) {
                //   this.getBatchDesign();
                // }
              }
              // pathImg= imgArray
            }
          }
        }
      }
    },
    //批量数据提交接口
    async getBatchDesign() {
      const data = {
        id: this.productDetail.id,
        image: JSON.stringify(this.newListArray),
        type: this.radioSelect,
        compound_no: this.compound_no,
      };
      const res: any = await getBatchDesign(data);
      if (res.code == 200) {
        this.$message('批量设计成功');
        this.loadingInstance.close();
        this.$router.push({
          path: '/finishedProduct',
        });
      }
    },
    delectFbxImg(index: any) {
      this.newListArray.splice(index, 1);
    },
    //大图canvas展示
    bigCanvasPop() {
      this.operatingIndex = 2;
    },
    //返回绘图操作
    backCanvasPop() {
      this.operatingIndex = 1;
    },
    chooseSingleTime(val: any) {
      this.singlecreatetime = `${val[0] / 1000},${val[1] / 1000}`;

      this.currentPage4 = 1;
      this.postMaterialList();
    },
    //单图设计素材分页切换的时候
    singleCurrentChange(val: any) {
      this.currentPage4 = val;
      this.postMaterialList();
    },
    //下拉选择
    handleSelect(key: any, keyPath: any) {
      if (keyPath[0] == 1) {
        return;
      }
      if (keyPath[0] == 3) {
        this.filterParams = {
          createtime: '',
          type: '',
          name: '', //搜索的名字
          order: 'desc',
        };
        this.createtime = '';
      } else {
        this.filterParams[`${keyPath[0]}`] = key;
      }
      this.currentPage4 = 1;
      this.postMaterialList();
    },
    //素材输入搜索的值
    searchInput() {
      // if (this.filterParams.name.length > 0) {
      this.currentPage4 = 1;
      this.postMaterialList();
      // }
    },
    //文件上传时提示
    onUploadImg() {
      this.upLoading = true;
    },
    //上传图片到七牛云
    uploadImg(response: any, file: any) {
      this.selectBgImgCont.image = response.data.url;
      this.selectBgImgCont.title = file.name;
      this.upLoading = false;
      this.addBgImg();
    },

    // 设置按钮
    handleDelete(index: any) {
      this.selectFbxIndex = index;
      this.imgPaste = false;
      // this.framType = this.fbxId == 2 ? 2 : this.fbxId == 1 ? 3 : 1;
      this.dialogVisible = true;
      // this.newImg = row.image;
      setTimeout(() => {
        this.addFavric();
        this.canvasDataChange(); //监听canvas 事件
      }, 200);
    },

    //关闭绘图弹窗
    colsPop() {
      this.dialogVisible = false;
    },

    //选择对应的图片
    slectBgImg(index: any) {
      this.selectBgImgIndex = index;
      this.selectBgImgCont = this.photoList.rows[index];
      this.addBgImg();
    },

    //创建fabric
    addFavric() {
      this.canvas = new fabric.Canvas('canvas');
      this.canvas.renderAll();
    },
    // 记录事件
    canvasDataChange() {
      this.canvas.on('object:modified', () => {
        this.updateCanvasState();
      });
      this.canvas.on('object:added', () => {
        this.updateCanvasState();
      });
      this.canvas.on('object:removed', () => {
        this.updateCanvasState();
      });
      this.canvas.on('object:rotating', () => {
        this.updateCanvasState();
      });

      this.canvas.on('selection:updated', (e: any) => {
        this.onObjectSelected(e);
      });
      this.canvas.on('selection:created', (e: any) => {
        this.onObjectSelected(e);
      });
      this.canvas.on('selection:cleared', () => {
        this.targetImg = '';
      });
      // this.canvas.renderAll();
    },

    fillImg() {
      //图片自适应填充
      const newObject: any = this.targetImg;
      if (newObject == '') {
        this.$message({
          message: '请先选中图层',
          type: 'warning',
        });
        return;
      }
      const fbxW = this.framType == 2 ? 400 : 600;
      const fbxH = this.framType == 1 ? 400 : 600;

      const scaleFactor = Math.max(
        Math.min(fbxW / newObject.width),
        Math.min(fbxH / newObject.height)
      );
      newObject.scale(scaleFactor);
      (newObject.left =
        this.framType == 2
          ? 100 - (newObject.width * scaleFactor - 398) / 2
          : -(newObject.width * scaleFactor - 600) / 2), //剧中计算
        (newObject.top =
          this.framType == 1
            ? 100 - (newObject.height * scaleFactor - 400) / 2
            : -(newObject.height * scaleFactor - 600) / 2), //剧中计算,
        this.canvas.renderAll();
    },
    onObjectSelected(e: any) {
      //选中目标改变
      this.targetImg = e.target;
    },
    // 产生farbic的历史操作记录
    updateCanvasState() {
      if (this.config.undoStatus == false && this.config.redoStatus == false) {
        const jsonData = this.canvas.toJSON();
        const canvasAsJson = JSON.stringify(jsonData);
        if (
          this.config.currentStateIndex <
          this.config.canvasState.length - 1
        ) {
          const indexToBeInserted = this.config.currentStateIndex + 1;
          this.config.canvasState[indexToBeInserted] = canvasAsJson;
          const numberOfElementsToRetain = indexToBeInserted + 1;
          this.config.canvasState = this.config.canvasState.splice(
            0,
            numberOfElementsToRetain
          );
        } else {
          this.config.canvasState.push(canvasAsJson);
        }
        this.config.currentStateIndex = this.config.canvasState.length - 1;
      }
    },

    redo() {
      //取消准备操作
      if (this.config.redoClick) {
        return;
      }
      if (this.config.redoFinishedStatus) {
        if (
          this.config.currentStateIndex == this.config.canvasState.length - 1 &&
          this.config.currentStateIndex != -1
        ) {
          // this.config.redoButton.disabled= true;
          this.config.redoClick = true;
        } else {
          if (
            this.config.canvasState.length > this.config.currentStateIndex &&
            this.config.canvasState.length != 0
          ) {
            this.config.redoFinishedStatus = 0;
            this.config.redoStatus = true;
            this.canvas.loadFromJSON(
              this.config.canvasState[this.config.currentStateIndex + 1],
              () => {
                // const jsonData = JSON.parse(
                //   this.config.canvasState[this.config.currentStateIndex + 1]
                // );
                this.canvas.renderAll();
                this.config.redoStatus = false;
                this.config.currentStateIndex += 1;
                if (this.config.currentStateIndex != -1) {
                  //    this.config.redoButton.disabled = false;

                  this.config.redoClick = false;
                }
                this.config.redoFinishedStatus = 1;
                if (
                  this.config.currentStateIndex ==
                    this.config.canvasState.length - 1 &&
                  this.config.currentStateIndex != -1
                ) {
                  // this.config.redoButton.disabled= true;

                  this.config.redoClick = true;
                }
              }
            );
          }
        }
      }
    },
    undo() {
      //撤消操作事件
      if (this.config.currentStateIndex == -1) {
        return;
      }
      if (this.config.undoFinishedStatus) {
        if (this.config.currentStateIndex == -1) {
          this.config.undoStatus = false;
        } else {
          if (this.config.canvasState.length >= 1) {
            this.config.undoFinishedStatus = 0;
            if (this.config.currentStateIndex != 0) {
              this.config.undoStatus = true;
              this.canvas.loadFromJSON(
                this.config.canvasState[this.config.currentStateIndex - 1],
                () => {
                  // const jsonData = JSON.parse(
                  //   this.config.canvasState[this.config.currentStateIndex - 1]
                  // );
                  this.canvas.renderAll();
                  this.config.undoStatus = false;
                  this.config.currentStateIndex -= 1;
                  // this.config.undoButton.removeAttribute("disabled");
                  // this.config.undoButton.disabled = false;

                  this.config.undeoClick = false;
                  if (
                    this.config.currentStateIndex !==
                    this.config.canvasState.length - 1
                  ) {
                    // this.config.redoButton.removeAttribute('disabled');
                    // this.config.redoButton.disabled = false;

                    this.config.redoClick = false;
                  }
                  this.config.undoFinishedStatus = 1;
                }
              );
            } else if (this.config.currentStateIndex == 0) {
              this.canvas.clear();
              this.config.undoFinishedStatus = 1;
              // this.config.undoButton.disabled= "disabled";
              // this.config.redoButton.removeAttribute('disabled');
              // this.config.redoButton.disabled = false;

              this.config.redoClick = false;
              this.config.currentStateIndex -= 1;
            }
          }
        }
      }
    },
    //单设计剪裁图片
    cutImg() {
      //1为横屏 2为竖屏，3 为全凭截图
      // 把组合设置为选中
      if (this.fabricImage) {
        this.canvas.setActiveObject(this.fabricImage);
      } else {
        this.$message({
          message: '请先选择一张需要合成的图片',
          type: 'warning',
        });
        return;
      }
      // // // 把拆分开的每一个模块进行取消选中状态
      this.canvas.discardActiveObject();
      this.canvas.renderAll();

      const clipcanvas: any = document.getElementById('clipcanvas');
      const canvas: any = document.getElementById('canvas');
      const shadow: number = (canvas.width * 100) / 600; //阴影部分放大缩小后尺寸计算

      const cliptpe = {
        width: this.framType == 2 ? canvas.width - shadow * 2 : canvas.width, /// 600为计算阴影的宽度
        height: this.framType == 1 ? canvas.height - shadow * 2 : canvas.height,
      };
      clipcanvas.width = cliptpe.width;
      clipcanvas.height = cliptpe.height;

      const clipCtx = clipcanvas.getContext('2d');

      const canvasCtx = canvas.getContext('2d');

      const data =
        canvasCtx.getImageData(
          this.framType == 2 ? (canvas.width * 100) / 600 : 0,
          this.framType == 1 ? (canvas.width * 100) / 600 : 0,
          cliptpe.width,
          cliptpe.height
        ) || null; //(area.x,area.y,area.w,area.h); //剪裁图片

      clipCtx.putImageData(data, 0, 0);

      this.canvasUrl = clipcanvas.toDataURL('image/png');

      this.haveFarmImg = true; //开始填入框架图片
      this.newImg = { unFrame: [], frame: [] };
      this.generateImg();
      // clipCtx.drawImage(this.canvas, 500, 500, 500, 500);
    },
    //删除图片
    deletBgImg() {
      this.canvas.remove(this.canvas.getActiveObject()); //删除当前图层图片
    },
    //添加背景图片
    addBgImg() {
      const image = new Image();
      // image.src = 'https://qie-online-sale-qiniu.wsandos.com/bgGirl.jpg';
      const url = this.selectBgImgCont.image;
      const reg = new RegExp('https://qie-online-sale-qiniu.wsandos.com');
      const strImg = url.replace(reg, 'http://amazon.wsandos.com');

      image.src = strImg; // this.getBase64Image(this.selectBgImgCont.image);
      image.crossOrigin = 'Anonymous';
      image.onload = () => {
        //首先获取image的属性

        const fbxW = this.framType == 2 ? 400 : 600;
        const fbxH = this.framType == 1 ? 400 : 600;
        const scaleFactor = Math.min(
          Math.min(fbxW / image.width),
          Math.min(fbxH / image.height)
        );
        this.fabricImage = new fabric.Image(image, {
          left:
            this.framType == 2
              ? 99 - (image.width * scaleFactor - 400) / 2
              : -(image.width * scaleFactor - 600) / 2, //剧中计算
          top:
            this.framType == 1
              ? 99 - (image.height * scaleFactor - 400) / 2
              : -(image.height * scaleFactor - 600) / 2, //剧中计算,
          width: image.width,
          height: image.height,
          scaleX: scaleFactor,
          //取短边展示
          scaleY: scaleFactor,
        });

        this.fabricImage.set({
          //可设置边框的属性
          transparentCorners: false, //
          cornerColor: 'blue',
          cornerStrokeColor: 'red',
          borderColor: 'red',
          // cornerSize: 12,
          // padding: 10,
          cornerStyle: 'triangle',
          borderDashArray: [3, 3],
        });

        this.canvas.add(this.fabricImage);
        this.canvas.setActiveObject(this.fabricImage);

        // 把组合设置为选中
        // this.canvas.setActiveObject(this.fabricImage);
        // 把选中的组合 进行拆分组
        // this.canvas.getActiveObject().toActiveSelection();
        // // 把拆分开的每一个模块进行取消选中状态
        // this.canvas.discardActiveObject();
        // 重新渲染
        this.canvas.renderAll();
      };
      // this.canvas.renderAll();
    },
  },
  watch: {
    creationTime() {
      if (this.creationTime != '') {
        this.size = '';
        this.getSourceMaterialList();
      }
    },
    size() {
      if (this.size != '') {
        this.creationTime = '';
        this.getSourceMaterialList();
      }
    },
  },
});























































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import {
  getCompleteList,
  getFinishedDeletion,
  getProductList,
  getProductEditor,
  getExportProducts,
  getExportData,
  getFinishedDetail,
} from '../api';
export default Vue.extend({
  data() {
    return {
      options: [] as any,
      // 时间
      contrabandWord: [] as any,
      // 产品名称
      productName: '',
      // 类目的选择
      category: '',
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 0,
      // 选中的数据，用于批量删除
      selectDate: [],
      // 配置的
      form: {
        //  母体
        // 成品id
        matrixFinished: '',
        // 材质
        matrixTexture: '',
        // 素材
        matrixSource: '',
        // 产品名称
        matrixProductName: '',
      },
      variantFrom: {
        oneCycle: [
          {
            // 变体
            // 成品id
            variantFinished: '',
            // 材质
            variantTexture: '',
            // 素材
            variantSource: '',
            // 产品名称
            variantProductName: '',
            // 成品颜色
            productColor: '',
            md5: '',
          },
        ],
      } as any,
      productForm: {
        // 产品模板
        countryCode: '',
        // 商品模板
        manufactorCode: '',
      },
      rules: {
        matrixFinished: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        matrixTexture: [{ required: true, message: '请输入', trigger: 'blur' }],
        matrixProductName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' },
        ],
        variantFinished: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        variantTexture: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        variantProductName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' },
        ],
        productColor: [
          { required: true, message: '请输入成品颜色', trigger: 'blur' },
        ],
        countryCode: [
          { required: true, message: '请选择产品模板', trigger: 'blur' },
          //  { validator: checkUsername, trigger: "blur" }
        ],
        manufactorCode: [
          { required: true, message: '请选择商品模板', trigger: 'blur' },
        ],
        specificationsType: [
          {
            type: 'array',
            required: true,
            message: '请选择至少一种尺寸',
            trigger: 'change',
          },
        ],
        withoutFrameType: [
          {
            type: 'array',
            required: true,
            message: '请选择至少一种',
            trigger: 'change',
          },
        ],
      },
      // 汇出的
      ruleForm: {
        // 规格
        specificationsType: [''],
        // 有无框
        withoutFrameType: [],
      },
      // 变体数据
      variantList: [] as any,
      // 变体当前选择的索引
      currentId: 0,
      cityOptions: ['xxl', 'xl'],
      checkAll: false,
      isIndeterminate: true,
      drawer: false,
      // 预览轮播图片
      dialogVisible2: false,
      // 下载
      dialogVisible3: false,
      // 下载搜索
      finishedProduct: '',
      radio1: '',
      radio2: '无框',
      dialogFormVisible2: false,
      dialogFormVisible1: false,
      // 汇出是否有填写
      isFill: false,
      sku: '母体SKU-<color_name>-<size_name>',
      skuRule:
        '变体SKU=1:母体SKU-<color_name>-<size_name>,2:母体下按变体顺序从1开始自增,3:与母体规则一致',
      // 分页
      page: 1,
      // 预览的大图
      imgList: [] as any,
      // 母体图片
      matrixImg: [] as any,
      // 变体图片
      variantImg: [{}] as any,
      // 尺寸
      SpecificationsSize: [{ radio1: '' }] as any,
      defaultImg1: '',
      defaultImg2: [] as any,
      // 成品id
      id: '',
      // 汇出选中产品模板的id
      productId: '',
      ExportData: {} as any,
      loading: false,
    };
  },
  mounted() {
    this.getCompleteList();
    // 请求产品模板
    this.getProductList();
  },
  methods: {
    // 成品管理的
    async getCompleteList() {
      const data = {
        filter: {
          'compound_no|product_name': this.productName,
          product_classify: this.category,
          createtime: this.contrabandWord.toString(),
        },
        op: {
          createtime: 'BETWEEN',
          'compound_no|product_name': 'LIKE',
          product_classify: 'LIKE',
        },
        limit: '10',
        page: this.page,
      };
      const res: any = await getCompleteList(data);
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },
    // 搜索框
    getLookup() {
      this.page = 1;
      this.getCompleteList();
    },
    // 重置按钮
    getReset() {
      this.category = '';
      this.contrabandWord = '';
      this.productName = '';
    },
    // 选中
    handleSelectionChange(val: any) {
      this.selectDate = val;
    },
    getRowKeys(row: any) {
      //分页选中
      return row.id;
    },
    // 列表的详情
    async getFinishedDetail(id: any) {
      const data = {
        id,
      };
      const res: any = await getFinishedDetail(data);
      if (res.code == 200) {
        this.form.matrixFinished = res.data.complete_id;
        this.form.matrixTexture = res.data.texture;
        this.form.matrixSource = res.data.material;
        this.form.matrixProductName = res.data.product_name;
        this.matrixImg = [];
        for (let i = 0; i < res.data.image.length; i += 5) {
          this.matrixImg.push(res.data.image.slice(i, i + 5));
        }
        // this.matrixImg = res.data.image;
        this.defaultImg1 = this.matrixImg[0][0];
        this.variantList = res.data.variant;
        this.variantFrom.oneCycle = [];
        this.SpecificationsSize = [];
        this.variantImg = [];
        this.defaultImg2 = [];
        Object.keys(this.variantList).forEach(key => {
          const item = {
            variantFinished: this.variantList[key][this.currentId].variant_id,
            variantTexture: this.variantList[key][this.currentId]
              .variant_texture,
            variantSource: this.variantList[key][this.currentId]
              .variant_material,
            variantProductName: this.variantList[key][this.currentId]
              .varant_name,
            md5: this.variantList[key][this.currentId].md5,
          };
          const item2 = [] as any;
          for (
            let i = 0;
            i < this.variantList[key][this.currentId].variant_image.length;
            i += 5
          ) {
            item2.push(
              this.variantList[key][this.currentId].variant_image.slice(
                i,
                i + 5
              )
            );
          }
          const item1 = {
            radio1: this.variantList[key][this.currentId].size,
          };
          this.variantFrom.oneCycle.push(item);
          this.SpecificationsSize.push(item1);
          this.variantImg.push(item2);
        });
        for (let i = 0; i < this.variantImg.length; i++) {
          this.defaultImg2.push(this.variantImg[i][0][0]);
        }
        // this.SpecificationsSize.radio1;
        this.drawer = true;
      }
    },
    getProductVariants(index1: any) {
      for (let i = 0; i < this.variantList[index1].length; i++) {
        if (
          this.variantList[index1][i].size ==
          this.SpecificationsSize[index1].radio1
        ) {
          this.variantFrom.oneCycle[index1].variantFinished = this.variantList[
            index1
          ][i].variant_id;
          this.variantFrom.oneCycle[index1].variantTexture = this.variantList[
            index1
          ][i].variant_texture;
          this.variantFrom.oneCycle[index1].variantSource = this.variantList[
            index1
          ][i].variant_material;
          this.variantFrom.oneCycle[
            index1
          ].variantProductName = this.variantList[index1][i].varant_name;
          this.defaultImg2[index1] = this.variantList[index1][
            i
          ].variant_image[0];
          const item2 = [] as any;
          for (
            let t = 0;
            t < this.variantList[index1][i].variant_image.length;
            t += 5
          ) {
            item2.push(
              this.variantList[index1][i].variant_image.slice(t, t + 5)
            );
          }
          this.variantImg[index1] = item2;
        }
      }
      this.$forceUpdate();
      // this.variantFrom[index1].oneCycle.variantFinished
      // this.variantFrom[index1].oneCycle.variantTexture
      // this.variantFrom[index1].oneCycle.variantSource
      // this.variantFrom[index1].oneCycle.variantProductName
    },
    // 编辑
    getModify(row: any) {
      this.getFinishedDetail(row.id);
      // 母体的赋值
      this.id = row.id;
    },
    // 下载的搜索内容
    getDownloadSearch() {
      console.log(this.finishedProduct);
    },
    // 下载
    getDownload(row: any) {
      const a = document.createElement('a');
      // 图片名
      a.download = name || 'pic';
      // 设置图片地址
      a.href = row.imgUrl;
      a.click();
    },
    // 单个汇出
    getRemittance(row: any) {
      this.id = row.id;
      this.isFill = false;
      this.dialogFormVisible1 = true;
    },
    // 产品模板选中的监听 val为id
    changeVal(val: any) {
      this.productId = val;
      this.getExportData();
    },
    async getExportData() {
      const data = {
        complete_id: this.id,
        template_id: this.productId,
      };
      const res: any = await getExportData(data);
      if (res.code == 200) {
        this.ExportData = res.data;
        this.loading = false;
        this.isFill = true;
      }
    },

    // 请求产品模板
    async getProductList() {
      const data = {
        filter: {},
        page: 1,
        limit: '100',
      };
      const res: any = await getProductList(data);
      if (res.code == 200) {
        Object.keys(res.data.rows).forEach(key => {
          const item = {
            id: res.data.rows[key].id,
            label: res.data.rows[key].name,
          };
          this.options.push(item);
        });
      }
    },
    // 改变展示的图片
    getChangeImg(img: any, index: number, index1: any) {
      if (index == 1) {
        this.defaultImg1 = img;
      } else {
        this.defaultImg2[index1] = img;
        this.$forceUpdate();
      }
    },
    // 清空选择
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getCompleteList();
    },
    //产品汇出的确定按钮
    getDialogFormVisible(productForm: any) {
      (this.$refs[productForm] as any).validate((valid: any) => {
        if (valid) {
          // this.dialogFormVisible1 = false;
          this.getExportProducts();
        } else {
          return false;
        }
      });
    },
    // 产品汇出
    async getExportProducts() {
      const data = {
        complete_id: this.id,
        template_id: this.productId,
      };
      const res: any = await getExportProducts(data);
      if (res.code == 200) {
        this.$message({
          message: '汇出成功',
          type: 'success',
        });
        this.dialogFormVisible1 = false;
        this.getCompleteList();
      }
    },
    //批量汇出
    getBounce() {
      if (this.selectDate.length) {
        this.BatchRemittance();
      }
    },
    // 刷新
    getAttribute() {
      this.loading = true;
      this.getExportData();
    },
    // 批量汇出请求
    BatchRemittance() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      this.id = id.toString();
      // this.id = this.id.replace(/,/g, ';');

      this.isFill = false;
      this.dialogFormVisible1 = true;
    },
    // 指定变体汇出的确定
    getAppoint(ruleForm: any) {
      (this.$refs[ruleForm] as any).validate((valid: any) => {
        if (valid) {
          this.dialogFormVisible2 = false;
        } else {
          return false;
        }
      });
    },
    // 确定配置
    getEditBtn(form: any) {
      (this.$refs[form] as any).validate((valid: any) => {
        if (valid) {
          this.getProductEditor();
          // this.drawer = false;
        } else {
          console.log('表单为空');
          return false;
        }
      });
    },
    // 配置的修改
    async getProductEditor() {
      // for(let i=0;i<this.variantFrom.oneCycle.length;i++){
      //   const
      // }
      const variant = [] as any;
      Object.keys(this.variantFrom.oneCycle).forEach(key => {
        const item = {
          md5: this.variantFrom.oneCycle[key].md5,
          variant_material: this.variantFrom.oneCycle[key].variantSource,
        };
        variant.push(item);
      });
      const data = {
        matrix_id: this.id,
        matrix_name: this.form.matrixSource,
        variant: JSON.stringify(variant),
      };
      const res: any = await getProductEditor(data);
      if (res.code == 200) {
        this.drawer = false;
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getCompleteList();
      }
    },
    // 批量删除
    async getBatchDelete() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      const data = {
        id: id.toString(),
      };
      const res: any = await getFinishedDeletion(data);
      if (res.code == 200) {
        this.getCompleteList();
        this.$message({
          message: '删除成功',
          type: 'success',
        });
      }
    },
    // 删除按钮
    async handleDelete(id: any) {
      const data = { id };
      const res: any = await getFinishedDeletion(data);
      if (res.code == 200) {
        this.getCompleteList();
        this.$message({
          message: '删除成功',
          type: 'success',
        });
      }
    },
    // 删除按钮
    handleClose(row: any) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id);
          })
          .catch(() => {
            console.log('取消删除');
          });
      } else if (this.selectDate.length) {
        this.$confirm('确认批量删除？')
          .then(() => {
            this.getBatchDelete();
          })
          .catch(() => {
            console.log('取消批量删除');
          });
      }
    },
    // 每次打开对话框删除form表单
    getOpen(formName: any) {
      this.$nextTick(() => {
        (this.$refs[formName] as any).resetFields();
      });
    },
    // 全选
    handleCheckAllChange(val: any) {
      this.ruleForm.specificationsType = val ? this.cityOptions : [];
      this.isIndeterminate = false;
    },
    // 监听是否全选
    handleCheckedCitiesChange(value: any) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.ruleForm.specificationsType.length;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.ruleForm.specificationsType.length;
    },
    // 查看大图片
    getViewPicture(image: any) {
      this.imgList = image;
      // this.selectIndex = index;
      // this.setActiveItem(index);
      this.dialogVisible2 = true;
    },
    setActiveItem(i: any) {
      if (this.$refs.carousel) {
        // 手动切换幻灯片 setActiveItem(i)(Carousel Methods)
        // 需要切换的幻灯片的索引，从 0 开始；或相应 el-carousel-item 的 name 属性值
        // i 与 name 里面的值绑定 ，i 决定显示那个 name值的 el-carousel-item
        (this.$refs.carousel as any).setActiveItem(i);
      }
    },
  },
  watch: {
    // 监听删除第二页之后请求上一页的操作
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getCompleteList();
      }
    },
  },
});


























































































import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      // 用来三元判断
      indexAddition: '0',
      // 区域代理默认打开用户管理
      index: '',
      //  区域代理选中之后0状态三角形的颜色为白色,1状态为黑色
      colour: 0,
      // 三角形黑色的变化
      balckTriangle: 0,
    };
  },
  mounted() {
    this.getIdentity();
  },
  methods: {
    // 区域代理
    getIdentity() {
      const correspondingPath = [
        '/regionalAgency',
        '/approval',
        '/pricing',
        '/commodity',
        '/product',
        '/productGreate',
        '/prohibitedWords',
        '/pictures',
        '/attribute',
        '/platform',
        '/modifyPlatform',
        '/commodityGreate',
        '/changePassword',
      ];
      const strategyIndex = [
        '1-1',
        '1-2',
        '2',
        '3',
        '4',
        '4',
        '5',
        '6',
        '7',
        '8',
        '8',
        '3',
        '-1',
      ];
      console.log(this.$route.path);
      correspondingPath.map((item, index) => {
        if (this.$route.path == item) {
          this.index = strategyIndex[index];
        }
      });
      this.currentIndex(this.index);
    },

    // 跳转事件
    getJump(index: number) {
      const route = [
        '/regionalAgency',
        '/approval',
        '/pricing',
        '/commodity',
        '/product',
        '/prohibitedWords',
        '/pictures',
        '/attribute',
        '/platform',
        '/modifyBgImg',
      ];
      this.$router.push({
        path: route[index],
      });
    },
    // 展开的事件
    handleOpen() {
      this.colour = 0;
    },
    // 收起的事件
    handleClose() {
      this.colour = 1;
    },
    // 当前点击的index
    currentIndex(index: any) {
      if (index == '1-1' || index == '1-2') {
        this.indexAddition = '0';
        this.index = index;
      } else {
        this.indexAddition = '1';
        this.index = index;
      }
    },
  },
  watch: {
    colour() {
      if (this.colour == 1 && this.indexAddition == '1') {
        this.balckTriangle = 1;
        console.log(this.balckTriangle);
      } else {
        this.balckTriangle = 0;
      }
    },
    // 监听路由的变化
    $route() {
      // console.log(to, from);
      this.getIdentity();
    },
  },
});
















































import Vue from 'vue';
import { getlibraryResultlistDetail } from '@/api';
export default Vue.extend({
  data() {
    return {
      id: '' as any, //当前选择的id
      dialogVisible: false,
      selectImg: '', //选的图片
      imgList: {
        image: [],
      }, //数据列表
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    // console.log(this.$route, 'this.$route');
    this.getlibraryResultlistDetail();
  },
  methods: {
    //获取id
    getlibraryResultlistDetail() {
      getlibraryResultlistDetail({ id: this.id }).then(res => {
        this.imgList = res.data;
        // console.log(res, 'resda1');
      });
    },
    getEnlarge(url: any) {
      this.selectImg = url;
      this.dialogVisible = true;
    },
  },
  watch: {},
});






































































































































































import Vue from 'vue';
import {
  posttemplateAdd,
  postTemlateDetail,
  postTemlateEdit,
  postTemlateSite,
} from '@/api';

export default Vue.extend({
  data() {
    return {
      options: [],
      id: '' as any, //编辑当前模板的id
      rules: {
        templateName: [
          { required: true, message: '请输入平台模板名称', trigger: 'blur' },
        ],
        site: [{ required: true, message: '请选择站点', trigger: 'blur' }],
        ch_name: [
          { required: true, message: '请输入类目中文名称', trigger: 'blur' },
        ],
        eng_name: [
          { required: true, message: '请输入类目英文名称', trigger: 'blur' },
        ],
        value: [
          { required: true, message: '请输入自定义分类', trigger: 'blur' },
        ],
      },
      //自定义分类
      classification: [{ value: '' }] as any,
      disabled: true,
      categoryForm: {
        // 类目中文名称
        ch_name: '',
        // 类目英文名称
        eng_name: '',
      },
      ruleForm: {
        // 平台模板名称
        templateName: '',
        // 站点
        site: '',
      },
      // 类目数组
      categoryList: [] as any,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.postTemlateDetail(this.id);
    }
    this.postTemlateSite();
  },
  methods: {
    // 站点列表
    async postTemlateSite() {
      const res: any = await postTemlateSite();
      if (res.code == 200) {
        // console.log(res.data);
        this.options = res.data;
      }
    },
    //获取平台模板详情
    postTemlateDetail(id: any) {
      postTemlateDetail({ id }).then((res: any) => {
        // const info =JSON.parse(res.data.nowInfo)
        this.categoryList = res.data.info;
        this.ruleForm.templateName = res.data.template_name;
        this.ruleForm.site = res.data.site;
        this.classification = res.data.info.map(() => {
          return { value: '' };
        });
        console.log(this.ruleForm, 'mobanxinxi', this.classification);
      });
    },
    // 添加类目分类
    getAddCategory(classification: any, index: any) {
      console.log(this.$refs[classification] as any);
      (this.$refs[classification] as any)[index].validate((valid: any) => {
        if (valid) {
          this.categoryList[index].sleect_list.push(
            this.classification[index].value
          );
          console.log(this.categoryList);
          this.classification[index].value = '';
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      // console.log(this.classification);
    },
    // 添加类目
    getCategory(categoryForm: any) {
      (this.$refs[categoryForm] as any).validate((valid: any) => {
        if (valid) {
          const categoryForm = {
            ch_name: this.categoryForm.ch_name,
            eng_name: this.categoryForm.eng_name,
            sleect_list: [],
          };
          this.categoryList.push(categoryForm);
          this.classification.push({ value: '' });
          this.categoryForm.ch_name = '';
          this.categoryForm.eng_name = '';
          console.log(this.categoryList);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //确定
    getPlatform(ruleForm: any) {
      // const nowInfo: any = [
      //   {
      //     ch_name: '产品类型',
      //     eng_name: 'feed_product_type',
      //     sleect_list: ['home', 'phone'],
      //   },
      //   { ch_name: '产品类目', eng_name: 'item_type', sleect_list: ['prints'] },
      // ];
      // const data = {
      //   site: '英国',
      //   template_name: '无框画',
      //   info: JSON.stringify(nowInfo).toString(),
      // };

      // console.log(data, 'data', nowInfo.toString());
      // posttemplateAdd(data).then(res => {
      //   console.log(res, 'res');
      // });

      (this.$refs[ruleForm] as any).validate((valid: any) => {
        if (valid) {
          console.log(this.ruleForm, this.categoryList);
          if (!this.categoryList.length) {
            this.$message({
              message: '请填写类目信息',
              type: 'warning',
            });
          } else if (!this.categoryList[0].sleect_list.length) {
            this.$message({
              message: '请填写分类',
              type: 'warning',
            });
          } else {
            let data: any = new Object();
            data = {
              site: this.ruleForm.site,
              template_name: this.ruleForm.templateName,
              info: JSON.stringify(this.categoryList).toString(),
            };
            if (this.id.length > 0) {
              data.id = this.id;
              console.log(data, 'shujudada');
              postTemlateEdit(data).then(() => {
                this.$message({
                  message: '编辑成功',
                  type: 'success',
                });
                this.$router.go(-1);
              });
            } else {
              posttemplateAdd(data).then(() => {
                this.$message({
                  message: '添加成功',
                  type: 'success',
                });
                this.$router.go(-1);
              });
            }
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 删除类目
    getDeleteCategory(index: number) {
      this.categoryList.splice(index, 1);
      this.classification.splice(index, 1);
    },
    // 删除类目分类
    getDelFication(index: number, index1: number) {
      console.log(index1);
      this.categoryList[index].sleect_list.splice(index1, 1);
    },
  },
  watch: {
    // classification() {
    //   if (this.classification != '') {
    //     this.disabled = false;
    //   } else {
    //     this.disabled = true;
    //   }
    // },
    // classification: {
    //   handler: function(newVal) {
    //     console.log(newVal);
    //   },
    //   deep: true,
    // },
  },
});




























































import Vue from 'vue';
import { getCode, postUserLogin } from '@/api';
export default Vue.extend({
  data() {
    const checkCode = (rule: any, value: any, callback: any) => {
      const that = this as any;
      if (!value) {
        return callback(new Error('请输入验证码'));
      }
      if (that.rulesForm.verificationCode == that.codeNum) {
        callback();
      } else {
        return callback(new Error('请重新输入正确的验证码'));
      }
    };
    return {
      // 提示账号密码错误
      show: false,
      rulesForm: {
        // 验证码
        verificationCode: '',
        // 账号
        accountNumber: '',
        //  密码
        password: '',
      },
      //  验证码
      codeNum: '',
      formVerification: {
        accountNumber: [
          { required: true, message: '请输入账号名', trigger: 'change' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' },
        ],
        verificationCode: [
          {
            required: true,
            validator: checkCode,
            trigger: 'change',
          },
          {
            min: 4,
            max: 4,
            message: '验证码长度至多为4位数',
            trigger: 'change',
          },
        ],
      },
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    // 表单的结果
    submitForm(formName: any) {
      (this.$refs[formName] as any).validate((valid: any) => {
        if (valid) {
          // console.log(this.rulesForm);
          this.postUserLogin();
        } else {
          console.log('error submit!!');
          //  去请求登录接口然后判断是否正确，不正确就提示true
          return false;
        }
      });
    },
    postUserLogin() {
      const data = {
        code: this.rulesForm.verificationCode,
        account: this.rulesForm.accountNumber,
        password: this.rulesForm.password,
      };
      // const res: any = await postUserLogin(data).;
      postUserLogin(data)
        .then(res => {
          const stateSidebarPath = [
            '/regionalAgency',
            '/management',
            '/materialLibrary',
          ];
          let userInfo = res.data.userinfo;
          // 判断是那哪个路由
          this.$router.push({
            path: stateSidebarPath[Number(userInfo.role) - 1],
          });
          this.$message({
            duration: 1500,
            message: '登录成功',
            type: 'success',
          });
          this.$router.go(0);
          // 把用户信息储存在缓存
          userInfo = JSON.stringify(userInfo);
          localStorage.setItem('userInfo', userInfo);
        })
        .catch(err => {
          console.log(err);
          this.getCode();
        });
      // if (res.code == 200) {
      //   // 三个身份的默认路由
      //   const stateSidebarPath = [
      //     '/regionalAgency',
      //     '/management',
      //     '/materialLibrary',
      //   ];
      //   let userInfo = res.data.userinfo;
      //   // 判断是那哪个路由
      //   this.$router.push({
      //     path: stateSidebarPath[Number(userInfo.role) - 1],
      //   });
      //   this.$message({
      //     duration: 1500,
      //     message: '登录成功',
      //     type: 'success',
      //   });
      //   this.$router.go(0);
      //   // 把用户信息储存在缓存
      //   userInfo = JSON.stringify(userInfo);
      //   localStorage.setItem('userInfo', userInfo);
      // } else {
      //   this.getCode();
      // }
    },
    // 请求验证码
    async getCode() {
      const res: any = await getCode();
      if (res.code == 200) {
        this.codeNum = res.data;
      }
    },
    // 换一张
    getCodeNum() {
      this.getCode();
    },
  },
  watch: {
    show() {
      if (this.show == true) {
        setTimeout(() => {
          this.show = false;
        }, 2500);
      }
    },
  },
});

import axios from 'axios';
import router from './../router';

import { Message } from 'element-ui';

// 环境的切换
// axios.defaults.timeout = 8000; //超过多少时间请求超时

console.log(location.protocol, 'process');
if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = '';
} else if (process.env.NODE_ENV == 'debug') {
  axios.defaults.baseURL = '';
} else if (process.env.NODE_ENV == 'production') {
  axios.defaults.baseURL = '';
}
const userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
// axios.defaults.baseURL = '/proxy_api';
const instance = axios.create({
  baseURL: 'http://amazon.wsandos.com',
  // baseURL: "/proxy_api", //本地使用
  // withCredentials: false, // 表示跨域请求时是否需要使用凭证
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept',
    token: userInfo ? userInfo.token : '',
    // Authorization: uni.getStorageSync('userInfo').token || '',
  },
});
// Add a request interceptor 请求拦截器
instance.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (
      config.url == '/api/upload/upload' ||
      config.url == '/api/material/upload'
    ) {
      config.headers = {
        'Content-Type': 'multipart/form-data',
        token: userInfo ? userInfo.token : '',
      };
    }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);
//数据返回拦截
instance.interceptors.response.use(
  (response: any) => {
    // Do something with response data
    return response.data;
  },
  error => {
    console.log(error);
    // Do something with response error
    // 处理错误
    // 若token过期，重新跳转到登录页面
    // 状态码判断
    switch (error.response.status) {
      // 401: 未登录状态，跳转登录页
      case 401:
        localStorage.removeItem('userInfo');
        Message.error(error.response.data.msg);
        router.push('/sign');
        break;
      // 403 token过期
      // 清除token并跳转登录页
      case 403:
        // Message.error('登录过期，请重新登录');
        localStorage.removeItem('userInfo');
        Message.info('登录生效请重新登录');
        router.push('/sign');
        // setTimeout(() => {
        //     toLogin();
        // }, 1000);
        break;

      // 404请求不存在
      case 404:
        // Message.error('请求的资源不存在');
        break;
      // 422操作问题
      case 422:
        Message.error(error.response.data.msg);
        break;
      case 400:
        Message.error(error.response.data.msg);
        break;
      case 500:
        Message.error(error);
        return error;
        break;
      default:
    }
    // error.response.data
    return Promise.reject(error);
  }
);

export default instance;

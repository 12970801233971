















































































































































import Vue from 'vue';
import { getGeneralList, getdeleteUser } from '@/api';
export default Vue.extend({
  data() {
    // 判断是否存在改用户名
    // const checkUsername = (rule: any, value: any, callback: any) => {
    //   // if (value != "") {
    //   // 请求接口
    //   //   if (this.usernameRules()) {
    //   //     callback(new Error("用户名已存在"));
    //   //   }
    //   //   callback();
    //   // }
    // };
    // 判断手机号码
    const checkPhone = (rule: any, value: any, callback: any) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
        // console.log(reg.test(value));
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      expireTimeOption: {
        disabledDate(date: any) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      // 账号
      accountNumber: '',
      // 用户名称
      userName: '',
      // 手机号码
      phoneNumber: '',
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 0,
      // 选中的数据，用于批量删除
      selectDate: [],
      // 新增用户
      form: {
        // 账号名
        accountName: '',
        // 用户名
        userName: '',
        // 手机号码
        phone: '',
        // 密码
        password: '',
        // 到期时间
        expireTime: '',
      },
      // 新增用户的验证
      rules: {
        accountName: [
          { required: true, message: '请输入账号名', trigger: 'blur' },
          //  { validator: checkUsername, trigger: "blur" }
        ],
        userName: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
          { validator: checkPhone, trigger: 'change' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' },
          {
            min: 8,
            max: 24,
            message: '密码长度在 8 到 24 个字符',
            trigger: 'change',
          },
        ],
        expireTime: [
          { required: true, message: '请输入到期时间', trigger: 'blur' },
        ],
      },
      // 弹窗的显示与隐藏
      // dialogFormVisible: false,
      page: 1,
    };
  },
  mounted() {
    this.getGeneralList();
  },
  methods: {
    // 重置按钮
    getReset() {
      this.accountNumber = '';
      this.userName = '';
      this.phoneNumber = '';
    },
    // 请求用户列表
    async getGeneralList() {
      const filter = {
        username: this.accountNumber,
        real_name: this.userName,
        mobile: this.phoneNumber,
      };
      const page = this.page;
      const limit = '10';
      const res: any = await getGeneralList(
        JSON.stringify(filter),
        limit,
        page
      );
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },
    // 搜索框
    getLookup() {
      this.page = 1;
      this.getGeneralList();
    },
    // 选中
    handleSelectionChange(val: any) {
      // console.log(5241, val);
      this.selectDate = val;
    },
    // 删除按钮
    async handleDelete(id: any) {
      const res: any = await getdeleteUser(id);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getGeneralList();
      }
    },
    // 清空选择
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getGeneralList();
      // console.log(466, val);
    },
    // 批量删除
    async getBatchDelete() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      const res: any = await getdeleteUser(id.toString());
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getGeneralList();
      }
    },
    // 批量删除按钮
    handleClose(row: any) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id);
          })
          .catch(() => {
            console.log('取消删除');
          });
      } else if (this.selectDate.length) {
        this.$confirm('确认批量删除？')
          .then(() => {
            this.getBatchDelete();
          })
          .catch(() => {
            console.log('取消批量删除');
          });
      }
    },
    // //弹起添加用户
    // getBounce() {
    //   this.dialogFormVisible = true;
    // },
    //添加用户按钮
    // getDialogFormVisible(form: any) {
    //   (this.$refs[form] as any).validate((valid: any) => {
    //     if (valid) {
    //       // console.log(this.form);
    //       this.getAddUser();
    //     } else {
    //       console.log('表单为空');
    //       return false;
    //     }
    //   });
    // },
    // 添加用户接口
    // async getAddUser() {
    //   const data = {
    //     username: this.form.accountName,
    //     real_name: this.form.userName,
    //     mobile: this.form.phone,
    //     password: this.form.password,
    //     role: '3',
    //     apply_time: Number(this.form.expireTime) / 1000,
    //   };
    //   const res: any = await getAddUser(data);
    //   if (res.code == 200) {
    //     // this.dialogFormVisible = false;
    //     this.page = 1;
    //     this.getGeneralList();
    //   }
    // },
    // 每次打开对话框删除form表单
    getOpen(formName: any) {
      this.$nextTick(() => {
        (this.$refs[formName] as any).resetFields();
      });
    },
    // 取消按钮
    // getCancel() {
    //   this.dialogFormVisible = false;
    // },
  },
  watch: {
    // 监听删除第二页之后请求上一页的操作
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getGeneralList();
      }
    },
  },
  filters: {
    formatTime(time: number) {
      if (time) {
        const date = new Date(time * 1000);
        const Y = date.getFullYear();
        let M = (date.getMonth() + 1).toString();
        let D = date.getDate().toString();
        if (Number(M) < 10) {
          M = '0' + M;
        }
        if (Number(D) < 10) {
          D = '0' + D;
        }
        return Y + '.' + M + '.' + D;
        // if (str == '-') {
        //   return Y + '年' + M + '月' + D + '日';
        // } else {
        //   return Y + '.' + M + '.' + D;
        // }
      }
    },
  },
});

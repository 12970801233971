

















































































































































































































































































































































import Vue from 'vue';

//canvas 绘图
import { fabric } from 'fabric';
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { Loading } from 'element-ui';
import {
  getSourceMaterialList,
  postQiniuUploadBase64,
  getAllTemplate,
  postAllTemplate,
} from '@/api';
export default Vue.extend({
  data() {
    return {
      //所有图片模板列表
      allTemplate: [] as any,
      //选中图片的路径
      slelectUrl: '' as any,
      selectFbx: {} as any, //选中的模板数组
      templateIndex: 0, //选中模板序号
      arrayIndex: 0, //对应有无框序号
      listIndex: 0, //对应图片或者模板的序号

      // 查看图片的显示与隐藏
      dialogImg: false,
      selectFrameIndex: 'unFrame', //选中的设计规格
      createtime: '', //时间节点筛选

      // 素材总数
      total: 0,
      // 判断当前是否选中
      current: true,
      page: 1,
      // 素材库数据
      MaterialList: [] as any,
      // 选中的素材
      materialImgList: [] as any,
      indexList: [] as any,

      //fabric和canvas

      targetImg: '' as any, //canvas中的目标函数
      singlecreatetime: '', //单个设计的时间选择
      currentPage4: 1, //素材第多少页
      filterParams: {
        //单模板选择素材
        createtime: '',
        type: '',
        name: '', //搜索的名字
        order: 'desc',
      } as any, //素材图片筛选
      upLoading: false, //图片上传
      selectBgImgIndex: -1, //当前选中的图片
      selectBgImgCont: { Image: '', name: '' } as any, //当前文件的名字
      photoList: {} as any, //素材库列表
      activeIndex2: '1', //导航下拉
      canvas: {} as any, //fabric使用的canvas
      canvas1: '' as any,
      context: null as any,
      canvasUrl: '', //绘制好的图片

      dialogVisible: false, //弹窗遮罩
      fabricImage: null as any, //添加图片事件
      operatingIndex: 1, //1为设计弹窗 2为列表弹窗

      config: {
        canvasState: [] as any,
        currentStateIndex: -1, //可撤回多少步骤
        redoStatus: false, //正在撤销状态
        undoStatus: false, // 正在重做状态
        undoFinishedStatus: 1,
        redoFinishedStatus: 1,
        undoButton: this.$refs.undo, //得不到  在 mounted 得到
        redoButton: this.$refs.redo,
        undeoClick: false, //是否可点击
        redoClick: true, //是否可操作取消撤回 true为无可撤销撤回操作
      },

      //fbx绘制模板图片
      slectPopIndex: 0, //弹窗选中的序号
      loadingInstance: null as any, //加载中弹窗遮罩
      imgPaste: false, //判断图片是否有贴入
      //canvas绘图参数
      getImageData: true, //可以生产图片
      // container: null as any, //画布
      camera: null as any, //相机视角
      scene: null as any, //创建this.scene
      renderer: null as any, //结构渲染器
      controls: null as any, //自由视角控制
      contTranslate: [-50, 25, 0], //向 x y z 轴平移的距离
      // geometry: null as any,//几何对象
      newImg: { unFrame: [], frame: [] } as any, //单个设计图片数组

      haveFarmImg: false, //是否展示框架图片
      lingtParam: {
        leftDown: {
          //向左向下阴影
          targetPosition: [0, 0, -100],
          position: [900, 20, -300], //前后 上下 左右
          far: 1000,
          near: 0.8,
          height: 2040, //上下
          width: 280, //左右
          left: -300,
          right: 600,
          top: 600,
          bottom: -300,
        },
        rightDown: {
          //向右向下阴影
          targetPosition: [0, 0, -100], //目标位置
          position: [900, 100, 20], //前后 上下 左右 坐标翻转定位翻转角度
          far: 1000,
          near: 200,
          height: 2010, //上下
          width: 380, //左右
          left: -200,
          right: 500,
          top: 600,
          bottom: -300,
        },
      } as any, //平行灯光设置
      c2List: {} as any,
    };
  },
  mounted() {
    //处理执行模板
    // this.animate();
    //执行循环canvas
    this.getAllTemplate();
    this.initRender();

    // this.postMaterialList(); //获取单个设计的素材列表
  },
  methods: {
    //获取所有图片 模板列表
    async getAllTemplate() {
      const res: any = await getAllTemplate();
      if (res.code == 200) {
        this.allTemplate = res.data;
      }
    },
    // 查看大图
    getLookImg(index: any, arrayIndex: any, listIndex: any) {
      const framName = arrayIndex == 1 ? 'unFrame' : 'frame';
      this.slelectUrl = this.allTemplate[index].cover[framName][listIndex];
      this.selectFbx = this.allTemplate[index].params[framName][listIndex];
      this.dialogImg = true;
    },
    // 选中当前的素材
    getSelect(index: any) {
      if (
        this.materialImgList.length < 10 ||
        this.MaterialList[index].current
      ) {
        const newList = {
          url: this.MaterialList[index].image,
          name: this.MaterialList[index].title,
        };
        const iscontain = JSON.stringify(this.materialImgList).indexOf(
          JSON.stringify(newList)
        );
        if (this.MaterialList[index].current == false && iscontain == -1) {
          this.MaterialList[index].current = true;
          const nowChooseImg: any = {
            url: this.MaterialList[index].image,
            name: this.MaterialList[index].title,
          };
          this.materialImgList.push(nowChooseImg);
        } else if (iscontain != -1) {
          const lookupId = this.materialImgList.findIndex((item: any) => {
            return item.url == this.MaterialList[index].image;
          });
          this.materialImgList.splice(lookupId, 1);
          this.MaterialList[index].current = false;
        }
      } else {
        this.$message('至多选择10个图片');
      }
    },

    //canvas绘图

    /**
     * 创建渲染器对象
     */
    initRender() {
      this.renderer = new THREE.WebGLRenderer({
        //不支持线的宽度 CanvasRenderer才支持线的宽度
        antialias: true,
        alpha: true,
        // precision: "highp"
      });
      this.renderer.shadowMap.enabled = true; //开启阴影，加上阴影渲染
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; //定义阴影贴图类型
    },
    /**
     * 创建场景对象this.scene
     */
    initScene() {
      this.scene = new THREE.Scene();
    },

    /**
     * 相机设置
     */
    initCamera() {
      this.camera = new THREE.OrthographicCamera(0, 500, 500, 0, 1, 3000); //x 图片x 图片y y (left right top botom near far)
      this.camera.position.set(400, 0, 0); //设置相机位置 x y z
      this.camera.lookAt(this.scene.position); //设置相机方向(指向的场景对象) 默认为坐标原点（x:0，y:0，z：0）
    },
    /**
     * 光源设置
     */
    initLight(direction: any) {
      //环境光
      /*Lights*/
      const boardParam: any = this.lingtParam[direction];
      const [positionx, positiony, positionz] = boardParam.position;
      const [targetX, targetY, targetZ] = boardParam.targetPosition;
      const ambient = new THREE.AmbientLight(0x444444);
      // ambient.castShadow = true;
      this.scene.add(ambient);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8); //平行光
      directionalLight.position.set(positionx, positiony, positionz);

      directionalLight.castShadow = true;
      directionalLight.receiveShadow = true;
      this.scene.add(directionalLight);
      directionalLight.shadow.camera.far = boardParam.far; //产生阴影的最远距离
      directionalLight.shadow.camera.left = boardParam.left; //产生阴影距离位置的最左边位置
      directionalLight.shadow.camera.right = boardParam.right; //最右边
      directionalLight.shadow.camera.top = boardParam.top; //最上边
      directionalLight.shadow.camera.bottom = boardParam.bottom; //最下面
      //这两个值决定使用多少像素生成阴影 默认512
      directionalLight.shadow.mapSize.height = boardParam.height;
      directionalLight.shadow.mapSize.width = boardParam.width;

      directionalLight.shadow.camera.near = boardParam.near; //远近
      const targetObject = new THREE.Object3D(); //灯光目标点
      targetObject.position.set(targetX, targetY, targetZ);
      this.scene.add(targetObject);
      directionalLight.target = targetObject;
    },

    async generateImg() {
      //单设计生成图片
      // this.container = document.getElementById('container');
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '拼命处理中',
      });

      this.scene = null;
      this.camera = null;
      // THREE.Cache.clear();
      this.initScene();
      this.initCamera();
      this.initLight(this.selectFbx.direction);
      this.initModel(this.selectFbx, this.canvasUrl);
      const nowImg = await this.animate();
      this.slelectUrl = nowImg;

      this.imgPaste = true;
      this.loadingInstance.close();
    },
    //创建立体对象
    initModel(boardParam: any, canvasUrl: any) {
      // TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load(canvasUrl);
      const material = new THREE.MeshLambertMaterial({
        visible: true, // ：是否可见，默认为 true
        map: texture,
      });

      this.renderer.setSize(800, 800); //像素设置
      const fbxLoader = new FBXLoader();
      fbxLoader.load(boardParam.fbx, object => {
        object.scale.set(0.5, 0.5, 0.5); //xyz缩放
        object.castShadow = boardParam.haveShadow; //开启灯光投射阴影
        object.receiveShadow = boardParam.haveShadow; //开启接受阴影
        object.traverse((child: any) => {
          if (child.name == '灯光_2') {
            //背景墙
            child.visible = false; //开启灯光投射阴影/
          }
          if (child.name == '平面') {
            child.castShadow = boardParam.haveShadow; //开启灯光投射阴影
            child.receiveShadow = boardParam.haveShadow; //开启接受阴影
            child.material = material;
            if (child.children[0].name == '立方体') {
              //背景墙
              child.children[0].castShadow = boardParam.haveShadow; //开启灯光投射阴影
              child.children[0].receiveShadow = boardParam.haveShadow; //开启接受阴影
            }
          }
        });

        this.scene.add(object);
      });
    },

    //3d模型处理完之后输出图片
    animate() {
      return new Promise(resolve => {
        setTimeout(() => {
          this.renderer.clear(); //清除缓存区

          this.renderer.clearDepth(); //清除深度缓存区
          this.renderer.render(this.scene, this.camera);
          this.scene.remove(this.scene.children);
          // for (let i = this.scene.children.length - 1; i >= 0; i--) {
          //   if (this.scene.children[i].type === "Mesh")
          //     this.scene.remove(this.scene.children);
          // }
          // if (this.getImageData == true) {
          resolve(this.renderer.domElement.toDataURL('image/png', 1.0));
        }, 500);
      });
    },
    //fabric 编辑图片

    //单素材库设计
    postMaterialList() {
      const filte = {
        title: this.filterParams.name,
        type: this.filterParams.type,
        createtime: this.singlecreatetime,
      };
      const data = {
        limit: 16,
        page: this.currentPage4,
        order: this.filterParams.order,
        filter: JSON.stringify(filte),
        op: '{"title":"LIKE","type":"=","createtime":"BETWEEN"}',
        sort: 'createtime',
      };
      getSourceMaterialList(data).then(res => {
        this.photoList = res.data;
      });
    },
    //保存单个设计
    async singleSave() {
      if (!this.imgPaste) {
        this.$message({
          message: '请先合成图片',
          type: 'warning',
        });
        return;
      }

      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '图片提交中...',
      });
      const slelectTemplate = this.allTemplate[this.templateIndex];

      const framName = this.arrayIndex == 1 ? 'unFrame' : 'frame';
      const newFbxImg: any = await this.postUploadBase64(
        this.slelectUrl.split('base64,')[1]
      );
      const newBgImg: any = await this.postUploadBase64(
        this.canvasUrl.split('base64,')[1]
      );
      slelectTemplate.cover[framName][this.listIndex] = newFbxImg.url;
      slelectTemplate.params[framName][this.listIndex].bgImg = newBgImg.url;

      const params = {
        id: slelectTemplate.id,
        cover: JSON.stringify(slelectTemplate.cover),
        params: JSON.stringify(slelectTemplate.params),
      };
      console.log(slelectTemplate, 'slelectTemplate');
      const res: any = await postAllTemplate(params);
      if (res.code == 200) {
        this.$message({
          message: '修改成功',
          type: 'success',
        });
        this.allTemplate[this.templateIndex] = slelectTemplate;
        this.dialogVisible = false;
        this.loadingInstance.close();
      }
    },

    //上传base4的图片接口
    postUploadBase64(file: any) {
      return new Promise((resolve, reject) => {
        postQiniuUploadBase64({ file })
          .then((res: any) => {
            resolve(res.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },
    //大图canvas展示
    bigCanvasPop() {
      this.dialogImg = true;
    },
    //返回绘图操作
    backCanvasPop() {
      this.operatingIndex = 1;
    },
    chooseSingleTime(val: any) {
      this.singlecreatetime = `${val[0] / 1000},${val[1] / 1000}`;

      this.currentPage4 = 1;
      this.postMaterialList();
    },
    //单图设计素材分页切换的时候
    singleCurrentChange(val: any) {
      this.currentPage4 = val;
      this.postMaterialList();
    },
    //下拉选择
    handleSelect(key: any, keyPath: any) {
      if (keyPath[0] == 1) {
        return;
      }
      if (keyPath[0] == 3) {
        this.filterParams = {
          createtime: '',
          type: '',
          name: '', //搜索的名字
          order: 'desc',
        };
        this.createtime = '';
      } else {
        this.filterParams[`${keyPath[0]}`] = key;
      }
      this.currentPage4 = 1;
      this.postMaterialList();
    },
    //素材输入搜索的值
    searchInput() {
      if (this.filterParams.name.length > 0) {
        this.currentPage4 = 1;
        this.postMaterialList();
      }
    },
    //文件上传时提示
    onUploadImg() {
      this.upLoading = true;
    },
    onUpLoadErr() {
      this.upLoading = false;
    },
    //上传图片到七牛云
    uploadImg(response: any, file: any) {
      this.selectBgImgCont.image = response.data.url;
      this.selectBgImgCont.title = file.name;
      this.upLoading = false;
      this.addBgImg();
    },

    // 设置按钮
    //index第几个 ,arrayIndex 有无框 ,listIndex 第几个
    handleDelete(index: any, arrayIndex: any, listIndex: any) {
      const framName = arrayIndex == 1 ? 'unFrame' : 'frame';
      this.templateIndex = index;
      this.arrayIndex = arrayIndex;
      this.listIndex = listIndex;
      this.slelectUrl = this.allTemplate[index].cover[framName][listIndex];
      this.selectFbx = this.allTemplate[index].params[framName][listIndex];
      this.imgPaste = false;
      this.dialogVisible = true;
      // this.newImg = row.image;
      setTimeout(() => {
        this.addFavric();
        this.canvasDataChange(); //监听canvas 事件
        // this.addBgImg();
      }, 200);
    },

    //关闭绘图弹窗
    colsPop() {
      this.dialogVisible = false;
    },

    //选择对应的图片
    slectBgImg(index: any) {
      this.selectBgImgIndex = index;
      this.selectBgImgCont = this.photoList.rows[index];
      this.addBgImg();
    },

    //创建fabric
    addFavric() {
      this.canvas = new fabric.Canvas('canvas');
      this.canvas.renderAll();
    },
    // 记录事件
    canvasDataChange() {
      this.canvas.on('object:modified', () => {
        this.updateCanvasState();
      });
      this.canvas.on('object:added', () => {
        this.updateCanvasState();
      });
      this.canvas.on('object:removed', () => {
        this.updateCanvasState();
      });
      this.canvas.on('object:rotating', () => {
        this.updateCanvasState();
      });

      this.canvas.on('selection:updated', (e: any) => {
        this.onObjectSelected(e);
      });
      this.canvas.on('selection:created', (e: any) => {
        this.onObjectSelected(e);
      });
      this.canvas.on('selection:cleared', () => {
        this.targetImg = '';
      });
      // this.canvas.renderAll();
    },

    fillImg() {
      //图片自适应填充
      const newObject: any = this.targetImg;
      if (newObject == '') {
        this.$message({
          message: '请先选中图层',
          type: 'warning',
        });
        return;
      }

      const scaleFactor = Math.max(
        Math.min(600 / newObject.width),
        Math.min(600 / newObject.height)
      );
      newObject.scale(scaleFactor);
      (newObject.left = -(newObject.width * scaleFactor - 600) / 2), //剧中计算
        (newObject.top = -(newObject.height * scaleFactor - 600) / 2), //剧中计算,
        this.canvas.renderAll();
    },
    onObjectSelected(e: any) {
      //选中目标改变
      this.targetImg = e.target;
    },
    // 产生farbic的历史操作记录
    updateCanvasState() {
      if (this.config.undoStatus == false && this.config.redoStatus == false) {
        const jsonData = this.canvas.toJSON();
        const canvasAsJson = JSON.stringify(jsonData);
        if (
          this.config.currentStateIndex <
          this.config.canvasState.length - 1
        ) {
          const indexToBeInserted = this.config.currentStateIndex + 1;
          this.config.canvasState[indexToBeInserted] = canvasAsJson;
          const numberOfElementsToRetain = indexToBeInserted + 1;
          this.config.canvasState = this.config.canvasState.splice(
            0,
            numberOfElementsToRetain
          );
        } else {
          this.config.canvasState.push(canvasAsJson);
        }
        this.config.currentStateIndex = this.config.canvasState.length - 1;
      }
    },

    redo() {
      //取消准备操作
      if (this.config.redoClick) {
        return;
      }
      if (this.config.redoFinishedStatus) {
        if (
          this.config.currentStateIndex == this.config.canvasState.length - 1 &&
          this.config.currentStateIndex != -1
        ) {
          // this.config.redoButton.disabled= true;
          this.config.redoClick = true;
        } else {
          if (
            this.config.canvasState.length > this.config.currentStateIndex &&
            this.config.canvasState.length != 0
          ) {
            this.config.redoFinishedStatus = 0;
            this.config.redoStatus = true;
            this.canvas.loadFromJSON(
              this.config.canvasState[this.config.currentStateIndex + 1],
              () => {
                // const jsonData = JSON.parse(
                //   this.config.canvasState[this.config.currentStateIndex + 1]
                // );
                this.canvas.renderAll();
                this.config.redoStatus = false;
                this.config.currentStateIndex += 1;
                if (this.config.currentStateIndex != -1) {
                  //    this.config.redoButton.disabled = false;

                  this.config.redoClick = false;
                }
                this.config.redoFinishedStatus = 1;
                if (
                  this.config.currentStateIndex ==
                    this.config.canvasState.length - 1 &&
                  this.config.currentStateIndex != -1
                ) {
                  // this.config.redoButton.disabled= true;

                  this.config.redoClick = true;
                }
              }
            );
          }
        }
      }
    },
    undo() {
      //撤消操作事件
      if (this.config.currentStateIndex == -1) {
        return;
      }
      if (this.config.undoFinishedStatus) {
        if (this.config.currentStateIndex == -1) {
          this.config.undoStatus = false;
        } else {
          if (this.config.canvasState.length >= 1) {
            this.config.undoFinishedStatus = 0;
            if (this.config.currentStateIndex != 0) {
              this.config.undoStatus = true;
              this.canvas.loadFromJSON(
                this.config.canvasState[this.config.currentStateIndex - 1],
                () => {
                  // const jsonData = JSON.parse(
                  //   this.config.canvasState[this.config.currentStateIndex - 1]
                  // );
                  this.canvas.renderAll();
                  this.config.undoStatus = false;
                  this.config.currentStateIndex -= 1;
                  // this.config.undoButton.removeAttribute("disabled");
                  // this.config.undoButton.disabled = false;

                  this.config.undeoClick = false;
                  if (
                    this.config.currentStateIndex !==
                    this.config.canvasState.length - 1
                  ) {
                    // this.config.redoButton.removeAttribute('disabled');
                    // this.config.redoButton.disabled = false;

                    this.config.redoClick = false;
                  }
                  this.config.undoFinishedStatus = 1;
                }
              );
            } else if (this.config.currentStateIndex == 0) {
              this.canvas.clear();
              this.config.undoFinishedStatus = 1;
              // this.config.undoButton.disabled= "disabled";
              // this.config.redoButton.removeAttribute('disabled');
              // this.config.redoButton.disabled = false;

              this.config.redoClick = false;
              this.config.currentStateIndex -= 1;
            }
          }
        }
      }
    },
    //单设计剪裁图片
    cutImg() {
      //1为横屏 2为竖屏，3 为全凭截图
      // 把组合设置为选中
      if (this.fabricImage) {
        this.canvas.setActiveObject(this.fabricImage);
      } else {
        this.$message({
          message: '请先选择一张需要合成的图片',
          type: 'warning',
        });
        return;
      }
      // // // 把拆分开的每一个模块进行取消选中状态
      this.canvas.discardActiveObject();
      this.canvas.renderAll();
      const canvas: any = document.getElementById('canvas');
      this.canvasUrl = canvas.toDataURL('image/png');
      this.haveFarmImg = true; //开始填入框架图片
      this.generateImg();

      // clipCtx.drawImage(this.canvas, 500, 500, 500, 500);
    },
    //删除图片
    deletBgImg() {
      this.canvas.remove(this.canvas.getActiveObject()); //删除当前图层图片
    },
    //添加背景图片
    addBgImg() {
      const image = new Image();
      const url = this.selectBgImgCont.image;
      // const url =
      //   'https://yzt.s3.ap-southeast-1.amazonaws.com/uploads/20210302/fbc56e083f470de60944aaeb78cb93a0.jpg';

      const newurl =
        url.indexOf('https://qie-online-sale-qiniu.wsandos.com') != -1
          ? 'https://qie-online-sale-qiniu.wsandos.com'
          : 'https://yzt.s3.ap-southeast-1.amazonaws.com';
      const reg = new RegExp(newurl);
      const strImg = url.replace(reg, 'http://amazon.wsandos.com');

      image.src = strImg; // this.getBase64Image(this.selectBgImgCont.image);
      image.crossOrigin = 'Anonymous';
      image.onload = () => {
        //首先获取image的属性
        const scaleFactor = Math.min(
          Math.min(600 / image.width),
          Math.min(600 / image.height)
        );
        this.fabricImage = new fabric.Image(image, {
          left: -(image.width * scaleFactor - 600) / 2, //剧中计算
          top: -(image.height * scaleFactor - 600) / 2, //剧中计算,
          width: image.width,
          height: image.height,
          scaleX: scaleFactor,
          //取短边展示
          scaleY: scaleFactor,
        });

        this.fabricImage.set({
          //可设置边框的属性
          transparentCorners: false, //
          cornerColor: 'blue',
          cornerStrokeColor: 'red',
          borderColor: 'red',
          // cornerSize: 12,
          // padding: 10,
          cornerStyle: 'triangle',
          borderDashArray: [3, 3],
        });

        this.canvas.add(this.fabricImage);
        this.canvas.setActiveObject(this.fabricImage);

        // 重新渲染
        this.canvas.renderAll();
      };
      // this.canvas.renderAll();
    },
  },
});

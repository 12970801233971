























































































































































import Vue from 'vue';
import {
  getIllegalPictures,
  getDeletePicture,
  getEditPicture,
  getprohibitedImage,
} from '@/api';
export default Vue.extend({
  data() {
    return {
      // 违禁词
      contrabandWord: '',
      // 创建人
      founder: '',
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 50,
      // 选中的数据，用于批量删除
      selectDate: [],
      // 新增用户
      form: {
        // 违禁图片名称：
        accountName: '',
        // 图片路径
        imageUrl: '',
      },
      // 新增用户的验证
      rules: {
        accountName: [
          { required: true, message: '请输入违规图片名称：', trigger: 'blur' },
        ],
        imageUrl: [{ required: true, message: '请上传图片', trigger: 'blur' }],
      },
      // 弹窗的显示与隐藏
      dialogFormVisible: false,
      // 标题
      title: '新增违规图片',
      userInfo: {} as any,
      page: 1,
      // 点击编辑拿到的id
      id: '',
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
    this.getprohibitedImage();
  },
  methods: {
    // 违禁图片列表
    async getprohibitedImage() {
      const search = this.contrabandWord;
      const filter = { 'user.real_name': this.founder };
      const limit = '10';
      const page = this.page;
      const res: any = await getprohibitedImage(
        search,
        JSON.stringify(filter),
        limit,
        page
      );
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },
    // 重置按钮
    getReset() {
      this.contrabandWord = '';
      this.founder = '';
    },
    // 禁止选中
    checkSelectable(row: any) {
      // 判断是什么身份  1是超管，2区域代理 3用户
      if (this.userInfo.role == '2') {
        if (row.role == '1') {
          return false;
        } else {
          return true;
        }
      } else if (this.userInfo.role == '3') {
        if (row.role != '3') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 搜索框
    getLookup() {
      // console.log(this.contrabandWord, this.founder);
      this.page = 1;
      this.getprohibitedImage();
    },
    // 选中
    handleSelectionChange(val: any) {
      // console.log(5241, val);
      this.selectDate = val;
    },
    // 删除按钮
    async handleDelete(id: any) {
      // console.log('删除单个', row);
      const res: any = await getDeletePicture(id);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getprohibitedImage();
      }
    },
    // 批量删除
    async getBatchDelete() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      const res: any = await getDeletePicture(id.toString());
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getprohibitedImage();
      }
    },
    // 清空选择
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      console.log(466, val);
      this.page = val;
      this.getprohibitedImage();
    },
    // 删除按钮
    handleClose(row: any) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id);
          })
          .catch(() => {
            console.log('取消删除');
          });
      } else if (this.selectDate.length) {
        this.$confirm('确认批量删除？')
          .then(() => {
            this.getBatchDelete();
          })
          .catch(() => {
            console.log('取消批量删除');
          });
      }
    },
    //弹起新增
    getBounce() {
      this.title = '新增违禁图片';
      (this.form.accountName = ''), (this.form.imageUrl = '');
      this.dialogFormVisible = true;
    },
    //添加违禁词按钮
    getDialogFormVisible(form: any) {
      (this.$refs[form] as any).validate((valid: any) => {
        if (valid) {
          // console.log(this.form);
          if (this.title == '新增违禁图片') {
            // 新增
            this.getIllegalPictures();
          } else {
            // 编辑
            this.getEditPicture();
          }
        } else {
          console.log('表单为空');
          return false;
        }
      });
    },
    // 添加
    async getIllegalPictures() {
      const data = {
        role: this.userInfo.role,
        name: this.form.accountName,
        image: this.form.imageUrl,
        user_id: this.userInfo.user_id,
      };
      const res: any = await getIllegalPictures(data);
      if (res.code == 200) {
        this.dialogFormVisible = false;
        // 刷新
        this.$router.go(0);
      }
    },
    // 编辑
    async getEditPicture() {
      const data = {
        id: this.id,
        name: this.form.accountName,
        image: this.form.imageUrl,
      };
      const res: any = await getEditPicture(data);
      if (res.code == 200) {
        this.dialogFormVisible = false;
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getprohibitedImage();
      }
    },
    // 取消按钮
    getCancel() {
      this.dialogFormVisible = false;
    },
    // 编辑按钮
    getModify(val: any) {
      this.title = '编辑违禁词';
      this.id = val.id;
      this.form.accountName = val.name;
      this.form.imageUrl = val.image;
      this.dialogFormVisible = true;
    },
    handleSuccess(response: any, file: any, fileList: any) {
      console.log(response, file, fileList);
      if (response.code == 200) {
        this.form.imageUrl = response.data.url;
      }
    },
    // 上传图片
    // async handleChange(fileList: any) {
    //   // flie打印
    //   const formData = new FormData();
    //   formData.append('file', fileList.raw);
    //   if (formData) {
    //     const res: any = await getUpload(formData);
    //     console.log(res);
    //     if (res.code == 200) {
    //       this.form.imageUrl = res.data.url;
    //     }
    //   }
    //   // this.form.imageUrl = URL.createObjectURL(file.raw);
    //   // console.log(this.form.imageUrl);
    // },
  },
  watch: {
    // 监听删除第二页之后请求上一页的操作
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getprohibitedImage();
      }
    },
  },
});

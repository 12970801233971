






























































































































































































































































































































































































































































































































































































import Vue from 'vue';
import {
  getPlatformList,
  postTemlateSite,
  postTemlateAdd,
  getPropertyList,
  getProductDetail,
  getProductedit,
} from '@/api';

export default Vue.extend({
  data() {
    return {
      id: '' as any, //产品模板的id
      testRul: {
        paramsName: '',
      },
      countryName: '', //选中的国家 engKeyWord 英国 usaKeyWord美国
      tableData: [
        //插入属性列表
        {
          code: '<product_name_code>',
          name: '产品代号',
          remarks: '有框',
        },
        {
          code: '<product_name_code>',
          name: '产品代号',
          remarks: '有框',
        },
      ],
      dialogVisible: false, //弹窗
      // selectTemplateIndex: '', //平台模板的选中序号
      propertyList: [] as any, //属性列表
      selectOption: '', //选中的选项
      //字段列表
      productTemplate: {
        user_name: '', //账号名字
        site: '', //站点
        template: '', //平台模板
        name: '', //模板名称
        info: [] as any, //产品类型
        product_main_sku: '<shop_code>-<end_product_id>', //产品母体SKU
        variant_sku: '3', //变体sku
        product_simple_sku: '<shop_code>-<end_product_id>', //简单商品SKU
        inventory: '', //库存数量
        product_title: [], //产品标题
        standard_price: '<standard_price>', //标准价格
        brand_name: '', //品牌
        manufacturer: '', //厂商
        color_name: '<color_name>', //颜色
        size_name: '<size_name>', //尺寸
        merchant_shipping_group_name: 'Migrated Template', //运费模板
        fabric_type: '', //布料类型
        condition_type: '', //condition_type
        fulfillment: '', //配送延迟
        style_name: 'Art Deco', //样式名
        currency: '', //货币
      } as any,
      //英国描述信息及关键字
      //type  1为输入 2为下拉 3为文本输入框
      writeKeyWord: {
        usaKeyWord: [
          {
            type: 3,
            zh: '产品描述',
            eng: 'product_description',
            text: '',
            maxLength: 2000,
          },
          {
            type: 3,
            zh: '卖点1',
            eng: 'bullet_point1',
            text: '',
            maxLength: 500,
          },
          {
            type: 3,
            zh: '卖点2',
            eng: 'bullet_point2',
            text: '',
            maxLength: 500,
          },
          {
            type: 3,
            zh: '卖点3',
            eng: 'bullet_point3',
            text: '',
            maxLength: 500,
          },
          {
            type: 3,
            zh: '卖点4',
            eng: 'bullet_point4',
            text: '',
            maxLength: 500,
          },
          {
            type: 3,
            zh: '卖点5',
            eng: 'bullet_point5',
            text: '',
            maxLength: 500,
          },
          {
            type: 1,
            zh: '具体使用关键字1',
            eng: 'specific_uses_keywords1',
            text: '',
          },
          {
            type: 1,
            zh: '具体使用关键字2',
            eng: 'specific_uses_keywords2',
            text: '',
          },
          {
            type: 1,
            zh: '具体使用关键字3',
            eng: 'specific_uses_keywords3',
            text: '',
          },
          {
            type: 1,
            zh: '具体使用关键字4',
            eng: 'specific_uses_keywords4',
            text: '',
          },
          {
            type: 1,
            zh: '具体使用关键字5',
            eng: 'specific_uses_keywords5',
            text: '',
          },
          {
            type: 1,
            zh: '目标受众关键字1',
            eng: 'target_audience_keywords1',
            text: '',
          },
          {
            type: 1,
            zh: '目标受众关键字2',
            eng: 'target_audience_keywords2',
            text: '',
          },
          {
            type: 1,
            zh: '目标受众关键字3',
            eng: 'target_audience_keywords3',
            text: '',
          },
          {
            type: 1,
            zh: 0,
            eng: 'thesaurus_attribute_keywords1',
            text: '',
          },
          {
            type: 1,
            zh: 0,
            eng: 'thesaurus_attribute_keywords2',
            text: '',
          },
          {
            type: 1,
            zh: 0,
            eng: 'thesaurus_attribute_keywords3',
            text: '',
          },
          {
            type: 1,
            zh: 0,
            eng: 'thesaurus_attribute_keywords4',
            text: '',
          },
          {
            type: 1,
            zh: '相似的标题关键词1',
            eng: 'thesaurus_subject_keywords1',
            text: '',
          },
          {
            type: 1,
            zh: '相似的标题关键词2',
            eng: 'thesaurus_subject_keywords2',
            text: '',
          },
          {
            type: 1,
            zh: '相似的标题关键词3',
            eng: 'thesaurus_subject_keywords3',
            text: '',
          },
          {
            type: 3,
            zh: 'Search Terms',
            eng: 'generic_keywords',
            text: '',
            maxLength: 250,
          },
        ],
        //美国信息及关键字
        engKeyWord: [
          {
            type: 3,
            zh: '产品描述',
            eng: 'product_description',
            text: '',
            maxLength: 2000,
          },
          {
            type: 3,
            zh: '卖点1',
            eng: 'bullet_point1',
            text: '',
            maxLength: 500,
          },
          {
            type: 3,
            zh: '卖点2',
            eng: 'bullet_point2',
            text: '',
            maxLength: 500,
          },
          {
            type: 3,
            zh: '卖点3',
            eng: 'bullet_point3',
            text: '',
            maxLength: 500,
          },
          {
            type: 3,
            zh: '卖点4',
            eng: 'bullet_point4',
            text: '',
            maxLength: 500,
          },
          {
            type: 3,
            zh: '卖点5',
            eng: 'bullet_point5',
            text: '',
            maxLength: 500,
          },
          {
            type: 3,
            zh: 'Search Terms',
            eng: 'generic_keywords',
            text: '',
            maxLength: 250,
          },
        ],
      }, //描述及其关键字
      templateTypeList: [] as any, //平台模板列表
      templateSite: [] as any, //站点列表
      temTypeList: {} as any, //当前选择平台的类型列表
      selectTemTypeListCont: {} as any, //选中模板类目信息的列表
      //标题规则列表
      dynamicTags: ['<material_name> <size_name>'],
      //选中的规则序号 默认第一个
      chooseTagIndex: 0,
      maxLength: true, //最多200个字符
      othenIndex: 0, //其他属性选中项

      otherList: {
        usaKeyWord: [
          'Fulfillment',
          'Basic',
          'Compliance',
          'Offer',
          'Product Enrichment',
          'Discovery',
          'Dimensions',
        ],
        engKeyWord: [
          'Basic',
          'Compliance',
          'Offer',
          'Product Enrichment',
          'Discovery',
          'Dimensions',
        ],
      }, //其他属性列表

      //其他字段
      otherAttributes: {
        usaKeyWord: [
          [
            //Fulfillment
            {
              type: 1, //1为输入 2为下拉
              zh: 'DEFAULT',
              eng: 'fulfillment_center_id',
              text: '',
            }, //包装高度：
            {
              type: 1, //1为输入 2为下拉
              zh: '包装高度：',
              eng: 'package_height',
              text: '',
            }, //包装高度：
            {
              type: 1, //1为输入 2为下拉
              zh: '包装宽度：',
              eng: 'package_width',
              text: '',
            }, //包装宽度：
            {
              type: 1, //1为输入 2为下拉
              zh: '包装长度：',
              eng: 'package_length',
              text: '',
            }, //包装长度：
            {
              type: 1, //1为输入 2为下拉
              zh: '包装尺寸计量单位：',
              eng: 'package_dimensions_unit_of_measure',
              text: '',
            }, //包装尺寸计量单位：
            {
              type: 1, //1为输入 2为下拉
              zh: '包装重量：',
              eng: 'package_weight',
              text: '',
            }, //包装重量：
            {
              type: 2, //1为输入 2为下拉
              zh: '产品重量计量单位：',
              eng: 'item_weight_unit_of_measure',
              text: '',
            }, //产品重量计量单位：
          ] as any,
          [
            //Basic
            {
              type: 2, //1为输入 2为下拉
              zh: '更新/删除：',
              eng: 'update_delete',
              text: '',
            }, //更新/删除：
            {
              type: 1, //1为输入 2为下拉
              zh: '生产编码：',
              eng: 'part_number',
              text: '',
            }, //生产编码：
            {
              type: 1, //1为输入 2为下拉
              zh: '模型：',
              eng: 'model',
              text: '',
            }, //模型：
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'losure_type',
              text: '',
            }, //
          ],
          [
            //Compliance
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'energy_efficiency_image_url',
              text: '',
            }, //更新/删除：
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'warranty_description',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: 'cpsia警告声明：',
              eng: 'cpsia_cautionary_statement',
              text: '',
            }, //模型：

            {
              type: 1, //1为输入 2为下拉
              zh: 'cpsia警告描述：',
              eng: 'cpsia_cautionary_description',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '布料类型：',
              eng: 'fabric_type',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: '进口名称：',
              eng: 'import_designation',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '法律遵从认证元数据：',
              eng: 'legal_compliance_certification_metadata',
              text: '',
            }, //生产编码：
            {
              type: 1, //1为输入 2为下拉
              zh: '法定合规认证有效期：',
              eng: 'legal_compliance_certification_expiration_date',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '产品体积：',
              eng: 'item_volume',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: '产品体积计量单位：',
              eng: 'item_volume_unit_of_measure',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'specific_uses_for_product',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: '原产地：',
              eng: 'country_of_origin',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '法律免责声明描述：',
              eng: 'legal_disclaimer_description',
              text: '',
            }, //生产编码：
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'usda_hardiness_zone1',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'usda_hardiness_zone2',
              text: '',
            }, //生产编码：
            {
              type: 1, //1为输入 2为下拉
              zh: '产品重量：',
              eng: 'item_weight',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: '产品体积：',
              eng: 'item_volume',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: '产品重量计量单位：',
              eng: 'item_volume_unit_of_measure',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'supplier_declared_dg_hz_regulation1',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'supplier_declared_dg_hz_regulation2',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'supplier_declared_dg_hz_regulation3',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'supplier_declared_dg_hz_regulation4',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'supplier_declared_dg_hz_regulation5',
              text: '',
            }, //生产编码：
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'hazmat_united_nations_regulatory_id',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'safety_data_sheet_url',
              text: '',
            }, //生产编码：
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'flash_point',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '出具法律符合性证明的日期：',
              eng: 'legal_compliance_certification_date_of_issue',
              text: '',
            }, //生产编码：
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'external_testing_certification1',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'external_testing_certification2',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'external_testing_certification3',
              text: '',
            }, //生产编码：
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'external_testing_certification4',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'external_testing_certification5',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'external_testing_certification6',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: '安全预警：',
              eng: 'safety_warning',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'ghs_classification_class1',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'ghs_classification_class2',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'ghs_classification_class3',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'ghs_classification_class4',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'california_proposition_65_compliance_type',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'california_proposition_65_chemical_names1',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'california_proposition_65_compliance_type2',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'california_proposition_65_compliance_type3',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'california_proposition_65_compliance_type4',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'california_proposition_65_compliance_type5',
              text: '',
            },
          ],
          [
            //off
            {
              type: 1, //1为输入 2为下拉
              zh: '特价',
              eng: 'sale_price',
              text: '',
            },
            {
              type: 2, //1为输入 2为下拉
              zh: '特价开始日期',
              eng: 'sale_from_date',
              text: '',
            },
            {
              type: 2, //1为输入 2为下拉
              zh: '特价结束日期',
              eng: 'sale_end_date',
              text: '',
            },
          ],
          [
            //product Enrichment
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'lens_color_map',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'eyewear_unit_of_measure',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'is_stain_resistant',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'subject_character',
              text: '',
            }, //
          ],
          [
            //discovery

            {
              type: 1, //1为输入 2为下拉
              zh: '样式名',
              eng: 'style_name',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'team_name',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'lens_color',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'magnification_strength',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '材料类型',
              eng: 'material_type',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'country_as_labeled',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'fur_description',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'lifestyle',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'special_features',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '白金关键词1',
              eng: 'platinum_keywords1',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '白金关键词2',
              eng: 'platinum_keywords2',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '白金关键词3',
              eng: 'platinum_keywords3',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '白金关键词4',
              eng: 'platinum_keywords4',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '白金关键词5',
              eng: 'platinum_keywords5',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品目录号',
              eng: 'catalog_number',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'seasons',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'shaft_style_type',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'magnification_strength_unit_of_measure',
              text: '',
            },
          ],
          [
            //dimensions
            {
              type: 1, //1为输入 2为下拉
              zh: '网站发货重量',
              eng: 'website_shipping_weight',
              text: '',
            },
            {
              type: 2, //1为输入 2为下拉
              zh: '网站运送重量的计量单位',
              eng: 'website_shipping_weight_unit_of_measure',
              text: '',
            },
            {
              type: 2, //1为输入 2为下拉
              zh: '产品长度计量单位',
              eng: 'tem_length_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品长度',
              eng: 'item_length',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品宽度',
              eng: 'item_width',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品高度',
              eng: 'item_height',
              text: '',
            },
          ],
        ],

        engKeyWord: [
          [
            //Basic
            {
              type: 2, //1为输入 2为下拉
              zh: '更新/删除：',
              eng: 'update_delete',
              text: '',
            }, //更新/删除：
            {
              type: 1, //1为输入 2为下拉
              zh: '生产编码：',
              eng: 'part_number',
              text: '',
            }, //生产编码：inner_material_type1
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'inner_material_type1',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'inner_material_type2',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'inner_material_type3',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'inner_material_type4',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'inner_material_type5',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'gtin_exemption_reason',
              text: '',
            },
          ],
          [
            //Compliance
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'customer_restriction_type',
              text: '',
            }, //更新/删除：
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'warranty_description',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'eu_toys_safety_directive_age_warning',
              text: '',
            },
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'eu_toys_safety_directive_warning',
              text: '',
            }, //模型：

            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'eu_toys_safety_directive_language',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'country_string',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '产品重量：',
              eng: 'item_weight',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: '产品重量计量单位：',
              eng: 'item_weight_unit_of_measure',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '产品体积：',
              eng: 'item_volume',
              text: '',
            }, //生产编码：
            {
              type: 2, //1为输入 2为下拉
              zh: '产品体积计量单位：',
              eng: 'item_volume_unit_of_measure',
              text: '',
            }, //

            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'product_efficiency_image_url',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'energy_efficiency_image_url',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '法律免责声明描述',
              eng: 'legal_disclaimer_description',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: '安全预警：',
              eng: 'safety_warning',
              text: '',
            }, //
            {
              type: 2, //1为输入 2为下拉
              zh: 0,
              eng: 'fedas_id',
              text: '',
            },
          ],
          [
            //off
            {
              type: 1, //1为输入 2为下拉
              zh: '产品上线日期',
              eng: 'product_site_launch_date',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '货币',
              eng: 'currency',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '包裹数量',
              eng: 'item_package_quantity',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '产品免税代码',
              eng: 'product_tax_code',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '商家发布日期',
              eng: 'merchant_release_date',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '特价',
              eng: 'sale_price',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '特价开始日期',
              eng: 'sale_from_date',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '特价结束日期',
              eng: 'sale_end_date',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '商品状态类型',
              eng: 'condition_type',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '状态码',
              eng: 'condition_note',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '进货日期',
              eng: 'restock_date',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '最大单个订单发货数量',
              eng: 'max_aggregate_ship_quantity',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '提供可以是礼物信息',
              eng: 'offering_can_be_gift_messaged',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '礼物可以赠送',
              eng: 'offering_can_be_giftwrapped',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '被制造商停止生产',
              eng: 'is_discontinued_by_manufacturer',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '吊牌价',
              eng: 'list_price',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'weee_tax_value',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'weee_tax_value_unit_of_measure',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '服务结束日期',
              eng: 'offering_end_date',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '最大订单数量',
              eng: 'max_order_quantity',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: '提供起始日期',
              eng: 'offering_start_date',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'delivery_schedule_group_id',
              text: '',
            }, //
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'uvp_list_price',
              text: '',
            }, //
          ],
          [
            //Product Enrichment

            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'finish_type1',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'finish_type2',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'finish_type3',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'finish_type4',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'finish_type5',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'efficiency',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'length_range',
              text: '',
            },
          ],
          [
            //discovery

            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'wattage',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品目录号',
              eng: 'catalog_number',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '白金关键词1',
              eng: 'platinum_keywords1',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '白金关键词2',
              eng: 'platinum_keywords2',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '白金关键词3',
              eng: 'platinum_keywords3',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '白金关键词4',
              eng: 'platinum_keywords4',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '白金关键词5',
              eng: 'platinum_keywords5',
              text: '',
            },

            {
              type: 1, //1为输入 2为下拉
              zh: '零件数量',
              eng: 'number_of_pieces',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'theme',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '样式名',
              eng: 'style_name',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'special_features1',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'special_features2',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'special_features3',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'special_features4',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'special_features5',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'special_features6',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'paint_type',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '材料类型',
              eng: 'material_type',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'capacity_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'wattage_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'power_source_type',
              text: '',
            },
          ],
          [
            //dimensions
            {
              type: 1, //1为输入 2为下拉
              zh: '产品显示深度',
              eng: 'item_display_depth',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'item_display_depth_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '网站发货重量',
              eng: 'website_shipping_weight',
              text: '',
            },
            {
              type: 2, //1为输入 2为下拉
              zh: '网站运送重量的计量单位',
              eng: 'website_shipping_weight_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品显示长度',
              eng: 'item_display_length',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'item_display_length_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品显示宽度',
              eng: 'item_display_width',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'item_display_width_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品显示高度',
              eng: 'item_display_height',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'item_display_height_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品显示直径',
              eng: 'item_display_diameter',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品显示直径单位的测量',
              eng: 'item_display_diameter_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品显示重量',
              eng: 'item_display_weight',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品显示重量单位的衡量',
              eng: 'item_display_weight_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '体积容量',
              eng: 'volume_capacity_name',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '容量计量单位',
              eng: 'volume_capacity_name_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品显示体积',
              eng: 'item_display_volume',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品显示体积单位的衡量',
              eng: 'item_display_volume_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '单位计数类型',
              eng: 'unit_count_type',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '单位计数',
              eng: 'unit_count',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品形状',
              eng: 'item_shape',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'item_diameter_derived',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'item_diameter_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'capacity',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'item_width_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品宽度',
              eng: 'item_width',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品高度',
              eng: 'item_height',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'item_thickness_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'width_range',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'item_thickness_derived',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: 0,
              eng: 'item_height_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品尺寸测量单位',
              eng: 'item_dimensions_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品长度计量单位',
              eng: 'item_length_unit_of_measure',
              text: '',
            },
            {
              type: 1, //1为输入 2为下拉
              zh: '产品长度',
              eng: 'item_length',
              text: '',
            },
          ],
        ],
      },
      // 是否添加或者编辑的状态

      //验证规则
      rules: {
        // name: [
        //   { required: true, message: '请输入账号名称', trigger: 'blur' },
        //   { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        // ],
        site: [{ required: true, message: '请选择站点', trigger: 'change' }],
        template: [
          { required: true, message: '请选择模板', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
          {
            min: 3,
            max: 20,
            message: '长度在 3 到 20 个字符',
            trigger: 'blur',
          },
        ],
        inventory: [
          { required: true, message: '请输入库存数', trigger: 'blur' },
        ],
        manufacturer: [
          { required: true, message: '请输入厂商', trigger: 'change' },
        ],
        size_name: [
          { required: true, message: '请输入尺寸', trigger: 'change' },
        ],
        color_name: [
          { required: true, message: '请输入颜色', trigger: 'change' },
        ],
        merchant_shipping_group_name: [
          { required: true, message: '请选择商户运费模板', trigger: 'change' },
        ],
      },
      type: '' as any,
    };
  },
  mounted() {
    this.postTemlateSite();
    this.getPropertyList();

    this.type = this.$route.query.type;
    if (this.type != 1) {
      this.id = this.$route.query.id;
      this.getProductDetail();
    }
    // if(this.$route.query){
    //   console.log(this.$route.query,"this.$route.query")

    // }
  },
  methods: {
    //其他值输入后修改的值
    // chageOtherValue(val: any) {
    //   // console.log(val, 'val', this.otherAttributes);
    //   console.log(this.productTemplate, 'newOIbs', val);
    // },
    //获取产品模板详情 进行数据填入
    getProductDetail() {
      getProductDetail({ id: this.id }).then(res => {
        this.productTemplate = res.data;
        if (res.data.product_title.length > 0) {
          this.dynamicTags = JSON.parse(res.data.product_title);
        }
        if (res.data.info.length > 0) {
          this.selectTemTypeListCont = JSON.parse(res.data.info);
        }
        this.getPlatformList();
        setTimeout(() => {
          this.selectTemplate(res.data.template);
        }, 1000);
      });
    },
    //使用属性 插入属性值
    useProperty(row: any) {
      this.productTemplate[`${this.selectOption}`] = row.code;
      this.dialogVisible = false;
    },
    //
    //添加属性列表
    addProperty(value: any) {
      console.log(value);
      this.dialogVisible = true;
      this.selectOption = value;
    },
    //下拉获取改变的值
    cahngeIndex(val: any) {
      console.log(val, 'qweqweqwe', this.selectTemTypeListCont);
      // val.forEach((val: any, index: any) => {
      //   console.log(val, index, 'qweqweqwe');
      //   // this.tableData.forEach((v, i) => {
      //   //   // id 是每一行的数据id
      //   //   console.log(v, i, 'qweqweqwe');
      //   //   // if (val.id == v.id) {
      //   //   //   console.log(i);
      //   //   // }
      //   // });
      // });
    },
    //保存产品模板
    saveProductModel() {
      if (this.productTemplate.site.length <= 0) {
        this.$message({
          message: '请选择站点',
          type: 'warning',
        });
        return;
      }
      if (this.productTemplate.template.length <= 0) {
        this.$message({
          message: '请选择平台模板',
          type: 'warning',
        });
        return;
      }
      if (this.productTemplate.name.length <= 0) {
        this.$message({
          message: '请输入模板名称',
          type: 'warning',
        });
        return;
      }
      if (this.productTemplate.inventory.length <= 0) {
        this.$message({
          message: '请输入库存数量',
          type: 'warning',
        });
        return;
      }
      if (this.productTemplate.manufacturer.length <= 0) {
        this.$message({
          message: '请输入厂商',
          type: 'warning',
        });
        return;
      }
      if (this.productTemplate.color_name.length <= 0) {
        this.$message({
          message: '请输入颜色',
          type: 'warning',
        });
        return;
      }
      if (this.productTemplate.size_name.length <= 0) {
        this.$message({
          message: '请输入尺寸',
          type: 'warning',
        });
        return;
      }
      if (this.productTemplate.merchant_shipping_group_name.length <= 0) {
        this.$message({
          message: '请选择运费模板',
          type: 'warning',
        });
        return;
      }

      const data = this.productTemplate;
      data.product_title = JSON.stringify(this.dynamicTags);
      data.info = JSON.stringify(this.selectTemTypeListCont);
      if (this.type == '2') {
        //修改编辑
        data.id = this.id;
        getProductedit(data).then((res: any) => {
          if (res.code == 200) {
            this.$router.go(-1);
          }
        });
      } else if (this.type != '2') {
        //新增保存
        data.id = '';
        postTemlateAdd(data).then((res: any) => {
          this.$router.go(-1);
          console.log(res.code, '123123');
        });
      }
    },
    //获取站点列表
    postTemlateSite() {
      postTemlateSite().then(res => {
        this.templateSite = res.data;
      });
    },
    // 属性列表
    getPropertyList() {
      getPropertyList().then(res => {
        this.propertyList = res.data.rows;
      });
    },
    //选择国家
    selectCountry() {
      this.productTemplate.templateType = '';
      this.temTypeList = [];
      this.productTemplate.template = '';
      this.getPlatformList();
    },
    //选择模板
    selectTemplate(val: any) {
      const index = this.templateTypeList.findIndex((item: any) => {
        return item.template_name == val;
      });
      if (index == -1) {
        //判断在平台模板中是否存在
        this.productTemplate.template = '';
        return;
      }
      this.temTypeList = this.templateTypeList[index].info;
    },
    //获取平台模板列表
    getPlatformList() {
      const filter = {
        site: this.productTemplate.site,
      };
      const data = {
        filter: JSON.stringify(filter),
      };

      getPlatformList(data).then(res => {
        this.templateTypeList = res.data.rows;
        // console.log(this.templateTypeList, 'this.templateTypeList');
      });
      if (this.productTemplate.site == '美国') {
        this.countryName = 'usaKeyWord';
      } else if (this.productTemplate.site == '英国') {
        this.countryName = 'engKeyWord';
      }
    },
    //关闭弹窗属性
    handleClosePop() {
      this.dialogVisible = false;
    },
    //选择其他属性
    othenSelsct(index: any) {
      if (this.othenIndex == index) {
        return;
      } else {
        this.othenIndex = index;
      }
    },
    //新增规则项
    addTags() {
      this.chooseTagIndex = this.dynamicTags.length;
      this.dynamicTags = this.dynamicTags.concat('');
      console.log(this.dynamicTags, 'this.dynamicTags');
    },
    //选中标签
    chooseTags(index: number) {
      this.chooseTagIndex = index;
    },
    //删除产品标签的规则标签
    handleClose(tag: number) {
      this.dynamicTags.splice(tag, 1);
      if (tag < this.chooseTagIndex) {
        this.chooseTagIndex -= 1;
      }
      if (tag == this.chooseTagIndex) {
        this.chooseTagIndex == 0;
      }
    },
    onSubmit() {
      console.log('submit!');
    },
  },
});



































































































































































































































import Vue from 'vue';
import { getProductList, postTemlateSite } from '../api';
export default Vue.extend({
  data() {
    return {
      //侧边弹窗
      drawer: false,
      oirderTypeList: [
        '所有订单',
        '待付款',
        '待设计',
        '待确认',
        '搁置中',
        '备货中',
        '已完成',
        '已取消',
      ],
      visible: false,
      // 时间
      contrabandWord: [] as any,
      options: [],
      // 产品名称
      productName: '',
      // 站点
      priceStie: '',
      // 模板类型
      templateType: '',
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 0,
      page: 1,
      userInfo: {} as any,
      //添加店铺数据
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: true,
        type: [],
        resource: '',
        desc: '',
        radio: 3,
      },
      rules: {
        name: [
          { required: true, message: '请输入店铺名称', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '长度在 3 到 10 个字符',
            trigger: 'blur',
          },
        ],
        site: [
          { required: true, message: '请输入店铺代号', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '长度在 3 到 10 个字符',
            trigger: 'blur',
          },
        ],
        sell: [
          { required: true, message: '请输入Seller ID', trigger: 'blur' },
          {
            min: 3,
            max: 30,
            message: '长度在 3 到 30 个字符',
            trigger: 'blur',
          },
        ],
        author: [
          { required: true, message: '请输入MWS Auth Token', trigger: 'blur' },
          {
            min: 3,
            max: 100,
            message: '长度在 3 到 100 个字符',
            trigger: 'blur',
          },
        ],
        region: [{ required: true, message: '请选择站点', trigger: 'change' }],
      },
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
    this.getProductList();
    this.postTemlateSite();
  },
  methods: {
    // 站点列表
    async postTemlateSite() {
      const res: any = await postTemlateSite();
      if (res.code == 200) {
        // console.log(res.data);
        this.options = res.data;
      }
    },
    // 重置按钮
    getReset() {
      this.productName = '';
      this.priceStie = '';
      this.templateType = '';
    },
    // 获取产品模板列表
    async getProductList() {
      const data = {
        filter: {
          site: this.priceStie,
          name: this.productName,
          type: this.templateType,
        },
        op: {
          name: 'like',
          site: 'like',
        },
        limit: '10',
        page: this.page,
      };
      const res: any = await getProductList(data);
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },

    // 搜索框
    getLookup() {
      this.page = 1;
      this.getProductList();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getProductList();
    },
    // 编辑
    getModify(row: any) {
      this.$router.push({
        path: '/productGreate',
        query: { id: row.id, type: '2' },
      });
    },
  },
  watch: {
    // 监听删除第二页之后请求上一页的操作
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getProductList();
      }
    },
  },
});

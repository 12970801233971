import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import productGreate from '@/views/productGreate.vue';
import commodityGreate from '@/views/commodityGreate.vue';
import management from '@/views/management.vue';
import application from '@/views/application.vue';
import pictures from '@/views/pictures.vue';
import prohibitedWords from '@/views/prohibitedWords.vue';
import changePassword from '@/views/changePassword.vue';
import approval from '@/views/approval.vue';
import regionalAgency from '@/views/regionalAgency.vue';
import pricing from '@/views/pricing.vue';
import commodity from '@/views/commodity.vue';
import product from '@/views/product.vue';
import attribute from '@/views/attribute.vue';
import materialLibrary from '@/views/materialLibrary.vue';
import productLibrary from '@/views/productLibrary.vue';
import designRecord from '@/views/designRecord.vue';
import finishedProduct from '@/views/finishedProduct.vue';
import barcode from '@/views/barcode.vue';
import exportRecord from '@/views/exportRecord.vue';
import technicalResults from '@/views/technicalResults.vue';
import sign from '@/views/sign.vue';
import platform from '@/views/platform.vue';
import modifyPlatform from '@/views/modifyPlatform.vue';
import designPanel from '@/views/designPanel.vue';
import modifyBgImg from '@/views/modifyBgImg.vue';
import shopAdmin from '@/views/shopAdmin.vue';
import shopOnline from '@/views/shopOnline.vue';
import orderManage from '@/views/orderManage.vue';
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: any) {
  return (originalPush.call(this, location) as any).catch((err: any) => err);
};
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/sign',
    name: 'sign',
    component: sign,
    meta: { title: '登录' },
  },
  {
    path: '/management',
    name: 'management',
    component: management,
    meta: { title: '用户管理' },
  },
  {
    path: '/productGreate',
    name: 'productGreate',
    component: productGreate,
    meta: { title: '编辑模板管理' },
  },
  {
    path: '/commodityGreate',
    name: 'commodityGreate',
    component: commodityGreate,
    meta: { title: '商品模板管理' },
  },
  {
    path: '/application',
    name: 'application',
    component: application,
    meta: { title: '申请管理' },
  },
  {
    path: '/prohibitedWords',
    name: 'prohibitedWords',
    component: prohibitedWords,
    meta: { title: '违禁词模板管理' },
  },
  {
    path: '/pictures',
    name: 'pictures',
    component: pictures,
    meta: { title: '违规图片模板管理' },
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: changePassword,
    meta: { title: '修改密码' },
  },
  {
    path: '/approval',
    name: 'approval',
    component: approval,
    meta: { title: '审批管理' },
  },
  {
    path: '/regionalAgency',
    name: 'regionalAgency',
    component: regionalAgency,
    meta: { title: '区域代理' },
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: pricing,
    meta: { title: '定价模板管理' },
  },
  {
    path: '/commodity',
    name: 'commodity',
    component: commodity,
    meta: { title: '商品模板管理' },
  },
  {
    path: '/product',
    name: 'product',
    component: product,
    meta: { title: '产品模板' },
  },
  {
    path: '/designPanel',
    name: 'designPanel',
    component: designPanel,
    meta: { title: '产品设计面板' },
  },
  {
    path: '/attribute',
    name: 'attribute',
    component: attribute,
    meta: { title: '属性管理' },
  },
  {
    path: '/materialLibrary',
    name: 'materialLibrary',
    component: materialLibrary,
    meta: { title: '素材库' },
  },
  {
    path: '/productLibrary',
    name: 'productLibrary',
    component: productLibrary,
    meta: { title: '产品库' },
  },
  {
    path: '/designRecord',
    name: 'designRecord',
    component: designRecord,
    meta: { title: '设计记录' },
  },
  {
    path: '/technicalResults',
    name: 'technicalResults',
    component: technicalResults,
    meta: { title: '设计结果' },
  },

  {
    path: '/finishedProduct',
    name: 'finishedProduct',
    component: finishedProduct,
    meta: { title: '成品管理' },
  },
  {
    path: '/barcode',
    name: 'barcode',
    component: barcode,
    meta: { title: '条形码管理' },
  },
  {
    path: '/exportRecord',
    name: 'exportRecord',
    component: exportRecord,
    meta: { title: '汇出记录' },
  },

  {
    path: '/platform',
    name: 'platform',
    component: platform,
    meta: { title: '平台模板管理' },
  },
  {
    path: '/modifyPlatform',
    name: 'modifyPlatform',
    component: modifyPlatform,
    meta: { title: '平台模板管理' },
  },
  {
    path: '/modifyBgImg',
    name: 'modifyBgImg',
    component: modifyBgImg,
    meta: { title: '背景图片替换' },
  },
  {
    path: '/shopAdmin',
    name: 'shopAdmin',
    component: shopAdmin,
    meta: { title: '店铺管理' },
  },
  {
    path: '/shopOnline',
    name: 'shopOnline',
    component: shopOnline,
    meta: { title: '线上订单' },
  },
  {
    path: '/orderManage',
    name: 'orderManage',
    component: orderManage,
    meta: { title: '订单管理' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;































































































































































import Vue from 'vue';
import { getCommodityList, getDeleteCommodity } from '../api';
export default Vue.extend({
  data() {
    return {
      // 类目的数据
      options: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          id: '1',
          label: '双皮奶',
        },
        {
          id: '2',
          label: '蚵仔煎',
        },
        {
          id: '3',
          label: '龙须面',
        },
        {
          id: '4',
          label: '北京烤鸭',
        },
      ],
      // 产品名称
      productName: '',
      // 产品编号
      productNumber: '',
      // 类目的选择
      category: '',
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 0,
      // 选中的数据，用于批量删除
      selectDate: [],
      // 新增用户
      form: {
        // 账号名
        accountName: '',
        // 用户名
        userName: '',
        // 手机号码
        phone: '',
        // 密码
        password: '',
        // 到期时间
        expireTime: '',
      },
      page: 1,
    };
  },
  mounted() {
    this.getCommodityList();
  },
  methods: {
    // 商品模板的列表的请求
    async getCommodityList() {
      const data = {
        filter: {
          chinese_name: this.productName,
          id: this.productNumber,
          category: this.category,
        },
        limit: '10',
        page: this.page,
      };
      const res: any = await getCommodityList(data);
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },
    // 重置按钮
    getReset() {
      this.productName = '';
      this.productNumber = '';
      this.category = '';
    },
    // 搜索框
    getLookup() {
      this.page = 1;
      this.getCommodityList();
    },
    // 选中
    handleSelectionChange(val: any) {
      this.selectDate = val;
    },
    // 删除按钮
    async handleDelete(id: any) {
      // console.log('删除单个', row);
      const data = { id };
      const res: any = await getDeleteCommodity(data);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getCommodityList();
      }
    },
    // 批量删除
    async getBatchDelete() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      const data = {
        id: id.toString(),
      };
      const res: any = await getDeleteCommodity(data);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getCommodityList();
      }
    },
    // 删除按钮
    handleClose(row: any) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id);
          })
          .catch(() => {
            console.log('取消删除');
          });
      } else if (this.selectDate.length) {
        this.$confirm('确认批量删除？')
          .then(() => {
            this.getBatchDelete();
          })
          .catch(() => {
            console.log('取消批量删除');
          });
      }
    },
    // 编辑
    getModify(row: any) {
      this.$router.push({
        path: '/commodityGreate',
        query: { id: row.id },
      });
    },
    // 清空选择
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      // console.log(466, val);
      this.page = val;
      this.getCommodityList();
    },
    //弹起添加(•̀⌄•́)
    getBounce() {
      this.$router.push({
        path: '/commodityGreate',
      });
    },
  },
  watch: {
    // 监听删除第二页之后请求上一页的操作
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getCommodityList();
      }
    },
  },
  filters: {
    addZero(val: any) {
      if (val > 10) {
        return '0' + val;
      } else if (val < 10) {
        return '00' + val;
      } else if (val > 99) {
        return val;
      }
    },
  },
});






































































































































































































































































import Vue from 'vue';
import {
  getEegionLsit,
  getRegionEditor,
  getAgencyAdd,
  getAddUser,
  getZoneDeletion,
  getEditUser,
  getLookUserList,
  getdeleteUser,
} from '@/api';
export default Vue.extend({
  data() {
    // 判断是否存在改用户名
    // const checkUsername = (rule: any, value: any, callback: any) => {
    //   // if (value != "") {
    //   // 请求接口
    //   //   if (this.usernameRules()) {
    //   //     callback(new Error("用户名已存在"));
    //   //   }
    //   //   callback();
    //   // }
    // };
    // 判断手机号码
    const checkPhone = (rule: any, value: any, callback: any) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;

        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      expireTimeOption: {
        disabledDate(date: any) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      prohibit: true,
      // 查看弹窗的隐藏与显示
      dialogDetails: false,
      // 判断是新增代理0还是新增用户1,
      increase: 0,
      // 弹窗的文字
      title: ['新增代理', '新增用户', '编辑代理信息', '编辑用户信息'],
      // 账号
      accountNumber: '',
      // 代理名称
      userName: '',
      // 手机号码
      phoneNumber: '',
      // 用户管理数据
      tableData: [],
      // 代理下级用户详情数据
      gridData: [],
      // 数据总数
      currentLength: 0,
      // subordinateLength
      subordinateLength: 0,
      // 选中的数据，用于批量删除
      selectDate: [],
      // 新增用户
      form: {
        // 账号名
        accountName: '',
        // 用户名
        userName: '',
        // 手机号码
        phone: '',
        // 密码
        password: '',
        // 到期时间
        expireTime: '',
        // 所属代理
        agent: '',
      },
      // 新增用户的验证
      rules: {
        accountName: [
          { required: true, message: '请输入账号名', trigger: 'blur' },
          //  { validator: checkUsername, trigger: "blur" }
        ],
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        agent: [{ required: true, message: '请输入所属代理', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
          { validator: checkPhone, trigger: 'change' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' },
          {
            min: 8,
            max: 24,
            message: '密码长度在 8 到 24 个字符',
            trigger: 'change',
          },
        ],
        expireTime: [
          { required: true, message: '请输入到期时间', trigger: 'blur' },
        ],
      },
      // 弹窗的显示与隐藏
      dialogFormVisible: false,
      page: 1,
      // 编辑选中的id
      id: '',
      // 弹窗的显示代理信息
      lookData: {
        name: '',
        realName: '',
        mobile: '',
      },
      // 弹窗分页
      PopupPage: 1,
      // 弹窗编辑id
      PopupId: '',
      // 弹窗列表请求数据的id
      PopupListId: '',
      // 添加用户是否重新请求用户列表
      againStatus: false,
    };
  },
  mounted() {
    this.getEegionLsit();
  },
  methods: {
    // 区域代理的列表请求
    async getEegionLsit() {
      const filter = {
        username: this.accountNumber,
        real_name: this.userName,
        mobile: this.phoneNumber,
      };
      const page = this.page;
      const limit = '10';
      const res: any = await getEegionLsit(JSON.stringify(filter), limit, page);
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      } else {
        this.$message({
          message: res.msg,
          type: 'warning',
        });
      }
    },
    // 搜索框
    getLookup() {
      this.page = 1;
      this.getEegionLsit();
    },
    // 选中
    handleSelectionChange(val: any) {
      this.selectDate = val;
    },
    // 删除按钮
    async handleDelete(id: any, num: number) {
      const data = {
        id,
      };
      if (num == 1) {
        // 删除区域代理
        const res: any = await getZoneDeletion(data);
        if (res.code == 200) {
          this.getEegionLsit();
          this.$message({
            message: res.msg,
            type: 'success',
          });
        } else {
          this.$message({
            message: res.msg,
            type: 'warning',
          });
        }
      } else {
        const res: any = await getdeleteUser(id);
        if (res.code == 200) {
          this.getLookUserList();
          this.$message({
            message: res.msg,
            type: 'success',
          });
        } else {
          this.$message({
            message: res.msg,
            type: 'warning',
          });
        }
        // 删除用户
      }
    },
    // 清空选择
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getEegionLsit();
    },
    // 删除的弹窗
    handleClose(row: any, num: number) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id, num);
          })
          .catch(() => {
            console.log('取消删除');
          });
      } else if (this.selectDate.length) {
        this.$confirm('确认批量删除？')
          .then(() => {
            this.getBatchDelete();
          })
          .catch(() => {
            console.log('取消批量删除');
          });
      }
    },
    // 弹窗的分页
    subordinateChange(val: any) {
      this.PopupPage = val;
      this.getLookUserList();
    },
    // 批量删除
    async getBatchDelete() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      const data = {
        id: id.toString(),
      };
      const res: any = await getZoneDeletion(data);
      if (res.code == 200) {
        this.getEegionLsit();
        this.$message({
          message: res.msg,
          type: 'success',
        });
      } else {
        this.$message({
          message: res.msg,
          type: 'warning',
        });
      }
    },
    getReset() {
      this.accountNumber = '';
      this.userName = '';
      this.phoneNumber = '';
    },
    //弹起添加用户或者添加代理
    getBounce(increase: number, inside: boolean, val: any) {
      if (increase == 0) {
        this.form.agent = '';
      } else if (increase == 1 && inside == true) {
        this.againStatus = inside;
        this.form.agent = this.lookData.realName;
      } else if (increase == 1 && inside == false) {
        this.form.agent = val.real_name;
        this.againStatus = inside;
      }
      if (inside == false) {
        this.PopupListId = val.id;
      }
      (this.form.accountName = ''), (this.form.userName = '');
      (this.form.phone = ''), (this.form.password = '');
      this.form.expireTime = '';
      this.prohibit = false;
      this.increase = increase;
      this.dialogFormVisible = true;
    },
    // 编辑代理信息或者编辑用户信息
    getModify(val: any, increase: number) {
      this.prohibit = true;
      this.increase = increase;
      if (increase == 2) {
        this.id = val.id;
        this.form.accountName = val.username;
        this.form.userName = val.real_name;
        this.form.phone = val.mobile;
        this.form.password = '********';
        this.form.expireTime = (val.use_time * 1000).toString();
      } else if (increase == 3) {
        this.PopupId = val.id;
        this.form.accountName = val.username;
        this.form.userName = val.real_name;
        this.form.phone = val.mobile;
        this.form.password = '********';
        this.form.expireTime = (val.use_time * 1000).toString();
      }
      this.dialogFormVisible = true;
    },
    // 编辑代理的接口
    async getRegionEditor() {
      const data = {
        id: this.id,
        username: this.form.accountName,
        real_name: this.form.userName,
        mobile: this.form.phone,
        use_time: Number(this.form.expireTime) / 1000,
      };
      const res: any = await getRegionEditor(data);
      if (res.code == 200) {
        this.getEegionLsit();
        this.dialogFormVisible = false;

        this.$message({
          message: res.msg,
          type: 'success',
        });
      } else {
        this.$message({
          message: res.msg,
          type: 'warning',
        });
      }
    },
    // 添加区域代理
    async getAgencyAdd() {
      const data = {
        username: this.form.accountName,
        real_name: this.form.userName,
        mobile: this.form.phone,
        use_time: Number(this.form.expireTime) / 1000,
        password: this.form.password,
      };
      const res: any = await getAgencyAdd(data);
      if (res.code == 200) {
        // this.$message({
        //   message: res.msg,
        //   type: 'success',
        // });
        this.dialogFormVisible = false;
        this.$router.go(0);
      }
    },
    // 添加用户
    async getAddUser() {
      const data = {
        username: this.form.accountName,
        real_name: this.form.userName,
        mobile: this.form.phone,
        use_time: Number(this.form.expireTime) / 1000,
        password: this.form.password,
        parent_id: this.PopupListId,
      };
      const res: any = await getAddUser(data);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        if (this.againStatus == true) {
          this.dialogFormVisible = false;
          this.PopupPage = 1;
          this.getLookUserList();
        } else {
          this.dialogFormVisible = false;
          this.$router.go(0);
        }
      }
    },
    // 编辑用户信息
    async getModifyUser() {
      const data = {
        id: this.PopupId,
        username: this.form.accountName,
        real_name: this.form.userName,
        mobile: this.form.phone,
        use_time: Number(this.form.expireTime) / 1000,
      };
      const res: any = await getEditUser(data);
      if (res.code == 200) {
        this.getLookUserList();
        this.dialogFormVisible = false;
        this.$message({
          message: res.msg,
          type: 'success',
        });
      } else {
        this.$message({
          message: res.msg,
          type: 'warning',
        });
      }
    },
    //添加用户按钮 或者添加代理按钮还有编辑
    getDialogFormVisible(form: any) {
      (this.$refs[form] as any).validate((valid: any) => {
        if (valid) {
          if (this.increase == 1) {
            // 在这里请求新增用户的接口
            this.getAddUser();
          } else if (this.increase == 2) {
            //  在这里请求编辑代理的接口
            this.getRegionEditor();
          } else if (this.increase == 0) {
            //  在这里请求新增代理的接口
            this.getAgencyAdd();
          } else if (this.increase == 3) {
            // 编辑用户的
            this.getModifyUser();
          }
        } else {
          return false;
        }
      });
    },
    // 每次打开对话框删除form表单
    // getOpen(formName: any) {
    //   this.$nextTick(() => {
    //     (this.$refs[formName] as any).resetFields();
    //   });
    // },
    // 查看弹窗拿用户列表
    getSubordinate(row: any) {
      this.lookData.name = row.username;
      this.lookData.realName = row.real_name;
      this.lookData.mobile = row.mobile;
      this.form.agent = row.real_name;
      this.PopupListId = row.id;
      this.getLookUserList();
    },
    // 使用查看的id，拿用户的列表
    async getLookUserList() {
      const data = {
        limit: '10',
        page: this.PopupPage,
        id: this.PopupListId,
      };
      const res: any = await getLookUserList(data);
      if (res.code == 200) {
        this.gridData = res.data.rows;
        this.subordinateLength = res.data.total;
        this.dialogDetails = true;
      } else {
        this.$message({
          message: res.msg,
          type: 'warning',
        });
      }
    },
    // 取消按钮
    getCancel() {
      this.dialogFormVisible = false;
      // (this.$refs[formName] as any).resetFields();
    },
  },
  watch: {
    // 监听删除第二页之后请求上一页的操作
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getEegionLsit();
      }
    },
    gridData() {
      if (!this.gridData.length && this.PopupPage > 1) {
        this.PopupPage = this.PopupPage - 1;
        this.getLookUserList();
      }
    },
  },
  filters: {
    formatTime(time: number) {
      if (time) {
        const date = new Date(time * 1000);
        const Y = date.getFullYear();
        let M = (date.getMonth() + 1).toString();
        let D = date.getDate().toString();
        if (Number(M) < 10) {
          M = '0' + M;
        }
        if (Number(D) < 10) {
          D = '0' + D;
        }
        return Y + '.' + M + '.' + D;
        // if (str == '-') {
        //   return Y + '年' + M + '月' + D + '日';
        // } else {
        //   return Y + '.' + M + '.' + D;
        // }
      }
    },
  },
});

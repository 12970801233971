







































































































































































































































































import {
  getTemplateSize,
  getTemplateAddSize,
  getTemplateDeletSize,
  getGoodsTemplateAdd,
  getPictureTemplate,
  getProductDetails,
  getEditProduct,
  getDeleteSpecification,
  postTemlateSite,
} from '../api';
import Vue from 'vue';
// import { log } from 'node_modules/three/src/polyfills';
// import { log } from 'node_modules/three/src/polyfills';
export default Vue.extend({
  data() {
    return {
      checkFormat: [] as any, //选中的规格
      checkedCities: [] as any, //选中的国家

      templateSite: [] as any, //站点列表

      newFormatName: '', //新添加输入的规格名
      //规格列表
      formatList: [] as any,
      //模板图片·列表
      // srcList: [
      //   'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
      //   'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
      // ],
      // 模板图片
      ImgList: [] as any,
      // 选中的模板数据
      ImgData: {
        cover: {} as any,
      } as any,
      cvhexk: 0, //单选框
      tableData: [] as any, //产品规格列表
      productTemplate: {
        chinese_name: '', //中文名称
        english_name: '', //英文名称
        category: '', //类目
        texture: '', //	材质
        sku: '', //产品sku
        shipments_type: '自发货', //发货类型
      },
      productStatus: '1', //状态:0=关闭,1=开启 默认开启
      // 编辑的id，
      id: '',
      //验证规则
      rules: {
        chinese_name: [
          { required: true, message: '请输入中文名称', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '长度在 2 到 13 个字符',
            trigger: 'blur',
          },
        ],
        site: [{ required: true, message: '请选择站点', trigger: 'change' }],
        templateType: [
          { required: true, message: '请选择模板', trigger: 'change' },
        ],
        texture: [{ required: true, message: '请输入材质', trigger: 'change' }],
      } as any,
    };
  },
  mounted() {
    this.getPictureTemplate();
    this.getTemplateSize();
    this.postTemlateSite();

    this.id = (this.$route as any).query.id;
    if (this.id) {
      this.getProductDetails({ id: this.id });
    }
  },
  methods: {
    chooseFormat() {
      console.log(this.checkFormat, '12312');
    },
    //选择站点进行分类
    chooseCountry() {
      const formatItem = [] as any;
      const tableData = this.tableData;

      this.checkFormat.forEach((item: any, index: any) => {
        this.checkedCities.forEach((listItem: any) => {
          let newTabData: any = {
            haveframe: true,
            notframe: true,
            size: tableData[index].name,
            site_id: '',
            site_name: '',
            price: '',
            frame_price: '',
            status: true,
            sizeid: tableData[index].id,
          };
          newTabData = { ...tableData[index] };
          (newTabData.site_id = listItem),
            (newTabData.site_name = listItem == 1 ? '美国' : '英国'),
            formatItem.push(newTabData);
          // formatItem.splice(index * 2, 0, newTabData);
          if (
            this.tableData.length * this.checkedCities.length ==
            formatItem.length
          ) {
            this.tableData = formatItem;
            return;
          }
        });
      });
      // console.log(itemr, 'this.checkedCities', this.checkedCities);

      // for (let i = 0; i < this.checkedCities.length; i++) {

      // }
    },
    //获取站点列表
    postTemlateSite() {
      postTemlateSite().then(res => {
        this.templateSite = res.data;
        this.checkedCities[0] = res.data[0].id;
        this.checkedCities[1] = res.data[1].id;
      });
    },
    // 编辑拿详情
    async getProductDetails(id: any) {
      const res: any = await getProductDetails(id);
      if (res.code == 200) {
        // 拿详情的id去匹配图片模板，返回索引
        const that = this as any;
        this.ImgList.filter(function(currentValue: any, index: any) {
          if (that.ImgList[index].id == res.data.template_id) {
            that.cvhexk = index;
          }
        });
        this.productTemplate.chinese_name = res.data.chinese_name;
        this.productTemplate.english_name = res.data.english_name;
        this.productTemplate.category = res.data.category;
        this.productTemplate.texture = res.data.texture;
        this.productTemplate.sku = res.data.sku;
        this.productTemplate.shipments_type = res.data.shipments_type;
        // this.productStatus = res.data.status == 1 ? '启用' : '禁用';
        this.productStatus = res.data.status;
        this.tableData = res.data.standard;

        ////获取之前选中的国家
        // const obj = {} as any;
        // this.checkedCities = res.data.standard.reduce((cur: any, next: any) => {
        //   const nowFormat = {
        //     id: next.site_id,
        //     name: next.site_name,
        //   };
        //   this.formatList.indexOf(nowFormat);
        //   obj[next.site_id]
        //     ? ''
        //     : (obj[next.site_id] = true && cur.push(next.site_id));
        //   return cur;
        // }, []); //设置cur默认类型为数组，并且初始值为空的数组

        // const testRuel = res.data.standard.filter((element: any) => {
        //   // if (element.site_name == '美国') {
        //   //   return element;
        //   // }
        //   return element.site_name == '美国';
        // });
        // const newIndex = res.data.standard.findIndex((item: any) => {
        //   return item.site_name == '英国';
        // });
        // console.log(newIndex, 'testRuel');
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].haveframe =
            this.tableData[i].haveframe == '1' ? true : false;
          this.tableData[i].notframe =
            this.tableData[i].notframe == '1' ? true : false;
          this.tableData[i].status =
            this.tableData[i].status == '1' ? true : false;
        }
      }
    },
    //添加商品模板
    goodsTemplateAdd() {
      (this.$refs['productTemplate'] as any).validate((valid: any) => {
        if (valid) {
          if (!this.id) {
            const data = {
              ...this.productTemplate,
              // status: this.productStatus == '启用' ? '1' : '0',
              status: this.productStatus,
              specification: JSON.stringify(this.tableData),
              template_id: this.ImgData.id,
            };
            getGoodsTemplateAdd(data).then(res => {
              console.log(res, 'res', data);
              this.$router.go(-1);
            });
          } else {
            const data = {
              id: this.id,
              ...this.productTemplate,
              // status: this.productStatus == '启用' ? '1' : '0',
              status: this.productStatus,
              specification: JSON.stringify(this.tableData),
              template_id: this.ImgData.id,
            };
            getEditProduct(data).then(res => {
              console.log(res, 'res', data);
              this.$router.go(-1);
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //删除尺寸列表项
    async deleteRow(index: any, selectDeta: any) {
      if (selectDeta.id) {
        const res: any = await getDeleteSpecification(selectDeta.id);
        if (res.code == 200) {
          this.tableData.splice(index, 1);
        }
      } else {
        this.tableData.splice(index, 1);
      }

      // const selctIndex = this.tableData.findIndex((item: any) => {
      //   //取消选中时删除规格项
      //   return item.sizeid == selectDeta.id;
      // });
      // this.formatList[selctIndex].select = false;
    },
    //获取尺寸列表
    getTemplateSize() {
      getTemplateSize().then(res => {
        this.formatList = res.data;
      });
    },
    //新的选中和未选中
    newSelectFormat(index: number, formatItem: any) {
      const selctIndex = this.tableData.findIndex((item: any) => {
        //取消选中时删除规格项
        return item.sizeid == formatItem.id;
      });
      // this.checkFormat = this.checkFormat.push(formatItem);
      console.log(formatItem, 'selctIndex', this.checkFormat);
      if (selctIndex == -1) {
        // this.checkFormat.push(formatItem);
        this.checkedCities.map((item: any) => {
          const newTabData: any = {
            haveframe: true,
            notframe: true,
            size: formatItem.name,
            site_id: '',
            site_name: '',
            price: '',
            frame_price: '',
            status: true,
            sizeid: formatItem.id,
          };
          (newTabData.site_id = item),
            (newTabData.site_name = item == 1 ? '美国' : '英国'),
            this.tableData.push(newTabData);
        });
      }
      // } else {
      //   const selctIndex = this.tableData.findIndex(item => { //取消选中时删除规格项
      //     return item.size == formatItem.value;
      //   });
      //   this.tableData.splice(selctIndex, 1);
    },
    //选中和未选中
    selectFormat(index: number, formatItem: any) {
      const newTabData: any = {
        haveframe: true,
        notframe: true,
        size: formatItem.name,
        price: '',
        frame_price: '',
        status: true,
        sizeid: formatItem.id,
      };
      const selctIndex = this.tableData.findIndex((item: any) => {
        //取消选中时删除规格项
        return item.sizeid == formatItem.id;
      });
      if (formatItem.select && selctIndex == -1) {
        this.tableData.push(newTabData);
      } else {
        // const selctIndex = this.tableData.findIndex(item => { //取消选中时删除规格项
        //   return item.size == formatItem.value;
        // });
        // this.tableData.splice(selctIndex, 1);
      }
    },
    //添加规格项
    addFormat() {
      if (this.newFormatName.length <= 0) {
        return;
      }
      getTemplateAddSize({ name: this.newFormatName }).then((res: any) => {
        const newFprmate: any = res.data;
        this.formatList.push(newFprmate);
      });
    },
    //删除规格列表项
    deletFormat(index: number, formatItem: any) {
      getTemplateDeletSize(formatItem.id).then(() => {
        this.formatList.splice(index, 1);
        // if (formatItem.select) {
        //   //如果产品规格列表中有当前规则 则一起删除掉
        //   const selctIndex = this.tableData.findIndex(item => {
        //     return item.format == formatItem.id;
        //   });
        //   this.tableData.splice(selctIndex, 1);
        // }
      });
    },
    //  图片模板请求
    async getPictureTemplate() {
      const res: any = await getPictureTemplate();
      if (res.code == 200) {
        this.ImgList = res.data;
        this.ImgData = this.ImgList[this.cvhexk];
      }
    },
  },
  watch: {
    cvhexk() {
      this.ImgData = this.ImgList[this.cvhexk];
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"tac"},[_c('el-col',{attrs:{"span":12}},[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"default-active":_vm.index},on:{"open":_vm.handleOpen,"close":_vm.handleClose,"select":_vm.currentIndex}},[_c('el-menu-item',{staticClass:"over",attrs:{"index":"1"},on:{"click":function($event){return _vm.getJump(0)}}},[_c('i',{staticClass:"el-icon-document",class:{ iconImg1: _vm.index == '1' }}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("素材库")])]),_c('el-submenu',{class:{ additionTilte: _vm.indexAddition == '0' },attrs:{"index":"2"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-location",class:{ iconImg3: _vm.indexAddition == '0' }}),_c('span',[_vm._v("设计")]),(_vm.blackLower1 == 0 && _vm.indexAddition != '0')?_c('img',{staticClass:"bar-img",attrs:{"src":require("@/assets/images/9 (1).png"),"alt":""}}):_vm._e(),(_vm.blackLower1 == 1 && _vm.indexAddition != '0')?_c('img',{staticClass:"bar-img",attrs:{"src":require("@/assets/images/heise.png"),"alt":""}}):_vm._e(),(_vm.blackLower1 == 0 && _vm.indexAddition == '0')?_c('img',{staticClass:"bar-img",attrs:{"src":require("@/assets/images/baise.png"),"alt":""}}):_vm._e(),(_vm.blackLower1 == 1 && _vm.indexAddition == '0')?_c('img',{staticClass:"bar-img",attrs:{"src":require("@/assets/images/8 (3).png"),"alt":""}}):_vm._e()]),_c('el-menu-item-group',[_c('el-menu-item',{staticClass:"pull-over",class:{
              addition: _vm.index == '2-1',
            },attrs:{"index":"2-1"},on:{"click":function($event){return _vm.getJump(1)}}},[_vm._v("我的产品库")]),_c('el-menu-item',{staticClass:"pull-over",class:{
              addition: _vm.index == '2-2',
            },attrs:{"index":"2-2"},on:{"click":function($event){return _vm.getJump(2)}}},[_vm._v("设计记录")])],1)],2),_c('el-submenu',{class:{ additionTilte: _vm.indexAddition == '3' },attrs:{"index":"3"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-location",class:{ iconImg3: _vm.indexAddition == '3' }}),_c('span',[_vm._v("发布")]),(_vm.blackLower2 == 0 && _vm.indexAddition != '3')?_c('img',{staticClass:"bar-img",attrs:{"src":require("@/assets/images/9 (1).png"),"alt":""}}):_vm._e(),(_vm.blackLower2 == 1 && _vm.indexAddition != '3')?_c('img',{staticClass:"bar-img",attrs:{"src":require("@/assets/images/heise.png"),"alt":""}}):_vm._e(),(_vm.blackLower2 == 0 && _vm.indexAddition == '3')?_c('img',{staticClass:"bar-img",attrs:{"src":require("@/assets/images/baise.png"),"alt":""}}):_vm._e(),(_vm.blackLower2 == 1 && _vm.indexAddition == '3')?_c('img',{staticClass:"bar-img",attrs:{"src":require("@/assets/images/8 (3).png"),"alt":""}}):_vm._e()]),_c('el-menu-item-group',[_c('el-menu-item',{staticClass:"pull-over",class:{
              addition: _vm.index == '3-1' || _vm.$route.path == '/management',
            },attrs:{"index":"3-1"},on:{"click":function($event){return _vm.getJump(3)}}},[_vm._v("成品管理")]),_c('el-menu-item',{staticClass:"pull-over",class:{
              addition: _vm.index == '3-2' || _vm.$route.path == '/product',
            },attrs:{"index":"3-2"},on:{"click":function($event){return _vm.getJump(4)}}},[_vm._v("产品模板列表")]),_c('el-menu-item',{staticClass:"pull-over",class:{
              addition: _vm.index == '3-3' || _vm.$route.path == '/application',
            },attrs:{"index":"3-3"},on:{"click":function($event){return _vm.getJump(5)}}},[_vm._v("条形码管理")]),_c('el-menu-item',{staticClass:"pull-over",class:{
              addition: _vm.index == '3-4' || _vm.$route.path == '/application',
            },attrs:{"index":"3-4"},on:{"click":function($event){return _vm.getJump(6)}}},[_vm._v("汇出记录")]),_c('el-menu-item',{staticClass:"pull-over",class:{
              addition: _vm.index == '3-5' || _vm.$route.path == '/application',
            },attrs:{"index":"3-5"},on:{"click":function($event){return _vm.getJump(7)}}},[_vm._v("违禁词管理")]),_c('el-menu-item',{staticClass:"pull-over",class:{
              addition: _vm.index == '3-6' || _vm.$route.path == '/application',
            },attrs:{"index":"3-6"},on:{"click":function($event){return _vm.getJump(8)}}},[_vm._v("违规图片管理")])],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
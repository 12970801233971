













































































































































import Vue from 'vue';
import {
  getPropertyList,
  getAddProperties,
  getEditProperties,
  getAttributeDeletion,
} from '@/api';
export default Vue.extend({
  data() {
    return {
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 0,
      // 选中的数据，用于批量删除
      selectDate: [],
      // 新增用户
      form: {
        // 属性code
        attributeCode: '',
        // 属性名称
        attributeName: '',
        // 备注
        remarks: '',
      },
      // 新增用户的验证
      rules: {
        attributeCode: [
          {
            required: true,
            message: '请输入属性code(字母、数字和下划线_及破折号-)',
            trigger: 'change',
          },
          //  { validator: checkUsername, trigger: "blur" }
        ],
        attributeName: [
          { required: true, message: '请输入属性名称', trigger: 'blur' },
        ],
        remarks: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },
      // 弹窗的显示与隐藏
      dialogFormVisible: false,
      title: '新增属性',
      page: 1,
      id: '',
    };
  },
  mounted() {
    this.getPropertyList();
  },
  methods: {
    // 属性模板请求列表
    async getPropertyList() {
      const limit = '10';
      const page = this.page;
      const res: any = await getPropertyList(limit, page);
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },
    // 新增接口
    async getAddProperties() {
      const data = {
        code: this.form.attributeCode,
        name: this.form.attributeName,
        remark: this.form.remarks,
        role: '1',
      };
      const res: any = await getAddProperties(data);
      if (res.code == 200) {
        this.dialogFormVisible = false;
        this.$router.go(0);
      }
    },
    // 编辑接口
    async getEditProperties() {
      const data = {
        id: this.id,
        code: this.form.attributeCode,
        name: this.form.attributeName,
        remark: this.form.remarks,
      };
      const res: any = await getEditProperties(data);
      if (res.code == 200) {
        this.dialogFormVisible = false;
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getPropertyList();
      }
    },
    // 选中
    handleSelectionChange(val: any) {
      console.log(5241, val);
      this.selectDate = val;
    },
    // 清空选择
    toggleSelection() {
      // console.log(this.$refs.multipleTable);
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getPropertyList();
      console.log(466, val);
    },
    // 批量删除
    async getBatchDelete() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      const res: any = await getAttributeDeletion(id.toString());
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getPropertyList();
      }
    },
    // 删除
    async handleDelete(id: any) {
      const res: any = await getAttributeDeletion(id);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getPropertyList();
      }
    },
    // 删除按钮
    handleClose(row: any) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id);
          })
          .catch(() => {
            console.log('取消删除');
          });
      } else if (this.selectDate.length) {
        this.$confirm('确认批量删除？')
          .then(() => {
            this.getBatchDelete();
          })
          .catch(() => {
            console.log('取消批量删除');
          });
      }
    },
    //  编辑
    getModify(row: any) {
      this.id = row.id;
      this.form.attributeCode = row.code;
      this.form.attributeName = row.name;
      this.form.remarks = row.remark;
      this.title = '编辑属性';
      this.dialogFormVisible = true;
    },
    //弹起添加用户
    getBounce() {
      (this.form.attributeName = ''), (this.form.attributeCode = '');
      this.form.remarks = '';
      this.title = '新增属性';
      this.dialogFormVisible = true;
    },
    //添加用户按钮
    getDialogFormVisible(form: any) {
      (this.$refs[form] as any).validate((valid: any) => {
        if (valid) {
          if (this.title == '新增属性') {
            this.getAddProperties();
          } else {
            this.getEditProperties();
          }
          // console.log(this.form);
        } else {
          console.log('表单为空');
          return false;
        }
      });
    },
    // 每次打开对话框删除form表单
    // getOpen(formName: any) {
    //   this.$nextTick(() => {
    //     (this.$refs[formName] as any).resetFields();
    //   });
    // },
  },
  watch: {
    // 监听删除第二页之后请求上一页的操作
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getPropertyList();
      }
    },
  },
});

















import headPage from '@/components/headPage.vue';
import headTile from '@/components/headTilte.vue';
import superBar from '@/components/superBar.vue';
import client from '@/components/client.vue';
import Vue from 'vue';
export default Vue.extend({
  components: { headPage, headTile, superBar, client },
  data() {
    return {
      // 1为超管，2为区域代理，3为用户端
      stateSidebar: '',
    };
  },
  mounted() {
    // 登录之后的路由
    const userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
    if (userInfo) {
      this.stateSidebar = userInfo.role;
      // 如果有缓存并且是重新打开 就给默认路由
      if (this.$route.path == '/') {
        const stateSidebarPath = [
          '/regionalAgency',
          '/management',
          '/materialLibrary',
        ];
        this.$router.push({
          path: stateSidebarPath[Number(this.stateSidebar) - 1],
        });
      }
    } else {
      this.$router.push({ path: '/sign' });
    }
  },
  methods: {},
});

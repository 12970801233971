








































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
//canvas 绘图
import { fabric } from 'fabric';
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { Loading } from 'element-ui';
import {
  postUploadBase64,
  getSourceMaterialList,
  getLibraryList,
  getImagetemplateparams,
  postLibraryDesign,
  getFolderList,
} from '@/api';

import AWS from 'aws-sdk';
// import { ImageLoader, Loader, LoadingManager } from 'three';

export default Vue.extend({
  data() {
    return {
      selectIndex: -1, // 文件夹一级选中
      openFileIndex: -2, //打开选中的文件
      isFile: false, //是否打开弹窗提示
      parentId: '', //选中的id
      fileList: [] as any, //文件列表

      imgArray: {} as any, //设计成功后的
      imgArrayNum: 0, //记录成功后的数量
      userInfo: '' as any, //储存用户数据
      compound_no: '' as any, //当前保存产生的唯一id
      newListArray: {} as any, //获取
      upLoading: false, //图片上传
      imgPaste: false, //判断图片是否有贴入
      createtime: '', //获取时间筛选
      designPop: false, //批量设计弹窗
      meterialPage: 1, //素材第多少页
      filterParams: {
        createtime: '',
        type: '',
        name: '', //搜索的名字
        order: 'desc',
      } as any, //素材图片筛选
      loadingInstance: null as any, //加载中弹窗遮罩
      selectSizeIndex: 0, //选中的设计规格
      selectFrameIndex: 'unFrame', //选中的设计规格
      selectFbx: null as any, //选中的商品模板序号
      slectPopIndex: 0, //弹窗选中的序号
      //产品库列表
      productionList: [] as any,
      // 产品名称
      productName: '',
      // 类目的选择
      category: '',
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 50,
      // 选中的数据，用于批量删除
      selectDate: [],
      // 新增用户
      form: {
        // 账号名
        accountName: '',
        // 用户名
        userName: '',
        // 手机号码
        phone: '',
        // 密码
        password: '',
        // 到期时间
        expireTime: '',
      },
      drawer: false,
      // 配置选中的数据
      ConfigureDate: [],
      ImgList: [
        {
          img:
            'https://qie-online-sale-qiniu.wsandos.com/uploads/20210115/0d4be3bbc3368f0216531762926e321d.png',
        },
        {
          img:
            'https://qie-online-sale-qiniu.wsandos.com/uploads/20210115/a72c634af5db68f5c2dc4a85f759a8cc.png',
        },
        {
          img:
            'https://qie-online-sale-qiniu.wsandos.com/uploads/20210115/59ed3bca2326846ddf962ef8b3bf8259.png',
        },
        {
          img:
            'https://qie-online-sale-qiniu.wsandos.com/uploads/20210115/13c21597d66dee8575b96494349bf4d3.png',
        },
      ],

      //fabric和canvas
      rectGroup: '' as any, //
      selectBgImgIndex: -1, //当前选中的图片
      selectBgImgCont: { Image: '', name: '' } as any, //当前文件的名字
      photoList: {} as any, //素材库列表
      activeIndex2: '1', //导航下拉
      currentPage4: 1, //当前页
      canvas: {} as any, //fabric使用的canvas
      canvas1: '' as any,
      context: null as any,
      canvasUrl: '', //绘制好的图片

      dialogVisible: false, //弹窗遮罩
      fabricImage: null as any, //添加图片事件
      operatingIndex: 1, //1为设计弹窗 2为列表弹窗
      framType: 3, //1为横向框架 ，2为竖直框架 3 为全屏

      config: {
        canvasState: [] as any,
        currentStateIndex: -1, //可撤回多少步骤
        redoStatus: false, //正在撤销状态
        undoStatus: false, // 正在重做状态
        undoFinishedStatus: 1,
        redoFinishedStatus: 1,
        undoButton: this.$refs.undo, //得不到  在 mounted 得到
        redoButton: this.$refs.redo,
        undeoClick: false, //是否可点击
        redoClick: true, //是否可操作取消撤回 true为无可撤销撤回操作
      },
      //绘制模板图片
      //canvas绘图参数
      targetImg: '' as any, //canvas中的目标函数
      getImageData: true, //可以生产图片
      // container: null as any, //画布
      camera: null as any, //相机视角
      scene: null as any, //创建this.scene
      renderer: null as any, //结构渲染器
      controls: null as any, //自由视角控制
      contTranslate: [-50, 25, 0], //向 x y z 轴平移的距离
      // geometry: null as any,//几何对象
      newImg: '' as any, //图片数组

      haveFarmImg: false, //是否展示框架图片
      lingtParam: {
        leftDown: {
          //向左向下阴影
          targetPosition: [0, 0, -100],
          position: [900, 180, -280], //前后 上下 左右
          far: 1500,
          near: 0.8,
          height: 2040, //上下
          width: 280, //左右
          left: -300,
          right: 1600,
          top: 1600,
          bottom: -300,
        },
        rightDown: {
          //向右向下阴影
          targetPosition: [0, 0, -100], //目标位置
          position: [900, 180, 20], //前后 上下 左右 坐标翻转定位翻转角度
          far: 1500,
          near: 200,
          height: 2010, //上下
          width: 380, //左右
          left: -200,
          right: 1500,
          top: 1600,
          bottom: -300,
        },
      } as any, //平行灯光设置
      c2List: [] as any,
      page: 1,
    };
  },
  mounted() {
    this.getFolderList();
    //处理执行模板
    //执行循环canvas
    this.initRender();
    this.postMaterialList();
    this.getLibraryList();
    this.userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
    //fabric和canvas
    // this.addFavric();
    // this.canvasDataChange(); //监听canvas 事件
  },
  methods: {
    //获取文件夹列表
    async getFolderList() {
      const res: any = await getFolderList();
      if (res.code == 200) {
        this.fileList = res.data;
      }
    },
    //打开二级列表
    openFileList(index: any) {
      this.selectIndex = this.selectIndex == index ? -1 : index;
    },
    //选中文件
    selctFile(index: any, id: any, num: any) {
      this.openFileIndex = index;
      this.selectIndex = num ? index : -1;
      this.isFile = false;

      this.parentId = id;
      this.searchInput();
    },
    onObjectSelected(e: any) {
      //选中目标改变
      this.targetImg = e.target;
    },
    // 关闭时间弹窗
    handleClose() {
      this.designPop = false;
    },
    //批量设计
    batchDesign(row: any) {
      // this.selectFbx = row;
      // this.framType = row.template_id == 2 ? 2 : row.template_id == 1 ? 3 : 1;
      // this.designPop = true;
      // this.newImg = row.image;
      // this.getImagetemplateparams();
      this.$router.push({
        path: '/designPanel',
        query: { id: row.id },
      });
    },
    //文件上传时提示
    onUploadImg() {
      this.upLoading = true;
    },
    //上传报错
    onUpLoadErr(error: any) {
      const msg = JSON.parse(error.message);
      this.$message.error(msg.msg);
    },
    //上传图片到七牛云
    uploadImg(response: any, file: any) {
      this.selectBgImgCont.image = response.data.url;
      this.selectBgImgCont.title = file.name;
      this.upLoading = false;
      this.addBgImg();
    },
    //选择筛选的时间
    chooseTime(val: any) {
      this.createtime = `${val[0] / 1000},${val[1] / 1000}`;
      this.meterialPage = 1;
      this.postMaterialList();
    },
    //大图canvas展示
    bigCanvasPop() {
      this.operatingIndex = 2;
    },
    //返回绘图操作
    backCanvasPop() {
      this.operatingIndex = 1;
    },
    //获取图片模板参数
    getImagetemplateparams() {
      this.c2List = [];
      getImagetemplateparams({ id: this.selectFbx.template_id }).then(res => {
        if (res.data.unFrame) {
          this.c2List = { unFrame: res.data.unFrame, frame: res.data.frame };
          this.framType = res.data.framType;
        } else {
          this.c2List = res.data;
        }
        // this.generateImg();
      });
    },
    //关闭绘图弹窗
    colsPop() {
      this.dialogVisible = false;
    },
    //获取产品库列表
    getLibraryList() {
      const newList = {
        'id|chinese_name': this.productName,
        category: this.category,
      };
      const data = {
        filter: JSON.stringify(newList),
        limit: '10',
        page: this.page,
        op: { 'id|chinese_name': 'like', category: 'like' },
      };
      getLibraryList(data).then(res => {
        this.productionList = res.data;
      });
    },
    //素材输入搜索的值
    searchInput() {
      this.meterialPage = 1;
      this.postMaterialList();
      // if (this.filterParams.name.length > 0) {
      // }
    },

    // 重置按钮
    getReset() {
      this.category = '';
      this.productName = '';
    },
    //选择对应的图片
    slectBgImg(index: any) {
      this.selectBgImgIndex = index;
      this.selectBgImgCont = this.photoList.rows[index];
      this.addBgImg();
    },
    //获取素材库列表
    postMaterialList() {
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '获取素材中...',
      });
      const filte = {
        title: this.filterParams.name,
        type: this.filterParams.type,
        createtime: this.createtime,
      };
      const data = {
        limit: 16,
        parent_id: this.parentId,
        page: this.meterialPage,
        order: this.filterParams.order,
        filter: JSON.stringify(filte),
        op: '{"title":"LIKE","type":"=","createtime":"BETWEEN"}',
        sort: 'createtime',
      };
      getSourceMaterialList(data).then(res => {
        this.photoList = res.data;
        this.loadingInstance.close();
      });
    },
    //确认合成
    async compositieFrame() {
      if (!this.imgPaste) {
        this.$message({
          message: '请先合成图片',
          type: 'warning',
        });
        return;
      }
      this.imgArrayNum = 0; //清零

      const timestamp = new Date().getTime();
      let Num = '';
      for (let i = 0; i < 5; i++) {
        Num += Math.floor(Math.random() * 10);
      }
      this.compound_no = timestamp + Num;
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '图片提交中...',
      });
      this.imgArray = {};
      for (let j = 0; j < 2; j++) {
        const framName = j == 0 ? 'unFrame' : 'frame';
        const nowFbxList: any = this.newImg[framName];
        let imgArrayList: any = [];
        for (let i = 0; i < nowFbxList.length; i++) {
          // const pic = nowFbxList[i].split('base64,')[1];
          const pic = await this.convertBase64UrlToBlob(nowFbxList[i]);
          const url: any = await this.postUploadBase64(pic);
          // imgArrayList = [...imgArrayList, url.url];
          imgArrayList = [...imgArrayList, url];
          if (i == nowFbxList.length - 1) {
            //上传完成后执行
            this.imgArray[framName] = imgArrayList;
            // if (j == 1) {
            //   // this.postLibraryDesign(imgArray);
            // }
            // pathImg= imgArray
          }
        }
      }
    },
    postLibraryDesign(url: any) {
      const data = {
        id: this.selectFbx.id,
        image: JSON.stringify(url),
        image_name: this.selectBgImgCont.title,
        compound_no: this.compound_no,
      };
      postLibraryDesign(data).then(() => {
        this.$message('设计保存成功');
        this.dialogVisible = false;
      });

      this.loadingInstance.close();
    },
    /*2:转bob*/
    /**
     * 将以base64的图片url数据转换为Blob
     * @param base64    用url方式表示的base64图片数据
     * @return blob     返回blob对象
     */
    convertBase64UrlToBlob(base64: any) {
      const type = base64.split(',')[0].match(/:(.*?);/)[1]; //提取base64头的type如 'image/png'
      const bytes = window.atob(base64.split(',')[1]); //去掉url的头，并转换为byte (atob:编码 btoa:解码)

      //处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length); //通用的、固定长度(bytes.length)的原始二进制数据缓冲区对象
      const ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: type });
    },

    //上传base64图片到aw s3 亚马逊上
    postUploadBase64(file: any) {
      return new Promise((resolve, reject) => {
        const nowTime = new Date().getTime();
        let Num = '';
        for (let i = 0; i < 5; i++) {
          Num += Math.floor(Math.random() * 10);
        }
        const timestamp = nowTime + Num;
        const key = 'AKIA5LDVNQFTN4FVXCHR';
        const secret = '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';
        const s3 = new AWS.S3({
          accessKeyId: key,
          secretAccessKey: secret,
          region: 'cn-north-1',
        });
        const params = {
          Bucket: 'yijiang',
          Key: this.userInfo.id + timestamp,
          Body: file,
          ACL: 'public-read',
          // 'Access-Control-Allow-Credentials': '*',
          ContentEncoding: 'base64',
          ContentType: 'image/jpeg',
        };
        //putObject
        s3.putObject(params, (err: any) => {
          // console.log(err, data);
          if (err) {
            reject(err);
          } else {
            this.imgArrayNum += 1;
            //判断是否处理完成
            if (this.imgArrayNum == 10) {
              console.log(this.imgArrayNum);
              this.postLibraryDesign(this.imgArray);
            }
          }
        });
        const newUrl =
          'https://yijiang.s3.cn-north-1.amazonaws.com.cn/' + params.Key;
        console.log(newUrl);
        resolve(newUrl);
      });
    },

    //上传base4的图片接口
    postUploadBase64A(file: any) {
      return new Promise((resolve, reject) => {
        postUploadBase64({ file, compound_no: this.compound_no })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    // 搜索框
    getLookup() {
      this.page = 1;
      this.getLibraryList();
    },
    // 选中
    handleSelectionChange(val: any) {
      this.selectDate = val;
    },
    // 设计按钮
    handleDelete(row: any) {
      this.selectFbx = row;
      if (row.image.unFrame) {
        this.framType = row.template_id == 2 ? 2 : row.template_id == 1 ? 3 : 1;
      }
      this.dialogVisible = true;
      this.newImg = row.image;
      this.getImagetemplateparams();
      setTimeout(() => {
        this.addFavric();
        this.canvasDataChange(); //监听canvas 事件
      }, 200);
    },
    // 编辑
    getModify() {
      this.drawer = true;
    },
    // 清空选择
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    handleCurrentChange1(val: any) {
      this.page = val;
      this.getLibraryList();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.meterialPage = val;
      this.postMaterialList();
    },
    //批量设计
    getBounce() {
      console.log(65465, 'this.selectDate');
    },
    // 配置设计
    handleConfigure(val: any) {
      this.ConfigureDate = val;
    },
    // 确定配置
    getEditBtn() {
      this.drawer = false;
    },
    objectSpanMethod({ rowIndex, columnIndex }: any) {
      if (columnIndex === 4) {
        if (rowIndex % this.tableData.length === 0) {
          return {
            rowspan: this.tableData.length,
            colspan: this.tableData.length,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },

    //fabri和canvas

    //每页多少条
    handleSizeChange(val: any) {
      console.log(`每页 ${val} 条`);
    },
    //下拉选择
    handleSelect(key: any, keyPath: any) {
      if (keyPath[0] == 1) {
        return;
      }
      if (keyPath[0] == 3) {
        this.filterParams = {
          createtime: '',
          type: '',
          name: '', //搜索的名字
          order: 'desc',
        };
        this.createtime = '';
      } else {
        this.filterParams[`${keyPath[0]}`] = key;
      }
      this.meterialPage = 1;
      this.postMaterialList();
    },
    // 记录事件
    canvasDataChange() {
      this.canvas.on('object:modified', () => {
        this.updateCanvasState();
      });
      this.canvas.on('object:added', () => {
        this.updateCanvasState();
      });
      this.canvas.on('object:removed', () => {
        this.updateCanvasState();
      });
      this.canvas.on('object:rotating', () => {
        this.updateCanvasState();
      });

      this.canvas.on('selection:updated', (e: any) => {
        this.onObjectSelected(e);
      });
      this.canvas.on('selection:created', (e: any) => {
        this.onObjectSelected(e);
      });
      this.canvas.on('selection:cleared', () => {
        this.targetImg = '';
      });

      // this.canvas.renderAll();
    },
    // 产生farbic的历史操作记录
    updateCanvasState() {
      if (this.config.undoStatus == false && this.config.redoStatus == false) {
        const jsonData = this.canvas.toJSON();
        const canvasAsJson = JSON.stringify(jsonData);
        if (
          this.config.currentStateIndex <
          this.config.canvasState.length - 1
        ) {
          const indexToBeInserted = this.config.currentStateIndex + 1;
          this.config.canvasState[indexToBeInserted] = canvasAsJson;
          const numberOfElementsToRetain = indexToBeInserted + 1;
          this.config.canvasState = this.config.canvasState.splice(
            0,
            numberOfElementsToRetain
          );
        } else {
          this.config.canvasState.push(canvasAsJson);
        }
        this.config.currentStateIndex = this.config.canvasState.length - 1;
      }
    },

    redo() {
      //取消准备操作
      if (this.config.redoClick) {
        return;
      }
      if (this.config.redoFinishedStatus) {
        if (
          this.config.currentStateIndex == this.config.canvasState.length - 1 &&
          this.config.currentStateIndex != -1
        ) {
          // this.config.redoButton.disabled= true;
          this.config.redoClick = true;
        } else {
          if (
            this.config.canvasState.length > this.config.currentStateIndex &&
            this.config.canvasState.length != 0
          ) {
            this.config.redoFinishedStatus = 0;
            this.config.redoStatus = true;
            this.canvas.loadFromJSON(
              this.config.canvasState[this.config.currentStateIndex + 1],
              () => {
                // const jsonData = JSON.parse(
                //   this.config.canvasState[this.config.currentStateIndex + 1]
                // );
                this.canvas.renderAll();
                this.config.redoStatus = false;
                this.config.currentStateIndex += 1;
                if (this.config.currentStateIndex != -1) {
                  //    this.config.redoButton.disabled = false;

                  this.config.redoClick = false;
                }
                this.config.redoFinishedStatus = 1;
                if (
                  this.config.currentStateIndex ==
                    this.config.canvasState.length - 1 &&
                  this.config.currentStateIndex != -1
                ) {
                  // this.config.redoButton.disabled= true;

                  this.config.redoClick = true;
                }
              }
            );
          }
        }
      }
    },
    undo() {
      //撤消操作事件
      if (this.config.currentStateIndex == -1) {
        return;
      }
      if (this.config.undoFinishedStatus) {
        if (this.config.currentStateIndex == -1) {
          this.config.undoStatus = false;
        } else {
          if (this.config.canvasState.length >= 1) {
            this.config.undoFinishedStatus = 0;
            if (this.config.currentStateIndex != 0) {
              this.config.undoStatus = true;
              this.canvas.loadFromJSON(
                this.config.canvasState[this.config.currentStateIndex - 1],
                () => {
                  // const jsonData = JSON.parse(
                  //   this.config.canvasState[this.config.currentStateIndex - 1]
                  // );
                  this.canvas.renderAll();
                  this.config.undoStatus = false;
                  this.config.currentStateIndex -= 1;
                  // this.config.undoButton.removeAttribute("disabled");
                  // this.config.undoButton.disabled = false;

                  this.config.undeoClick = false;
                  if (
                    this.config.currentStateIndex !==
                    this.config.canvasState.length - 1
                  ) {
                    // this.config.redoButton.removeAttribute('disabled');
                    // this.config.redoButton.disabled = false;

                    this.config.redoClick = false;
                  }
                  this.config.undoFinishedStatus = 1;
                }
              );
            } else if (this.config.currentStateIndex == 0) {
              this.canvas.clear();
              this.config.undoFinishedStatus = 1;
              // this.config.undoButton.disabled= "disabled";
              // this.config.redoButton.removeAttribute('disabled');
              // this.config.redoButton.disabled = false;

              this.config.redoClick = false;
              this.config.currentStateIndex -= 1;
            }
          }
        }
      }
    },
    //剪裁图片
    cutImg() {
      //1为横屏 2为竖屏，3 为全凭截图
      // 把组合设置为选中
      if (this.fabricImage) {
        this.canvas.setActiveObject(this.fabricImage);
      } else {
        this.$message({
          message: '请先选择一张需要合成的图片',
          type: 'warning',
        });
        return;
      }
      // // // 把拆分开的每一个模块进行取消选中状态
      this.canvas.discardActiveObject();
      this.canvas.renderAll();

      const clipcanvas: any = document.getElementById('clipcanvas');
      const canvas: any = document.getElementById('canvas');
      const shadow: number = (canvas.width * 100) / 600; //阴影部分放大缩小后尺寸计算

      const cliptpe = {
        width: this.framType == 2 ? canvas.width - shadow * 2 : canvas.width, /// 600为计算阴影的宽度
        height: this.framType == 1 ? canvas.height - shadow * 2 : canvas.height,
      };
      clipcanvas.width = cliptpe.width;
      clipcanvas.height = cliptpe.height;

      const clipCtx = clipcanvas.getContext('2d');

      const canvasCtx = canvas.getContext('2d');

      const data =
        canvasCtx.getImageData(
          this.framType == 2 ? (canvas.width * 100) / 600 : 0,
          this.framType == 1 ? (canvas.width * 100) / 600 : 0,
          cliptpe.width,
          cliptpe.height
        ) || null; //(area.x,area.y,area.w,area.h); //剪裁图片

      clipCtx.putImageData(data, 0, 0);

      this.canvasUrl = clipcanvas.toDataURL('image/png', 1);

      this.haveFarmImg = true; //开始填入框架图片
      this.newImg = [];
      this.generateImg();
      // clipCtx.drawImage(this.canvas, 500, 500, 500, 500);
    },
    //删除图片
    deletBgImg() {
      this.canvas.remove(this.canvas.getActiveObject()); //删除当前图层图片
    },

    fillImg() {
      //图片自适应填充

      const newObject: any = this.targetImg;
      if (newObject == '') {
        this.$message({
          message: '请先选中图层',
          type: 'warning',
        });
        return;
      }
      const fbxW = this.framType == 2 ? 400 : 600;
      const fbxH = this.framType == 1 ? 400 : 600;

      const scaleFactor = Math.max(
        Math.min(fbxW / newObject.width),
        Math.min(fbxH / newObject.height)
      );
      newObject.scale(scaleFactor);
      (newObject.left =
        this.framType == 2
          ? 100 - (newObject.width * scaleFactor - 398) / 2
          : -(newObject.width * scaleFactor - 600) / 2), //剧中计算
        (newObject.top =
          this.framType == 1
            ? 100 - (newObject.height * scaleFactor - 400) / 2
            : -(newObject.height * scaleFactor - 600) / 2), //剧中计算,
        this.canvas.renderAll();
    },
    //添加背景图片
    addBgImg() {
      const image = new Image();
      const url = this.selectBgImgCont.image;
      const newurl =
        url.indexOf('https://qie-online-sale-qiniu.wsandos.com') != -1
          ? 'https://qie-online-sale-qiniu.wsandos.com'
          : 'https://yzt.s3.ap-southeast-1.amazonaws.com';
      const reg = new RegExp(newurl);
      const strImg = url.replace(reg, 'http://amazon.wsandos.com');

      image.src = strImg; // this.getBase64Image(this.selectBgImgCont.image);

      image.crossOrigin = 'Anonymous';
      image.onload = () => {
        //首先获取image的属性

        const fbxW = this.framType == 2 ? 400 : 600;
        const fbxH = this.framType == 1 ? 400 : 600;
        const scaleFactor = Math.min(
          Math.min(fbxW / image.width),
          Math.min(fbxH / image.height)
        );
        this.fabricImage = new fabric.Image(image, {
          left:
            this.framType == 2
              ? 99 - (image.width * scaleFactor - 400) / 2
              : -(image.width * scaleFactor - 600) / 2, //剧中计算
          top:
            this.framType == 1
              ? 99 - (image.height * scaleFactor - 400) / 2
              : -(image.height * scaleFactor - 600) / 2, //剧中计算,
          width: image.width,
          height: image.height,
          scaleX: scaleFactor,
          //取短边展示
          scaleY: scaleFactor,
        });

        this.fabricImage.set({
          //可设置边框的属性
          transparentCorners: true, //
          cornerColor: 'blue',
          cornerStrokeColor: 'red',
          borderColor: 'red',
          // cornerSize: 12,
          // padding: 10,
          cornerStyle: 'triangle',
          borderDashArray: [3, 3],
        });
        this.canvas.add(this.fabricImage);
        this.canvas.setActiveObject(this.fabricImage);
        // this.rectGroup.addWithUpdate(this.fabricImage);
        // 把组合设置为选中
        // this.canvas.setActiveObject(this.fabricImage);
        // 把选中的组合 进行拆分组
        // this.canvas.getActiveObject().toActiveSelection();
        // // 把拆分开的每一个模块进行取消选中状态
        // this.canvas.discardActiveObject();
        // 重新渲染
        this.canvas.renderAll();
      };
      // this.canvas.renderAll();
    },
    //创建fabric
    addFavric() {
      this.canvas = new fabric.Canvas('canvas');
      this.canvas.renderAll();
    },

    /**
     * 创建渲染器对象
     */
    initRender() {
      this.renderer = new THREE.WebGLRenderer({
        //不支持线的宽度 CanvasRenderer才支持线的宽度
        antialias: true,
        alpha: true,
        // precision: "highp"
      });
      this.renderer.shadowMap.enabled = true; //开启阴影，加上阴影渲染
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; //定义阴影贴图类型
    },
    /**
     * 创建场景对象this.scene
     */
    initScene() {
      this.scene = new THREE.Scene();
    },

    /**
     * 相机设置
     */
    initCamera() {
      // this.camera = new THREE.PerspectiveCamera(90, 1, 0.1, 3000); //fov, aspect, near, far
      // this.camera.position.set(100, 0, 0); //设置相机位置 x y z
      this.camera = new THREE.OrthographicCamera(0, 1000, 1000, 0, 1, 3000); //x 图片x 图片y y (left right top botom near far)
      this.camera.position.set(400, 0, 0); //设置相机位置 x y z
      this.camera.lookAt(this.scene.position); //设置相机方向(指向的场景对象) 默认为坐标原点（x:0，y:0，z：0）
    },
    /**
     * 光源设置
     */
    initLight(direction: any) {
      //环境光
      /*Lights*/
      const boardParam: any = this.lingtParam[direction];
      const [positionx, positiony, positionz] = boardParam.position;
      const [targetX, targetY, targetZ] = boardParam.targetPosition;

      const ambient = new THREE.AmbientLight(0x999999); //444444
      // ambient.castShadow = true;
      this.scene.add(ambient);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.4); //平行光
      directionalLight.position.set(positionx, positiony, positionz);

      directionalLight.castShadow = true;
      directionalLight.receiveShadow = true;
      this.scene.add(directionalLight);
      directionalLight.shadow.camera.far = boardParam.far; //产生阴影的最远距离
      directionalLight.shadow.camera.left = boardParam.left; //产生阴影距离位置的最左边位置
      directionalLight.shadow.camera.right = boardParam.right; //最右边
      directionalLight.shadow.camera.top = boardParam.top; //最上边
      directionalLight.shadow.camera.bottom = boardParam.bottom; //最下面
      //这两个值决定使用多少像素生成阴影 默认512
      directionalLight.shadow.mapSize.height = boardParam.height;
      directionalLight.shadow.mapSize.width = boardParam.width;

      directionalLight.shadow.camera.near = boardParam.near; //远近
      const targetObject = new THREE.Object3D(); //灯光目标点
      targetObject.position.set(targetX, targetY, targetZ);
      this.scene.add(targetObject);
      directionalLight.target = targetObject;
    },

    async generateImg() {
      //生成图片
      // this.container = document.getElementById('container');
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '拼命处理中',
      });
      const imgArray: any = {};
      for (let j = 0; j < 2; j++) {
        const framName = j == 0 ? 'unFrame' : 'frame';
        const nowFbxList: any = this.c2List[framName];
        let imgArrayList: any = [];
        for (let i = 0; i < nowFbxList.length; i++) {
          this.scene = null;
          this.camera = null;
          // THREE.Cache.clear();
          this.initScene();
          this.initCamera();
          this.initLight(nowFbxList[i].direction);
          const nowImg = await this.initModel(nowFbxList[i], this.canvasUrl);
          imgArrayList = [...imgArrayList, nowImg];
          if (i == nowFbxList.length - 1) {
            imgArray[framName] = imgArrayList;
            if (j == 1) {
              this.newImg = imgArray;
              this.imgPaste = true;
              this.loadingInstance.close();
            }
          }
        }
      }
    },
    async generateListImg(num: number) {
      //批量处理图片
      //生成图片
      // this.container = document.getElementById('container');
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '拼命处理中',
      });
      let imgArray: any = [];
      for (let i = 0; i < this.c2List.length; i++) {
        this.scene = null;
        this.camera = null;
        // THREE.Cache.clear();
        this.initScene();
        this.initCamera();
        this.initLight(this.c2List[i].direction);
        const nowImg = await this.initModel(
          this.c2List[i],
          this.photoList.rows[num].image
        );
        imgArray = [...imgArray, nowImg];
        if (i == this.c2List.length - 1) {
          this.newListArray[num] = imgArray;
          if (num < 9) {
            ++num;
            this.generateListImg(num);
          } else {
            this.imgPaste = true;
            this.loadingInstance.close();
          }
        }
      }
    },
    //批量处理图片
    cutListImg() {
      this.haveFarmImg = true;
      this.generateListImg(0);
    },

    //加载纹理图片
    textureImg(img: any) {
      return new Promise(resolve => {
        // TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(img, texture => {
          const material4 = new THREE.MeshLambertMaterial({
            // visible: true, // ：是否可见，默认为 true
            map: texture,
          });
          resolve(material4);
        });
      });
    },
    //创建立体对象
    async initModel(boardParam: any, canvasUrl: any) {
      //背景图片纹理
      const material4 = await this.textureImg(boardParam.bgImg);
      //框架图片纹理
      const material = await this.textureImg(canvasUrl);

      return new Promise(resolve => {
        this.renderer.setSize(1600, 1600); //像素设置
        const fbxLoader = new FBXLoader();
        fbxLoader.load(boardParam.fbx, object => {
          // object.scale.set(0.5, 0.5, 0.5); //xyz缩放
          object.castShadow = boardParam.haveShadow; //开启灯光投射阴影
          object.receiveShadow = boardParam.haveShadow; //开启接受阴影
          object.traverse((child: any) => {
            if (child.name == '灯光_2') {
              //背景墙
              child.visible = false; //开启灯光投射阴影/
            }

            if (child.name == '平面') {
              child.castShadow = boardParam.haveShadow; //开启灯光投射阴影
              child.receiveShadow = boardParam.haveShadow; //开启接受阴影
              // if (boardParam.haveBgImg) {
              child.material = material4;

              // }

              if (child.children[0].name == '立方体') {
                //背景墙
                child.children[0].castShadow = boardParam.haveShadow; //开启灯光投射阴影
                child.children[0].receiveShadow = boardParam.haveShadow; //开启接受阴影

                // child.children[0].scale.set(1.2, 1.2, 1.2); //xyz缩放
                if (this.haveFarmImg) {
                  child.children[0].material = material;
                }
              }
            }
          });

          this.scene.add(object);
          this.renderer.render(this.scene, this.camera);
          resolve(this.renderer.domElement.toDataURL('image/png', 1.0));

          // this.renderer.clear(); //清除缓存区
          // this.renderer.clearDepth(); //清除深度缓存区
          // this.scene.remove(this.scene.children);
        });
      });
    },
  },
  watch: {},
});






































































































































































































































































































































import Vue from 'vue';
import {
  getApprovalLsit,
  getDisagree,
  getdeleteUser,
  getrEgionList,
  getAddUser,
  getuserTime,
  getBatchList,
} from '@/api';
export default Vue.extend({
  data() {
    // 判断是否存在改用户名
    // const checkUsername = (rule: any, value: any, callback: any) => {
    //   // if (value != "") {
    //   // 请求接口
    //   //   if (this.usernameRules()) {
    //   //     callback(new Error("用户名已存在"));
    //   //   }
    //   //   callback();
    //   // }
    // };
    // 判断手机号码
    const checkPhone = (rule: any, value: any, callback: any) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
        // console.log(reg.test(value));
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      expireTimeOption: {
        disabledDate(date: any) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      restaurants: [],
      // 批量审合选择的数量
      subordinateLength: 0,
      dialogDetails: false,
      // 设置时间的弹框
      setTime: false,
      // 状态
      userState: '',
      // 区域代理
      accountNumber: '',
      // 用户名称
      userName: '',
      // 手机号码
      phoneNumber: '',
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 0,
      // 选中的数据，用于批量删除
      selectDate: [] as any,
      // 新增用户
      form: {
        // 账号名
        accountName: '',
        // 用户名
        userName: '',
        // 手机号码
        phone: '',
        // 密码
        password: '',
        // 申请到期时间
        expireTime: '',
        // 修改的到期时间
        dueTime: '',
        // 使属代理
        region: '',
      },
      // 新增用户的验证
      rules: {
        accountName: [
          { required: true, message: '请输入账号名', trigger: 'blur' },
          //  { validator: checkUsername, trigger: "blur" }
        ],
        userName: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
          { validator: checkPhone, trigger: 'change' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' },
          {
            min: 8,
            max: 24,
            message: '密码长度在 8 到 24 个字符',
            trigger: 'change',
          },
        ],
        expireTime: [
          { required: true, message: '请输入到期时间', trigger: 'blur' },
        ],
        region: [
          { required: true, message: '请输入所属代理', trigger: 'change' },
        ],
        dueTime: [
          { required: true, message: '请输入到期时间', trigger: 'blur' },
        ],
      },
      // 弹窗的显示与隐藏
      dialogFormVisible: false,
      // 2 通过 0不通过
      radio: '2',
      page: 1,
      // 新增用户区域代理的id
      regionId: '',
      // 通过选择的id
      id: '',
      // 弹窗的显示代理信息
      lookData: {
        name: '',
        realName: '',
        applyTime: '',
      },
    };
  },
  mounted() {
    this.getApprovalLsit();
    this.getrEgionList();
  },
  methods: {
    // 请求区域代理下拉菜单
    async getrEgionList() {
      const res: any = await getrEgionList();
      if (res.code == 200) {
        this.restaurants = res.data;
      }
      console.log(this.restaurants);
    },
    // 用户审核列表请求
    async getApprovalLsit() {
      const filter = {
        username: this.userName,
        mobile: this.phoneNumber,
        audit_status: this.userState,
      };
      const superior_name = this.accountNumber;
      const page = this.page;
      const limit = '10';
      const res: any = await getApprovalLsit(
        JSON.stringify(filter),
        limit,
        page,
        superior_name
      );
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },
    // 重置按钮
    getReset() {
      this.accountNumber = '';
      this.userName = '';
      this.phoneNumber = '';
      this.userState = '';
    },
    // 禁止选中
    checkSelectable(row: any) {
      if (row.audit_status == 1) {
        return true;
      } else {
        return false;
      }
    },
    // 下拉菜单是搜索方法
    querySearch(queryString: any, cb: any) {
      const restaurants = this.restaurants;
      const results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString: any) {
      return (restaurant: any) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect1(item: any) {
      this.accountNumber = item.real_name;
    },
    handleSelect2(item: any) {
      this.form.region = item.real_name;
      this.regionId = item.id;
    },
    // 搜索框
    getLookup() {
      // console.log(this.accountNumber, this.userName, this.phoneNumber);
      this.page = 1;
      this.getApprovalLsit();
    },
    // 选中
    handleSelectionChange(val: any) {
      // console.log(5241, val);
      this.selectDate = val;
    },
    // 操作按钮
    handleDelete(row: any, state: any) {
      this.lookData.name = row.username;
      this.lookData.realName = row.real_name;
      this.lookData.applyTime = (this.$options as any).filters['formatTime'](
        row.apply_time
      );
      if (state == 2) {
        this.form.dueTime = (row.use_time * 1000).toString();
      } else {
        this.form.dueTime = (row.apply_time * 1000).toString();
      }
      this.id = row.id;
      this.setTime = true;
    },
    // 设置时间并且通过
    async getuserTime() {
      const data = {
        id: this.id,
        use_time: Number(this.form.dueTime) / 1000,
      };
      const res: any = await getuserTime(data);
      if (res.code == 200) {
        this.setTime = false;
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getApprovalLsit();
      }
    },
    // 清空选择
    toggleSelection() {
      // console.log(this.$refs.multipleTable);
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getApprovalLsit();
      // console.log(466, val);
    },
    // 批量删除
    getBatchDelete() {
      if (this.selectDate.length) {
        this.subordinateLength = this.selectDate.length;
        this.dialogDetails = true;
      }
      console.log(this.selectDate);
    },
    // 批量的分页
    subordinateChange(val: any) {
      console.log(val);
    },
    //弹起添加用户
    getBounce() {
      this.dialogFormVisible = true;
    },
    // 不通过以后
    getDisagree(row: any) {
      this.$confirm('是否确定改用户不通过？')
        .then(() => {
          getDisagree({ id: row.id }).then((res: any) => {
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.getApprovalLsit();
            }
          });
        })
        .catch(() => {
          console.log('取消重新提交');
        });
    },
    // 删除用户
    getdeleteUser(row: any) {
      this.$confirm('是否确定删除改用户？')
        .then(() => {
          getdeleteUser(row.id).then((res: any) => {
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.getApprovalLsit();
            }
          });
        })
        .catch(() => {
          console.log('取消重新提交');
        });
    },
    //添加用户按钮
    getDialogFormVisible(form: any, num: number) {
      (this.$refs[form] as any).validate((valid: any) => {
        if (valid) {
          if (num == 0) {
            // 新增用户
            this.getAddUser();
          } else {
            this.getuserTime();
          }
        } else {
          console.log('表单为空');
          return false;
        }
      });
    },
    // 新增用户
    async getAddUser() {
      const data = {
        username: this.form.accountName,
        password: this.form.password,
        mobile: this.form.phone,
        real_name: this.form.userName,
        use_time: Number(this.form.expireTime) / 1000,
        role: '3',
        parent_id: this.regionId,
      };
      const res: any = await getAddUser(data);
      if (res.code == 200) {
        this.dialogFormVisible = false;
        this.$router.go(0);
      }
    },
    // 每次打开对话框删除form表单
    getOpen(formName: any) {
      this.$nextTick(() => {
        (this.$refs[formName] as any).resetFields();
      });
    },
    // 取消按钮
    getCancel() {
      this.dialogDetails = false;
      this.setTime = false;
      this.dialogFormVisible = false;
    },
    // 操作是否通过或者不通过
    async getExamine() {
      const data1 = [] as any;
      if (this.radio == '2') {
        Object.keys(this.selectDate).forEach(key => {
          const item = {
            id: this.selectDate[key].id,
            audit_status: this.radio,
            use_time: this.selectDate[key].apply_time,
          };
          data1.push(item);
        });
      } else {
        Object.keys(this.selectDate).forEach(key => {
          const item = {
            id: this.selectDate[key].id,
            audit_status: this.radio,
            apply_time: this.selectDate[key].apply_time,
          };
          data1.push(item);
        });
      }
      const obj = {
        data: JSON.stringify(data1),
      };
      // obj.data = ;
      // const data = obj;
      // console.log(obj);
      const res: any = await getBatchList(obj);
      if (res.code == 200) {
        this.dialogDetails = false;
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getApprovalLsit();
      }
    },
  },
  watch: {
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getApprovalLsit();
      }
    },
  },
  filters: {
    formatTime(time: number) {
      if (time) {
        const date = new Date(time * 1000);
        const Y = date.getFullYear();
        let M = (date.getMonth() + 1).toString();
        let D = date.getDate().toString();
        if (Number(M) < 10) {
          M = '0' + M;
        }
        if (Number(D) < 10) {
          D = '0' + D;
        }
        return Y + '.' + M + '.' + D;
        // if (str == '-') {
        //   return Y + '年' + M + '月' + D + '日';
        // } else {
        //   return Y + '.' + M + '.' + D;
        // }
      }
    },
  },
});

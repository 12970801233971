



































































































































import Vue from 'vue';
import { getProductList, getDeleteProduct, postTemlateSite } from '../api';
export default Vue.extend({
  data() {
    return {
      options: [],
      // 产品名称
      productName: '',
      // 站点
      priceStie: '',
      // 模板类型
      templateType: '',
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 0,
      // 选中的数据，用于批量删除
      selectDate: [],
      page: 1,
      userInfo: {} as any,
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
    this.getProductList();
    this.postTemlateSite();
  },
  methods: {
    // 站点列表
    async postTemlateSite() {
      const res: any = await postTemlateSite();
      if (res.code == 200) {
        // console.log(res.data);
        this.options = res.data;
      }
    },
    // 重置按钮
    getReset() {
      this.productName = '';
      this.priceStie = '';
      this.templateType = '';
    },
    // 获取产品模板列表
    async getProductList() {
      const data = {
        filter: {
          site: this.priceStie,
          name: this.productName,
          type: this.templateType,
        },
        op: {
          name: 'like',
          site: 'like',
        },
        limit: '10',
        page: this.page,
      };
      const res: any = await getProductList(data);
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },
    // 禁止选中
    checkSelectable(row: any) {
      // 判断是什么身份  1是超管 3用户
      if (this.userInfo.role == '1') {
        return true;
      } else if (this.userInfo.role == '3') {
        if (row.user.role != '3') {
          return false;
        } else {
          return true;
        }
      }
    },
    // 搜索框
    getLookup() {
      this.page = 1;
      this.getProductList();
    },
    // 批量删除
    async getBatchDelete() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      const data = {
        id: id.toString(),
      };
      const res: any = await getDeleteProduct(data);
      if (res.code == 200) {
        this.getProductList();
        this.$message({
          message: '删除成功',
          type: 'success',
        });
      }
    },
    // 删除
    async handleDelete(id: any) {
      const data = { id };
      const res: any = await getDeleteProduct(data);
      if (res.code == 200) {
        this.getProductList();
        this.$message({
          message: '删除成功',
          type: 'success',
        });
      }
    },
    // 删除按钮
    handleClose(row: any) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id);
          })
          .catch(() => {
            console.log('取消删除');
          });
      } else if (this.selectDate.length) {
        this.$confirm('确认批量删除？')
          .then(() => {
            this.getBatchDelete();
          })
          .catch(() => {
            console.log('取消批量删除');
          });
      }
    },
    // 选中
    handleSelectionChange(val: any) {
      this.selectDate = val;
    },
    // 复制模板
    getCopyTemplate(row: any) {
      this.$router.push({
        path: '/productGreate',
        query: { id: row.id, type: '3' },
      });
    },
    // 清空
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getProductList();
    },
    // 添加
    getBounce() {
      this.$router.push({ path: '/productGreate', query: { type: '1' } });
    },
    // 编辑
    getModify(row: any) {
      this.$router.push({
        path: '/productGreate',
        query: { id: row.id, type: '2' },
      });
    },
  },
  watch: {
    // 监听删除第二页之后请求上一页的操作
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getProductList();
      }
    },
  },
});

































































import { getCode, getChangePassword } from '@/api';
import Vue from 'vue';
export default Vue.extend({
  data() {
    // 判断是否存在改用户名
    // const checkUsername = (rule: any, value: any, callback: any) => {
    //   // if (value != "") {
    //   // 请求接口
    //   //   if (this.usernameRules()) {
    //   //     callback(new Error("用户名已存在"));
    //   //   }
    //   //   callback();
    //   // }
    // };
    const checkCode = (rule: any, value: any, callback: any) => {
      const that = this as any;
      if (!value) {
        return callback(new Error('请输入验证码'));
      } else {
        if (that.rulesForm.verificationCode == that.codeNum) {
          callback();
        } else {
          return callback(new Error('请重新输入正确的验证码'));
        }
      }
      // if (value === "") {
      //   callback(new Error("请输入验证码"));
      // } else {
      //   if (this.form.verificationCode != this.codeNum) {
      //     console.log(this.form.verificationCode, this.codeNum);
      //     (this.$refs.form as any).validateField("verificationCode");
      //     callback(new Error("请重新输入验证码"));
      //   }
      //   callback();
      // }
    };
    const validatePass = (rule: any, value: any, callback: any) => {
      const that = this as any;
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (that.rulesForm.checkPass !== '') {
          (this.$refs.form as any).validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule: any, value: any, callback: any) => {
      const that = this as any;
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== that.rulesForm.pass) {
        callback(new Error('与新密码输入不一致!'));
      } else {
        callback();
      }
    };
    return {
      codeNum: '',
      // 忘记密码用户信息
      rulesForm: {
        // 旧密码
        accountName: '',
        // 密码
        pass: '',
        // 第二次输入的密码
        checkPass: '',
        // 验证码
        verificationCode: '',
      },
      // 修改用户信息的验证
      rules: {
        accountName: [
          { required: true, message: '请输入旧密码', trigger: 'change' },
          //  { validator: checkUsername, trigger: "change" }
        ],
        verificationCode: [
          {
            required: true,
            validator: checkCode,
            trigger: 'blur',
          },
          {
            min: 4,
            max: 4,
            message: '验证码长度至多为4位数',
            trigger: 'blur',
          },
        ],
        pass: [
          { required: true, validator: validatePass, trigger: 'change' },
          {
            min: 8,
            max: 24,
            message: '密码长度在 8 到 24 个字符',
            trigger: 'change',
          },
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'change' },
          {
            min: 8,
            max: 24,
            message: '密码长度在 8 到 24 个字符',
            trigger: 'change',
          },
        ],
      },
    };
  },
  mounted() {
    this.getCodeNum();
  },
  methods: {
    // 请求验证码
    async getCodeNum() {
      const res: any = await getCode();
      if (res.code == 200) {
        this.codeNum = res.data;
      }
    },
    // 表单的结果
    submitForm(formName: any) {
      (this.$refs[formName] as any).validate((valid: any) => {
        if (valid) {
          this.getChangePassword();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async getChangePassword() {
      const data = {
        old_pwd: this.rulesForm.accountName,
        new_pwd: this.rulesForm.pass,
        again_pwd: this.rulesForm.checkPass,
      };
      const res: any = await getChangePassword(data);
      console.log(res);
      if (res.code == 200) {
        this.$message.success('修改成功');
        this.$router.push({
          path: '/sign',
        });
      }
    },
  },
  watch: {},
});

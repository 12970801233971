






















import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      avatarImg: '',
      // 名字
      username: '',
    };
  },
  mounted() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
    if (userInfo) {
      this.avatarImg = userInfo.avatar;
      this.username = userInfo.username;
    }
  },
  methods: {
    // 修改密码
    getChangePassword() {
      this.$router.push({
        path: '/changePassword',
      });
    },
    //退出登录
    getSignOut() {
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          localStorage.removeItem('userInfo');
          this.$router.push({
            path: '/sign',
          });
        })
        .catch(() => {
          console.log('取消退出登录');
        });
    },
  },
});

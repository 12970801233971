




















































































































































































































































































import Vue from 'vue';
import {
  getRandomCode,
  getGenerateBarcode,
  getBarcodeList,
  getEditBarcode,
  getSettingFailure,
  getCancelInvalidation,
  getBatchFailure,
  getBatchCancel,
} from '@/api';
export default Vue.extend({
  data() {
    // 判断厂家
    const checkPhone = (rule: any, value: any, callback: any) => {
      if (!value) {
        return callback(
          new Error('厂商代码范围：1-9999，在此范围内填写4位厂商代码!')
        );
      } else {
        console.log(value, '545');
        if (value.toString().length == 4) {
          callback();
        } else {
          return callback(
            new Error('厂商代码范围：1-9999，在此范围内填写4位厂商代码!')
          );
        }
      }
    };
    // 判断国家
    const countryCode = (rule: any, value: any, callback: any) => {
      if (!value) {
        return callback(
          new Error('国家代码范围：1-999，在此范围内填写3位厂商代码!!')
        );
      } else {
        console.log(value.length);
        if (value.toString().length == 3) {
          callback();
        } else {
          return callback(
            new Error('国家代码范围：1-999，在此范围内填写3位厂商代码!!')
          );
        }
      }
    };
    return {
      // 状态
      options: [
        {
          id: '2',
          label: '已用',
        },
        {
          id: '1',
          label: '可用',
        },
        {
          id: '0',
          label: '已失效',
        },
      ],
      // 厂家代码
      productName: '',
      // 条形码编码
      barcodeCoding: '',
      // 状态
      stateName: '',
      // 用户管理数据
      tableData: [],
      // 编辑回去的数据
      barCodeList: [],
      // 编辑的总条数
      barCodelegth: 0,
      // 分页请求
      barCodePage: 1,
      // 用户信息
      form: {
        // 国家代码
        countryCode: '',
        // 厂家代码
        manufactorCode: '',
        // 生成数量
        generatedQuantity: '',
      },
      // 修改用户信息的验证
      rules: {
        countryCode: [
          {
            required: true,
            message: '国家代码范围：1-999，在此范围内填写3位厂商代码!',
            trigger: 'change',
          },
          { validator: countryCode, trigger: 'change' },
        ],
        manufactorCode: [
          {
            required: true,
            message: '厂商代码范围：1-9999，在此范围内填写4位厂商代码!',
            trigger: 'change',
          },
          { validator: checkPhone, trigger: 'change' },
        ],
        generatedQuantity: [
          { required: true, message: '生成数量不能为空!', trigger: 'change' },
        ],
      },
      // 弹窗的显示与隐藏
      dialogFormVisible: false,
      // 数据总数
      currentLength: 0,
      // 选中的数据，用于批量删除
      selectDate: [],
      drawer: false,
      // 批量按钮的禁止
      disabled: true,
      // 批量设置的选择的数据
      batchList: [] as any,
      page: 1,
      // 编辑拿到的id
      id: '',
    };
  },
  mounted() {
    this.getBarcodeList();
  },
  methods: {
    // 列表的请求
    async getBarcodeList() {
      const data = {
        filter: JSON.stringify({ factory_code: this.productName }),
        limit: '10',
        page: this.page,
      };
      const res: any = await getBarcodeList(data);
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },
    // 重置按钮
    getReset() {
      this.productName = '';
    },
    getReset2() {
      // 条形码编码
      this.barcodeCoding = '';
      // 状态
      this.stateName = '';
    },
    // 搜索框
    getLookup() {
      // console.log(this.productName);
      this.page = 1;
      this.getBarcodeList();
    },
    // 选中
    handleSelectionChange(val: any) {
      // console.log(5241, val);
      this.selectDate = val;
    },
    // 批量编辑选中
    batchCheck(val: any) {
      console.log(5241, val);
      this.batchList = val;
    },
    // 清空
    toggleSelection() {
      // console.log(this.$refs.multipleTable);
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getBarcodeList();
      // console.log(466, val);
    },
    // 批量编辑的分页
    getPaging(val: any) {
      // console.log(466, val);
      this.barCodePage = val;
      this.getEditBarcode();
    },
    // 添加
    getBounce() {
      this.dialogFormVisible = true;
    },
    // 批量编辑的搜索
    getEditLook() {
      // console.log(this.barcodeCoding, this.stateName);
      this.barCodePage = 1;
      this.getEditBarcode();
    },
    //添加新的条形码按钮
    getDialogFormVisible(form: any) {
      (this.$refs[form] as any).validate((valid: any) => {
        if (valid) {
          this.getGenerateBarcode();
        } else {
          return false;
        }
      });
    },
    // 生成新的条形码
    async getGenerateBarcode() {
      const data = {
        country_code: this.form.countryCode,
        factory_code: this.form.manufactorCode,
        number: this.form.generatedQuantity,
      };
      const res: any = await getGenerateBarcode(data);
      if (res.code == 200) {
        this.dialogFormVisible = false;
        this.$router.go(0);
      }
    },
    // 条形码的编辑
    getSetUp(row: any, num: number, judge: string) {
      if (num == 0) {
        this.$confirm('是否设为失效？')
          .then(() => {
            if (judge == 'outside') {
              this.getPublicMethod(row.id, num);
            } else {
              this.getEditingMethod(row.id, num, row.status);
            }
          })
          .catch(() => {
            console.log('设为失效');
          });
      } else {
        this.$confirm('确认取消失效？')
          .then(() => {
            if (judge == 'outside') {
              this.getPublicMethod(row.id, num);
            } else {
              this.getEditingMethod(row.id, num, row.status);
            }
          })
          .catch(() => {
            console.log('取消失效');
          });
      }
    },
    // 外面的单个设置失效和取消失效
    async getPublicMethod(id: any, num: number) {
      const data = {
        id,
      };
      if (num == 0) {
        const res: any = await getSettingFailure(data);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.getBarcodeList();
        }
      } else {
        const res: any = await getCancelInvalidation(data);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.getBarcodeList();
        }
      }
    },
    // 每次打开对话框删除form表单
    getOpen(formName: any) {
      this.$nextTick(() => {
        (this.$refs[formName] as any).resetFields();
      });
    },
    // 里面的单个设置失效和取消失效
    async getEditingMethod(id: any, num: number, status: any) {
      // console.log(id, num, status);
      const data1 = [
        {
          id: id,
          status: status,
        },
      ];
      const obj = {
        data: JSON.stringify(data1),
      };
      if (num == 0) {
        const res: any = await getBatchFailure(obj);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.getEditBarcode();
        }
      } else {
        const res: any = await getBatchCancel(obj);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.getEditBarcode();
        }
      }
    },
    // 批量的操作
    getBatchSetting(num: number) {
      if (num == 0) {
        this.$confirm('是否批量设为失效？')
          .then(() => {
            this.getPublicBatch(num);
          })
          .catch(() => {
            console.log('设为失效');
          });
      } else {
        this.$confirm('确认批量取消失效？')
          .then(() => {
            this.getPublicBatch(num);
          })
          .catch(() => {
            console.log('取消失效');
          });
      }
    },
    async getPublicBatch(num: number) {
      const data1 = [] as any;
      Object.keys(this.batchList).forEach(key => {
        const item = {
          id: this.batchList[key].id,
          status: this.batchList[key].status,
        };
        data1.push(item);
      });
      const obj = {
        data: JSON.stringify(data1),
      };
      if (num == 0) {
        // 批量设置失效
        const res: any = await getBatchFailure(obj);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.getEditBarcode();
        }
      } else {
        // 批量设置取消失效
        const res: any = await getBatchCancel(obj);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.getEditBarcode();
        }
      }
    },
    // 编辑按钮
    getModify(val: any) {
      this.id = val.id;
      this.getEditBarcode();
    },
    // 编辑拿数据请求
    async getEditBarcode() {
      const data = {
        id: this.id,
        filter: { code: this.barcodeCoding, status: this.stateName },
        op: { code: 'like' },
        page: this.barCodePage,
        limit: '10',
      };
      const res: any = await getEditBarcode(data);
      if (res.code == 200) {
        this.barCodeList = res.data.rows;
        this.barCodelegth = res.data.total;
      }
      this.drawer = true;
    },
    // 取消按钮
    getCancel() {
      this.dialogFormVisible = false;
    },
    // 自动生成代码
    async getAutomatic(number: number) {
      const res: any = await getRandomCode();
      if (res.code == 200) {
        if (number == 1) {
          this.form.countryCode = res.data.country_code;
        } else {
          this.form.manufactorCode = res.data.factory_code;
        }
      }
    },
  },
  watch: {
    batchList() {
      if (this.batchList.length) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
});












































































































































































import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      // 定价站点的数据
      options: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          id: '1',
          label: '双皮奶',
        },
        {
          id: '2',
          label: '蚵仔煎',
        },
        {
          id: '3',
          label: '龙须面',
        },
        {
          id: '4',
          label: '北京烤鸭',
        },
      ],
      // 账号
      templateName: '',
      // 定价站点
      priceStie: '',
      // 用户管理数据
      tableData: [
        {
          id: '1',
          date: '一朵云',
          name: '韩梅梅',
          address: '1890FF',
          password: '12345678',
          time: '至2022.02.15',
        },
        {
          id: '1',
          date: '一朵云',
          name: '韩梅梅',
          address: 'F2637B',
          password: '12345678',
          time: '至2022.02.15',
        },
        {
          id: '1',
          date: '一朵云',
          name: '韩梅梅',
          address: 'F2637B',
          password: '12345678',
          time: '至2022.02.15',
        },
        {
          id: '1',
          date: '一朵云',
          name: '韩梅梅',
          address: 'F2637B',
          password: '12345678',
          time: '至2022.02.15',
        },
        {
          id: '1',
          date: '一朵云',
          name: '韩梅梅',
          address: 'F2637B',
          password: '12345678',
          time: '至2022.02.15',
        },
        {
          id: '1',
          date: '一朵云',
          name: '韩梅梅',
          address: 'F2637B',
          password: '12345678',
          time: '至2022.02.15',
        },
        {
          id: '1',
          date: '一朵云',
          name: '韩梅梅',
          address: 'F2637B',
          password: '12345678',
          time: '至2022.02.15',
        },
        {
          id: '1',
          date: '一朵云',
          name: '韩梅梅',
          address: 'F2637B',
          password: '12345678',
          time: '至2022.02.15',
        },
      ],
      // 数据总数
      currentLength: 50,
      // 选中的数据，用于批量删除
      selectDate: [],
      // 新增用户
      form: {
        // 账号名
        accountName: '',
        // 用户名
        userName: '',
        // 手机号码
        phone: '',
        // 密码
        password: '',
        // 到期时间
        expireTime: '',
      },
      // 新增用户的验证
      // rules: {
      //   accountName: [
      //     { required: true, message: "请输入账号名", trigger: "blur" }
      //     //  { validator: checkUsername, trigger: "blur" }
      //   ],
      //   userName: [
      //     { required: true, message: "请输入用户姓名", trigger: "blur" }
      //   ],
      //   phone: [
      //     { required: true, message: "请输入手机号码", trigger: "change" },
      //     { validator: checkPhone, trigger: "change" }
      //   ],
      //   password: [
      //     { required: true, message: "请输入密码", trigger: "change" },
      //     {
      //       min: 8,
      //       max: 24,
      //       message: "密码长度在 8 到 24 个字符",
      //       trigger: "change"
      //     }
      //   ],
      //   expireTime: [
      //     { required: true, message: "请输入到期时间", trigger: "blur" }
      //   ]
      // },
      // 弹窗的显示与隐藏
      // dialogFormVisible: false
    };
  },
  methods: {
    // 搜索框
    getLookup() {
      console.log(this.templateName, this.priceStie);
    },
    // 选中
    handleSelectionChange(val: any) {
      console.log(5241, val);
      this.selectDate = val;
    },
    // 删除按钮
    handleDelete(row: any) {
      console.log('删除单个', row);
    },
    // 编辑
    getModify(row: any) {
      console.log('编辑', row);
    },
    // 清空选择
    toggleSelection() {
      // console.log(this.$refs.multipleTable);
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      console.log(466, val);
    },
    // 批量删除
    getBatchDelete() {
      console.log(this.selectDate);
    },
    //弹起添加用户
    getBounce() {
      console.log(65465);
    },
    // //添加用户按钮
    // getDialogFormVisible(form: any) {
    //   (this.$refs[form] as any).validate((valid: any) => {
    //     if (valid) {
    //       console.log(this.form);
    //       this.dialogFormVisible = false;
    //     } else {
    //       console.log("表单为空");
    //       return false;
    //     }
    //   });
    // },
    // 每次打开对话框删除form表单
    getOpen(formName: any) {
      this.$nextTick(() => {
        (this.$refs[formName] as any).resetFields();
      });
    },
    // // 取消按钮
    // getCancel() {
    //   this.dialogFormVisible = false;
    // }
  },
  watch: {},
});

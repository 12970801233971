




















































































































































































































































import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      // 用来三元判断
      indexAddition: '',
      // 用户管理默认打开素材库
      index: '',
      blackLower1: 0, //设计弹窗切换
      blackLower2: 0, //发布弹窗切换
      blackLower3: 0, //订单弹窗切换
      // //  下拉菜单选中之后0状态三角形的颜色为白色,1状态为黑色
      // colour1: 0,
      // colour2: 0,
      // // 三角形黑色的变化
      // balckTriangle1: 1,
      // balckTriangle2: 1
    };
  },
  mounted() {
    this.getIdentity();
  },
  methods: {
    getIdentity() {
      const correspondingPath = [
        '/materialLibrary',
        '/productLibrary',
        '/designPanel',
        '/designRecord',
        '/finishedProduct',
        '/product',
        '/productGreate',
        '/barcode',
        '/exportRecord',
        '/prohibitedWords',
        '/pictures',
        '/technicalResults',
        '/changePassword',
        '/orderManage',
        '/shopAdmin',
        '/shopOnline',
      ];
      const strategyIndex = [
        '1',
        '2-1',
        '2-1',
        '2-2',
        '3-1',
        '3-2',
        '3-2',
        '3-3',
        '3-4',
        '3-5',
        '3-6',
        '2-2',
        '-1',
        '4-1',
        '3-7',
        '4-2',
      ];
      console.log(this.$route.path);
      correspondingPath.map((item, index) => {
        if (this.$route.path == item) {
          this.index = strategyIndex[index];
          // console.log(this.index);
        }
      });
      // console.log(this.index);
      this.currentIndex(this.index);
    },
    // 跳转事件
    getJump(index: number) {
      const route = [
        '/materialLibrary',
        '/productLibrary',
        '/designRecord',
        '/finishedProduct',
        '/product',
        '/barcode',
        '/exportRecord',
        '/prohibitedWords',
        '/pictures',
        '/shopAdmin',
        '/shopOnline',
        '/orderManage',
      ];
      this.$router.push({
        path: route[index],
      });
    },
    // 展开的事件
    handleOpen(index: any) {
      // this.indexAddition = index;
      if (index == '2') {
        this.blackLower1 = 1;
      } else if (index == '3') {
        this.blackLower2 = 1;
      } else {
        this.blackLower3 = 1;
      }
    },
    // 收起的事件
    handleClose(index: any) {
      if (index == '2') {
        this.blackLower1 = 0;
      } else if (index == '3') {
        this.blackLower2 = 0;
      }
      {
        this.blackLower3 = 0;
      }
    },
    // 当前点击的index
    currentIndex(index: any) {
      this.index = index;
      console.log(index, '3234');
      if (index == '2-1' || index == '2-2') {
        this.blackLower1 = 1;
        this.indexAddition = '0';
      } else if (index == '1') {
        this.indexAddition = '1';
      } else if (index.indexOf('3') != -1) {
        this.indexAddition = '3';
        this.blackLower2 = 1;
      } else if (index.indexOf('4') != -1) {
        this.indexAddition = '4';
        this.blackLower2 = 1;
      }
    },
  },

  watch: {
    // colour1() {
    //   if (this.colour1 == 1 && this.indexAddition != "0") {
    //     this.balckTriangle1 = 1;
    //     console.log(this.balckTriangle2);
    //   } else {
    //     this.balckTriangle1 = 0;
    //   }
    // },
    // colour2() {
    //   console.log(this.indexAddition);
    //   if (this.colour2 == 1 && this.indexAddition != "2") {
    //     this.balckTriangle2 = 1;
    //     console.log(this.balckTriangle2);
    //   } else {
    //     this.balckTriangle2 = 0;
    //   }
    // },
    // 监听路由的变化
    $route() {
      // console.log(to, from);
      this.getIdentity();
    },
  },
});












































































































































































































































































































































































































import {
  getSourceMaterialList,
  getMaterialEditing,
  getMaterialDelete,
  getMaterialUpload,
  postAddFolder,
  getFolderList,
  getEditFolder,
  getDelFolder,
} from '@/api';
import { Loading } from 'element-ui';
// import { log } from 'node_modules/three/src/polyfills';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      parentId: 0, //选中元素的id
      fileRadio: '新增' as any, //1新增文件夹 2为修改文件
      fileType: 1, //1为新增类型  2为新增 修改  3为修改
      newFileName: '' as any, //修改文件夹的名字
      fileVisible: false, //文件夹弹窗
      selectIndex: -1, // 文件夹一级选中
      openFileIndex: -2, //打开二级文件的一级序号
      isEsternalfile: false, //打开文件夹外部弹窗
      isFile: false, //是文件夹否打开弹窗提示
      fileListTest: [] as any, //文件列表

      uploadFileindex: -2, //上传文件时打开的一级序号
      uploadFileName: '全部', //上传文件时选中的文件名
      uploadFileId: 0, //上传文件时选中的id

      loadingInstance: null as any, //加载中弹窗遮罩

      // 关键词
      keyWord: '',
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 0,
      // 选中的数据，用于批量删除
      selectDate: [] as any,
      // 修改素材名称
      ruleForm: {
        // 素材名称
        oneCycle: [
          {
            materialName: '',
            id: '',
            image: '',
          },
        ],
      } as any,
      // 新增用户的验证
      rules: {
        materialName: [
          { required: true, message: '请输入素材名称', trigger: 'change' },
          //  { validator: checkUsername, trigger: "blur" }
        ],
      },
      // 弹窗的显示与隐藏
      dialogFormVisible: false,
      checked: true,
      // 图片上传
      fileList: [] as any,
      // 是否禁止图片上传
      disabled: false,
      // 编辑是否隐藏
      drawer: false,
      direction: 'rtl',
      // 勾选提示隐藏
      showTip: false,
      // 已上传的图片file文件
      // 分页
      page: 1,
      config: {
        token: '',
      },
      code: [] as any,
      loading: '' as any,
    };
  },
  mounted() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') as any);
    this.config.token = userInfo.token;
    this.getSourceMaterialList();
    this.getFolderList();
  },
  methods: {
    //删除选中的文件
    deletFile(id: any, name: any) {
      this.$confirm('是否永久删除  (' + name + ')  及文件夹中的内容?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.getDelFolder(id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    async getDelFolder(id: any) {
      const res: any = await getDelFolder(id);
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
        this.fileListTest = res.data;
        this.isEsternalfile = false;
      }
    },
    chooseUploadList(index: any) {
      this.uploadFileindex = this.uploadFileindex == index ? -2 : index;
    },
    //上传素材选中的文件夹
    chooseUploadFile(index: any, name: any, id?: any) {
      this.uploadFileindex = index;
      // if (name != -101) {
      //   //排除打开子选项分页时
      (this.uploadFileName = name), (this.uploadFileId = id);
      this.isFile = false;
      // }
    },
    //修改
    async getEditFolder() {
      const params = {
        id: this.parentId,
        title: this.newFileName,
      };
      const res: any = await getEditFolder(params);
      if (res.code == 200) {
        this.fileVisible = false;
        this.isEsternalfile = false;
        this.fileListTest = res.data;

        this.$message({
          message: '修改成功',
          type: 'success',
        });
      }
    },
    //修改，新增文件夹
    addFile() {
      if (this.fileRadio == '新增') {
        this.postAddFolder();
      } else {
        this.getEditFolder();
      }
    },
    //选择修改文件
    chooseFile(name: any, num: number, id: any) {
      this.fileType = num == 1 ? 2 : 3;
      this.fileVisible = true;
      this.newFileName = name;
      // if (num == 1) {
      this.parentId = id;
      // }
      this.fileRadio = '修改';
    },
    //设置文件夹
    // setFileList(index: any) {},
    //打开二级列表
    openFileList(index: any) {
      this.selectIndex = this.selectIndex == index ? -1 : index;
    },
    //选中文件
    selctFile(index: any, id: any, num?: any) {
      this.openFileIndex = index;
      this.selectIndex = num ? index : -1;
      this.isEsternalfile = false;

      this.parentId = id;
      this.getLookup();
    },
    //获取文件夹列表
    async getFolderList() {
      const res: any = await getFolderList();
      if (res.code == 200) {
        this.fileListTest = res.data;
      }
    },
    //添加新文件夹
    async postAddFolder() {
      const params = {
        parent_id: this.parentId,
        title: this.newFileName,
      };
      const res: any = await postAddFolder(params);
      if (res.code == 200) {
        this.fileVisible = false;
        this.openFileIndex = -1;
        this.fileListTest = res.data;
        this.isEsternalfile = false;
        this.$message({
          message: '新增成功',
          type: 'success',
        });
      }
    },

    //  已上传成功的钩子
    handlePreview(file: any) {
      console.log(file);
    },
    // 重置按钮
    getReset() {
      this.keyWord = '';
    },
    // 删除的钩子
    handleRemove(file: any, fileList: any) {
      this.fileList = fileList;
      // console.log(file, fileList);
    },
    //请求报错
    onUpLoadErr(error: any) {
      const msg = JSON.parse(error.message);
      this.$message.error(msg.msg);
    },
    //上传成功
    onUpLoadSucc(e: any) {
      console.log(e, 'e1');
    },
    //
    imgchange(file: any, fileList: any) {
      this.fileList = fileList;
      // console.log('111', file, fileList, this.fileList);
    },
    // 请求素材库的列表
    async getSourceMaterialList() {
      this.loadingInstance = Loading.service({
        fullscreen: true,
        text: '获取素材中...',
      });
      const data = {
        sort: 'createtime',
        parent_id: this.parentId,
        filter: {
          title: this.keyWord,
        },
        limit: '10',
        page: this.page,
      };
      const res: any = await getSourceMaterialList(data);
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
        this.loadingInstance.close();
      }
    },
    // 搜索框
    getLookup() {
      // console.log(this.keyWord);
      this.page = 1;
      this.getSourceMaterialList();
    },
    // 选中
    handleSelectionChange(val: any) {
      // console.log(5241, val);
      this.selectDate = val;
    },
    //关闭上传按钮
    popHandleClose(done: any) {
      this.page = 1;
      this.getSourceMaterialList();
      done();
      // this.$confirm('确认关闭？')
      //   .then(() => {
      //     this.page = 1;
      //     this.getSourceMaterialList();
      //     done();
      //   })
      //   .catch(() => {
      //     console.log('guanbi');
      //   });
    },
    // 删除按钮
    handleClose(row: any) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id);
          })
          .catch(() => {
            console.log('取消删除');
          });
      } else if (this.selectDate.length) {
        this.$confirm('确认批量删除？')
          .then(() => {
            this.getBatchDelete();
          })
          .catch(() => {
            console.log('取消批量删除');
          });
      }
    },
    // 删除按钮
    async handleDelete(id: any) {
      // console.log('删除单个', row);
      const res: any = await getMaterialDelete(id);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getSourceMaterialList();
      }
    },
    // 批量删除
    async getBatchDelete() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      const res: any = await getMaterialDelete(id.toString());
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getSourceMaterialList();
      }
    },
    // 编辑单个
    getModify(row: any) {
      this.ruleForm.oneCycle = [
        {
          materialName: '',
          id: '',
          image: '',
        },
      ];
      this.ruleForm.oneCycle[0].id = row.id;
      this.ruleForm.oneCycle[0].materialName = row.title;
      this.ruleForm.oneCycle[0].image = row.image;
      // console.log('编辑', row);
      console.log(this.ruleForm.oneCycle);
      this.drawer = true;
    },
    // 清空选择
    toggleSelection() {
      // console.log(this.$refs.multipleTable);
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      this.page = val;
      this.getSourceMaterialList();
      // console.log(466, val);
    },
    //弹起上传
    getBounce() {
      this.dialogFormVisible = true;
    },
    //添加上传按钮
    async getDialogFormVisible() {
      if (this.disabled == false) {
        // console.log(this.fileList.row);
        for (let i = 0; i < this.fileList.length; i++) {
          const formData = new FormData();
          formData.append('file', this.fileList[i].raw);
          // this.$loading({
          //   lock: true,
          //   text: '图片上传中',
          //   spinner: 'el-icon-loading',
          //   background: 'rgba(0, 0, 0, 0.7)',
          // });
          this.loading = this.$loading({
            fullscreen: true,
            text: '图片上传中...',
          });
          const res: any = await getMaterialUpload(formData).catch(err => {
            this.loading.close();
            console.log(err);
          });
          this.code.push(res.code);
        }
        const that = this as any;
        this.code.filter(function(val: any, index: any) {
          if (that.code[index] == 200) {
            that.dialogFormVisible = false;
            that.loading.close();
            that.$router.go(0);
          }
        });
      } else {
        this.showTip = true;
      }
    },
    // 批量编辑
    getEdit() {
      if (this.selectDate.length) {
        // console.log(this.selectDate);
        this.ruleForm.oneCycle = [];
        Object.keys(this.selectDate).forEach(key => {
          const item = {
            id: this.selectDate[key].id,
            materialName: this.selectDate[key].title,
            image: this.selectDate[key].image,
          };
          this.ruleForm.oneCycle.push(item);
        });
        // console.log(this.ruleForm);
        this.drawer = true;
      }
    },
    getTips() {
      if (this.checked == false) {
        this.showTip = true;
      }
    },
    // 编辑请求
    async getMaterialEditing() {
      const data1 = [] as any;
      Object.keys(this.ruleForm.oneCycle).forEach(key => {
        const item = {
          id: this.ruleForm.oneCycle[key].id,
          title: this.ruleForm.oneCycle[key].materialName,
        };
        data1.push(item);
      });
      const obj = {
        data: '',
      };
      obj.data = JSON.stringify(data1);
      // console.log(this.ruleForm, obj);
      const res: any = await getMaterialEditing(obj);
      if (res.code == 200) {
        this.drawer = false;
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getSourceMaterialList();
      }
    },
    //编辑素材库的按钮
    getEditBtn(form: any) {
      (this.$refs[form] as any).validate((valid: any) => {
        if (valid) {
          this.getMaterialEditing();
        } else {
          console.log('表单为空');
          return false;
        }
      });
    },
  },
  filters: {
    conversion(num: any) {
      const num1 = num * 1024;
      if (num1 < 1024) {
        //如果小于0.1KB转化成B
        return num1.toFixed(2) + 'B';
      } else if (num1 < 1024 * 1024) {
        //如果小于0.1MB转化成KB
        return (num1 / 1024).toFixed(2) + 'KB';
      } else if (num1 < 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        return (num1 / (1024 * 1024)).toFixed(2) + 'MB';
      } else {
        //其他转化成GB
        return (num1 / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
      }
    },
  },
  watch: {
    checked() {
      if (this.checked == true) {
        this.disabled = false;
        this.showTip = false;
      } else {
        this.disabled = true;
      }
    },
    showTip() {
      if (this.showTip) {
        setTimeout(() => {
          this.showTip = false;
        }, 2500);
      }
    },
    // 监听删除第二页之后请求上一页的操作
    tableData() {
      if (!this.tableData.length && this.page > 1) {
        this.page = this.page - 1;
        this.getSourceMaterialList();
      }
    },
  },
});





































































































import Vue from 'vue';
import { getPlatformList, getPlatformDelete, postTemlateSite } from '@/api';
export default Vue.extend({
  data() {
    return {
      options: [],
      // 模板名称
      contrabandWord: '',
      // 站点
      founder: '',
      // 用户管理数据
      tableData: [],
      // 数据总数
      currentLength: 50,
      // 选中的数据，用于批量删除
      selectDate: [],
      // 新增用户
      form: {
        // 违禁词名称：
        accountName: '',
      },
      page: 1,
    };
  },
  mounted() {
    this.getPlatformList();
    this.postTemlateSite();
  },
  methods: {
    // 站点列表
    async postTemlateSite() {
      const res: any = await postTemlateSite();
      if (res.code == 200) {
        // console.log(res.data);
        this.options = res.data;
      }
    },
    // 请求平台列表
    async getPlatformList() {
      const filter = {
        template_name: this.contrabandWord,
        site: this.founder,
      };
      const page = this.page;
      const limit = '10';
      const data = {
        filter: JSON.stringify(filter),
        limit,
        page,
      };
      const res: any = await getPlatformList(data);
      if (res.code == 200) {
        this.tableData = res.data.rows;
        this.currentLength = res.data.total;
      }
    },
    // 重置按钮
    getReset() {
      this.contrabandWord = '';
      this.founder = '';
    },
    // 搜索框
    getLookup() {
      this.page = 1;
      this.getPlatformList();
    },
    // 选中
    handleSelectionChange(val: any) {
      console.log(5241, val);
      this.selectDate = val;
    },
    // 删除按钮
    async handleDelete(id: any) {
      const res: any = await getPlatformDelete(id);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getPlatformList();
      }
    },
    // 批量删除
    async getBatchDelete() {
      const id = [];
      for (let i = 0; i < this.selectDate.length; i++) {
        id.push((this.selectDate[i] as any).id);
      }
      const res: any = await getPlatformDelete(id.toString());
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
        });
        this.getPlatformList();
      }
    },
    // 清空选择
    toggleSelection() {
      (this.$refs['multipleTable'] as any).clearSelection();
    },
    //分页切换的时候
    handleCurrentChange(val: any) {
      console.log(466, val);
    },
    // 删除按钮
    handleClose(row: any) {
      if (row.id) {
        this.$confirm('是否确认删除？')
          .then(() => {
            this.handleDelete(row.id);
          })
          .catch(() => {
            console.log('取消删除');
          });
      } else if (this.selectDate.length) {
        this.$confirm('确认批量删除？')
          .then(() => {
            this.getBatchDelete();
          })
          .catch(() => {
            console.log('取消批量删除');
          });
      }
    },
    // 编辑站点或者添加
    getModify(val: any) {
      if (val.id) {
        // 编辑
        console.log(val, '编辑');
        this.$router.push({ path: '/modifyPlatform', query: { id: val.id } });
      } else {
        // 添加
        this.$router.push({ path: '/modifyPlatform' });
        console.log('添加');
      }
    },
  },
  watch: {},
  filters: {
    formatTime(time: number) {
      if (time) {
        const date = new Date(time * 1000);
        const Y = date.getFullYear();
        let M = (date.getMonth() + 1).toString();
        let D = date.getDate().toString();
        if (Number(M) < 10) {
          M = '0' + M;
        }
        if (Number(D) < 10) {
          D = '0' + D;
        }
        return Y + '.' + M + '.' + D;
        // if (str == '-') {
        //   return Y + '年' + M + '月' + D + '日';
        // } else {
        //   return Y + '.' + M + '.' + D;
        // }
      }
    },
  },
});
